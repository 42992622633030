import React from "react";
import logo from "../../static/img/logo.png";


const TopMenu = (props) => {

    return(
        <div style={{backgroundColor:'#233646', padding:'5px', display:'flex', justifyContent:'space-between' ,zIndex:'100',top:'0', position:'fixed', width:'100%'}}>
            <img src={logo} style={{maxHeight:'40px'}}>
            </img>
           <span style={{color:'#34abc1', fontWeight:'600', marginRight:'1%'}}>{props.userDisplayName}</span>
        </div>
    );

}

export default TopMenu;
export const USER_PROFILE = "NOW_CERTS_USER_PROFILE";
export const GET_LOB = "NOW_CERTS_LOB";
export const UPDATE_LOB = "UPDATE_LOB";
export const GET_GENERAL_INFO = "GET_GENERAL_INFO";
export const UPDATE_GENERAL_INFO = "UPDATE_GENERAL_INFO";
export const GET_KPI_INFO = "GET_KPI_INFO";
export const UPDATE_KPI_INFO = "UPDATE_KPI_INFO";
export const GET_ADD_OTHER_DEPT = "GET_ADD_OTHER_DEPT";
export const UPDATE_ADD_OTHER_DEPT = "UPDATE_ADD_OTHER_DEPT";
export const GET_LOB_ORGANIC_GROWTH = "GET_LOB_ORGANIC_GROWTH";
export const GET_AGENCY_ORGANIC_GROWTH = "GET_AGENCY_ORGANIC_GROWTH";
export const GET_HABIT_ORGANIC_GROWTH = "GET_HABIT_ORGANIC_GROWTH";
export const GET_AGENCY_MONTHLY_INFO = "GET_AGENCY_MONTHLY_INFO";
export const UPDATE_AGENCY_MONTHLY_INFO = "UPDATE_AGENCY_MONTHLY_INFO";

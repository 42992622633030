import React, {useState, useEffect} from "react";



const LoadNowCertUser = ({ match, location }) => {

    useEffect(()=> {
        const qs = require('qs')
        console.log("Inside use effect pf load now cert user");
        //console.log(JSON.stringify(location, null, 2));
        const access_token = qs.parse(location.search, { ignoreQueryPrefix: true }).access_token;
        const refresh_token = qs.parse(location.search, { ignoreQueryPrefix: true }).refresh_token;
        const userName  = qs.parse(location.search, { ignoreQueryPrefix: true }).userName;
        const userId = qs.parse(location.search, { ignoreQueryPrefix: true }).userId;
        const userDisplayName = qs.parse(location.search, { ignoreQueryPrefix: true }).userDisplayName;
        const agencyId = qs.parse(location.search, { ignoreQueryPrefix: true }).agencyId;
        const agencyName = qs.parse(location.search, { ignoreQueryPrefix: true }).agencyName;
        const userType = qs.parse(location.search, { ignoreQueryPrefix: true }).userType;
        const issued = qs.parse(location.search, { ignoreQueryPrefix: true }).issued;
        const expires = qs.parse(location.search, { ignoreQueryPrefix: true }).expires;

    });
    
    return(
     
        <h1>Now cert</h1>
     
    );

}

export default LoadNowCertUser;
import React, {Component} from "react";
import {toast} from "react-toastify";
import {Table} from 'reactstrap';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { Redirect } from "react-router";

import ogcAction from "../../actions/OGCAction";
import LoadingModal from "./LoadingModal";
import {GET_GENERAL_INFO, UPDATE_GENERAL_INFO,GET_AGENCY_MONTHLY_INFO,UPDATE_AGENCY_MONTHLY_INFO} from '../../actions/ActionTypes';
import {GENERAL_INFO, DEPARTMENTS,AGENCY_MONTHLY} from "./Constants";
import Login from "./Login";


export default class TotalAgencyMonthly extends Component {
    state = {
        agencyMonthly: {
            personal: {},
            commercial:{},
        },
        agency_rev_info:{
            personal: {},
            commercial:{},
        },

        areFieldValueChanged: this.props.areFieldValueChanged,
        isloading :false,
        flag:false
    };

    componentDidMount = async () => {
       // console.log(this.props.generalInfo_details);
       console.log(this.props)
        const currentYear = this.props.year;
        const prevYear = currentYear - 1;
        if (this.props.isAuthenticated ) {
            // without await, we are facing problem 
            // because both function update same state
            
            // call for commercial. department id = 2
            await this.getAgencyMonthlyInfo(currentYear,'commercial'); 
           
           // call for personal. department id = 1
            await this.getAgencyMonthlyInfo(currentYear,'personal');
        }
        return false;
    };
    
        // componentWillUnmount = () => { 

        //     if (this.props.areFieldValueChanged && this.props.setShouldSave){
        //         this.props.showConfirmModal({
        //             msgTitle: 'Save / Discard Changes !',
        //             msgBody: [
        //                 <p>Do you want to save / discard the changes ?</p>
        //             ],
        //             buttons: [
        //                 {
        //                     label : 'Save',
        //                     onClick : async() => {
        //                         await this.saveInfo(this.props.year);
        //                         //this.setAreFieldValueChanged(false);
        //                         this.props.setGeneralInfoAfterCancel(null);
        //                     },
        //                     className : 'btn-success'
        //                 },
        //                 {
        //                     label : 'Discard',
        //                     onClick : () => {
        //                         this.props.setGeneralInfoAfterCancel(null);
        //                         this.props.setAreFieldValueChanged(false);
        //                     },
        //                     className : 'btn-danger'
        //                 },
        //                 {
        //                     label : 'Cancel',
        //                     onClick : () => {
        //                         let generalInfo_details = {...this.state.generalInfo};
        //                         window.location.hash = '#0';
        //                         this.props.setGeneralInfoAfterCancel(generalInfo_details);
        //                         this.props.setAreFieldValueChanged(true);
        //                     },
        //                     className : 'btn-primary'
        //                 }
        //             ]
        //         });

        //     }
            
        // }

    componentDidUpdate = async (prevProps) => {

        //console.log("update");
        const {isAuthenticated, year, shouldSave, generalInfo} = this.props;
        const currentYear = year;
        //console.log(DEPARTMENTS[this.props.selectedDepartment])
        if ((prevProps.isAuthenticated !== isAuthenticated || prevProps.year !== year)) {
            await this.getAgencyMonthlyInfo(currentYear,'commercial');
            await this.getAgencyMonthlyInfo(currentYear,'personal');
        }



        if (prevProps.shouldSave !== shouldSave) {
            if (shouldSave) {
                this.saveInfo(currentYear);
                this.props.setShouldSave(false);
            }
        }
        return false;
    };


    getAgencyMonthlyInfo = async (year,department) => {
        
        const dept = DEPARTMENTS[department];
        const {agencyMonthly,agency_rev_info} = this.state;
        this.setState({isloading:true});
        try {
            const result = await ogcAction({
                type: GET_AGENCY_MONTHLY_INFO, year: year, department:dept
            });
            console.log('resa', result)
            if(result) {
                
                let agencyInfo = {...agencyMonthly};
                agencyInfo[department] = {...result.data};
                this.setState({agencyMonthly: agencyInfo});
                let revenue_info= {...agency_rev_info};
                revenue_info[department] = {...result.info};
                this.setState({agency_rev_info:revenue_info,isloading:false});
               
            }
            else{
                this.setState({flag:true,isloading:false})
            }

        } catch(error) {
            toast.error(error);
            this.setState({isloading:false})
        }
        return;
    }

    saveInfo = async (year) => {
        console.log(year);
        const {agencyMonthly} = this.state;
        let personal_data = []
        let commercial_data = []
        Object.keys(AGENCY_MONTHLY).map(field => {
            
            if (agencyMonthly['personal'][field]){
                let setting = { 'id' : agencyMonthly['personal'][field]['id'],
                            'ly_actual':agencyMonthly['personal'][field]['ly_actual'],
                            'ty_actual': agencyMonthly['personal'][field]['ty_actual'],
                            'ty_goal_new': agencyMonthly['personal'][field]['ty_goal_new'],
                            'ty_goal_renewal': agencyMonthly['personal'][field]['ty_goal_renewal'],
                            'ty_goal_total': agencyMonthly['personal'][field]['ty_goal_total'],
                          
                        }

                personal_data.push(setting);
            }
            
        });
        Object.keys(AGENCY_MONTHLY).map(field => {
    
            if (agencyMonthly['commercial'][field]){
                console.log(agencyMonthly['commercial'][field]['id'])
                let setting = { 'id' : agencyMonthly['commercial'][field]['id'],
                'ly_actual':agencyMonthly['commercial'][field]['ly_actual'],
                'ty_actual': agencyMonthly['commercial'][field]['ty_actual'],
                'ty_goal_new': agencyMonthly['commercial'][field]['ty_goal_new'],
                'ty_goal_renewal': agencyMonthly['commercial'][field]['ty_goal_renewal'],
                'ty_goal_total': agencyMonthly['commercial'][field]['ty_goal_total'],
                          
                        }

                commercial_data.push(setting);
            }
            
        });

        try {
            const resultPersonal = await ogcAction({
                type: UPDATE_AGENCY_MONTHLY_INFO, year: year, 
                department:DEPARTMENTS['personal'],
                data: personal_data
            });
            const resultCommercial = await ogcAction({
                type: UPDATE_AGENCY_MONTHLY_INFO, year: year, 
                department:DEPARTMENTS['commercial'],
                data:commercial_data
            });
            if((resultPersonal && resultCommercial) && year === this.props.year) {
                toast.success("Saved Successfully.")
            }

          
        } catch(error) {
           
            toast.error("Bad request");
        }
        return;
    }

    handleInputChange = (field, value, dept, type) => {
        const {agencyMonthly} = this.state;
        let agencyInfo =  {...agencyMonthly};
        //console.log(agencyInfo[dept][field]);
        console.log(agencyInfo[dept][field])
        agencyInfo[dept][field][type] = value;
        this.setState({agencyMonthly: agencyInfo});
    }

    handleInputBlur = (field, dept) => {
        this.props.setAreFieldValueChanged(true);
        const currentYear = this.props.year;
        const {generalInfo} = this.state;
        let agencyInfo =  {...generalInfo};
        
        let value = agencyInfo[dept][field];
        if (isNaN(value)) {
            agencyInfo[dept][field] = 0;
            this.setState({generalInfo: agencyInfo});
        } else if (GENERAL_INFO[field]['type'] === 'percent' && value > 100) {
            agencyInfo[dept][field] = 100;
            this.setState({generalInfo: agencyInfo});
        }
    }

    getCalculatedValue = (field, dept,type) => {
        const {agencyMonthly,agency_rev_info} = this.state;
        
        let value = 0;
        

        if (agencyMonthly[dept][field]) {
            if (type === 'ty_goal_total') {
                value =  Math.round(Number(
                    agencyMonthly[dept][field]['ly_actual'] *
                    (agency_rev_info[dept]['revenue_increase'] / 100) +
                    agencyMonthly[dept][field]['ly_actual']
                ))
            } else if (type === 'ty_goal_renewal') {
                value =  Math.round(Number(
                    agencyMonthly[dept][field]['ly_actual'] *
                    (agency_rev_info[dept]['retention'] / 100) 
                ))
            } else if (type === 'ty_goal_new') {
                value =  Math.round(Number(
                   ( agencyMonthly[dept][field]['ly_actual'] *
                   (agency_rev_info[dept]['revenue_increase'] / 100) +
                   agencyMonthly[dept][field]['ly_actual'] ) -
                   ( agencyMonthly[dept][field]['ly_actual'] *
                   (agency_rev_info[dept]['retention'] / 100) )
                ))
            }
            else {
                value = agencyMonthly[dept][field][type]
            }
        } 

        return value;
    };

    renderpersTableBody = () => {
        const currentYear = this.props.year;
        const prevYear = currentYear - 1;
        const deptPersonal = 'personal';
        const deptCommercial = 'commercial';
        let type = '';
        let tableBody = Object.keys(AGENCY_MONTHLY).map((field,index) => (
            <React.Fragment >
            {index === 0 &&
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style={{"color":"black","font-weight":"bold",fontSize:'16px'}} className = "align-middle " colSpan="3">
                   Personal
                </td>
            </tr>
             }
               
            <tr style= {{"border": "0"}} key={index}>
                <td style={{'border':'0px', 'cursor': 'alias' , }} 
                >
                    {
                     AGENCY_MONTHLY[field]['text']
                     }
                </td>
                <td style={{'border':'0px', 'cursor': 'alias'}}
                className={`align-middle py-0 text-center 
                 
                `}
                >
                    <NumberFormat 
                     className={`text-center form-control
                     `}
                     thousandSeparator={true} allowNegative={false}
                     decimalScale={AGENCY_MONTHLY[field]['decimalScale']} 
                     prefix={AGENCY_MONTHLY[field]['type'] === 'amount' ? '$ ' : ''}
                     suffix={AGENCY_MONTHLY[field]['type'] === 'percent' ? ' %' : ''}
                    onValueChange={values => this.handleInputChange(
                        field , values.floatValue, deptPersonal,
                         type = 'ly_actual'
                    )}
                    // onBlur={(e) => this.handleInputBlur(field, deptPersonal)}
                    value={this.getCalculatedValue(field, deptPersonal,type = 'ly_actual')}
                    // displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                    disabled={!AGENCY_MONTHLY[field]['editable']}
                    style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                />
                </td>
                <td style={{'border':'0px', 'cursor': 'alias'}}>
                <NumberFormat 
                     className={`text-center form-control 
                     `}
                     thousandSeparator={true} allowNegative={false}
                     decimalScale={AGENCY_MONTHLY[field]['decimalScale']} 
                     prefix={AGENCY_MONTHLY[field]['type'] === 'amount' ? '$ ' : ''}
                     suffix={AGENCY_MONTHLY[field]['type'] === 'percent' ? ' %' : ''}
                    onValueChange={values => this.handleInputChange(
                        field, values.floatValue, deptPersonal, 
                        type ='ty_actual'
                    )}
                    // onBlur={(e) => this.handleInputBlur(field, deptPersonal)}
                    value={this.getCalculatedValue(field, deptPersonal ,type='ty_actual')}
                    // displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                    disabled={!AGENCY_MONTHLY[field]['editable']}
                    style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                />
                </td>
                <td style={{'border':'0px', 'cursor': 'alias'}}>
                <NumberFormat 
                    className={`text-center form-control
                    `}
                    thousandSeparator={true} allowNegative={false}
                    decimalScale={AGENCY_MONTHLY[field]['decimalScale']} 
                    prefix={AGENCY_MONTHLY[field]['type'] === 'amount' ? '$ ' : ''}
                    suffix={AGENCY_MONTHLY[field]['type'] === 'percent' ? ' %' : ''}
                    onValueChange={values => this.handleInputChange(
                        field , values.floatValue, deptPersonal, 
                        type = 'ty_goal_new'
                    )}
                    // onBlur={(e) => this.handleInputBlur(field, deptPersonal)}
                    value={this.getCalculatedValue(field, deptPersonal,type='ty_goal_new')}
                    // displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                    disabled={true}
                    style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                />
                </td>
                <td style={{'border':'0px', 'cursor': 'alias'}}>
                <NumberFormat 
                    className={`text-center form-control
                    `}
                    thousandSeparator={true} allowNegative={false}
                    decimalScale={AGENCY_MONTHLY[field]['decimalScale']} 
                    prefix={AGENCY_MONTHLY[field]['type'] === 'amount' ? '$ ' : ''}
                    suffix={AGENCY_MONTHLY[field]['type'] === 'percent' ? ' %' : ''}
                    onValueChange={values => this.handleInputChange(
                        field , values.floatValue, deptPersonal, 
                        type = 'ty_goal_renewal'
                    )}
                    // onBlur={(e) => this.handleInputBlur(field, deptPersonal)}
                    value={this.getCalculatedValue(field, deptPersonal,type='ty_goal_renewal')}
                    // displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                    disabled={true}
                    style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                />
                </td>
                <td style={{'border':'0px', 'cursor': 'alias'}}>
                <NumberFormat 
                    className={`text-center form-control
                    `}
                    thousandSeparator={true} allowNegative={false}
                    decimalScale={AGENCY_MONTHLY[field]['decimalScale']} 
                    prefix={AGENCY_MONTHLY[field]['type'] === 'amount' ? '$ ' : ''}
                    suffix={AGENCY_MONTHLY[field]['type'] === 'percent' ? ' %' : ''}
                    onValueChange={values => this.handleInputChange(
                        field , values.floatValue, deptPersonal, 
                        type = 'ty_goal_total'
                    )}
                    // onBlur={(e) => this.handleInputBlur(field, deptPersonal)}
                    value={this.getCalculatedValue(field, deptPersonal,type='ty_goal_total')}
                    // displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                    disabled={true}
                    style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                />
                </td>
               
                      
            </tr>
            </React.Fragment>
        ));
        return tableBody;
    }

    rendercommTableBody = () => {
        const currentYear = this.props.year;
        const prevYear = currentYear - 1;
        const deptPersonal = 'personal';
        const deptCommercial = 'commercial';
        let type = '';
        let tableBody = Object.keys(AGENCY_MONTHLY).map((field,index) => (
            <React.Fragment >
            {index === 0 &&
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style={{"color":"black","font-weight":"bold",fontSize:'16px'}} className = "align-middle font-14" colSpan="3">
                   Commercial
                </td>
            </tr>
             }
               
            <tr style= {{"border": "0"}} key={index}>
                <td style={{'border':'0px', 'cursor': 'alias' , fontSize:'13px'}} 
                >
                    {
                     AGENCY_MONTHLY[field]['text']
                     }
                </td>
               
                
                <td style={{'border':'0px', 'cursor': 'alias'}}>
                <NumberFormat 
                     className={`text-center form-control
                     `}
                     thousandSeparator={true} allowNegative={false}
                     decimalScale={AGENCY_MONTHLY[field]['decimalScale']} 
                     prefix={AGENCY_MONTHLY[field]['type'] === 'amount' ? '$ ' : ''}
                     suffix={AGENCY_MONTHLY[field]['type'] === 'percent' ? ' %' : ''}
                    onValueChange={values => this.handleInputChange(
                        field , values.floatValue, deptCommercial, 
                        type='ly_actual'
                    )}
                    // onBlur={(e) => this.handleInputBlur(field, deptPersonal)}
                    value={this.getCalculatedValue(field, deptCommercial,type = 'ly_actual')}
                    // displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                    disabled={!AGENCY_MONTHLY[field]['editable']}
                    style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                />
                </td>
                    
                <td style={{'border':'0px', 'cursor': 'alias'}}>
                <NumberFormat 
                    className={`text-center form-control
                    `}
                    thousandSeparator={true} allowNegative={false}
                    decimalScale={AGENCY_MONTHLY[field]['decimalScale']} 
                    prefix={AGENCY_MONTHLY[field]['type'] === 'amount' ? '$ ' : ''}
                    suffix={AGENCY_MONTHLY[field]['type'] === 'percent' ? ' %' : ''}
                    onValueChange={values => this.handleInputChange(
                        field , values.floatValue, deptCommercial, 
                        type='ty_actual'
                    )}
                    // onBlur={(e) => this.handleInputBlur(field, deptPersonal)}
                    value={this.getCalculatedValue(field, deptCommercial,type='ty_actual')}
                    // displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                    disabled={!AGENCY_MONTHLY[field]['editable']}
                    style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                />
                </td>
                <td style={{'border':'0px', 'cursor': 'alias'}}>
                <NumberFormat 
                    className={`text-center form-control
                    `}
                    thousandSeparator={true} allowNegative={false}
                    decimalScale={AGENCY_MONTHLY[field]['decimalScale']} 
                    prefix={AGENCY_MONTHLY[field]['type'] === 'amount' ? '$ ' : ''}
                    suffix={AGENCY_MONTHLY[field]['type'] === 'percent' ? ' %' : ''}
                    onValueChange={values => this.handleInputChange(
                        field , values.floatValue, deptCommercial, 
                        type = 'ty_goal_new'
                    )}
                    // onBlur={(e) => this.handleInputBlur(field, deptPersonal)}
                    value={this.getCalculatedValue(field, deptCommercial,type = 'ty_goal_new')}
                    // displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                    disabled={true}
                    style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                />
                </td>
                <td style={{'border':'0px', 'cursor': 'alias'}}>
                <NumberFormat 
                    className={`text-center form-control
                    `}
                    thousandSeparator={true} allowNegative={false}
                    decimalScale={AGENCY_MONTHLY[field]['decimalScale']} 
                    prefix={AGENCY_MONTHLY[field]['type'] === 'amount' ? '$ ' : ''}
                    suffix={AGENCY_MONTHLY[field]['type'] === 'percent' ? ' %' : ''}
                    onValueChange={values => this.handleInputChange(
                        field , values.floatValue, deptCommercial, 
                        type='ty_goal_renewal'
                    )}
                    // onBlur={(e) => this.handleInputBlur(field, deptPersonal)}
                    value={this.getCalculatedValue(field, deptCommercial,type='ty_goal_renewal')}
                    // displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                    disabled={true}
                    style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                />
                </td>
                <td style={{'border':'0px', 'cursor': 'alias'}}>
                <NumberFormat 
                    className={`text-center form-control
                    `}
                    thousandSeparator={true} allowNegative={false}
                    decimalScale={AGENCY_MONTHLY[field]['decimalScale']} 
                    prefix={AGENCY_MONTHLY[field]['type'] === 'amount' ? '$ ' : ''}
                    suffix={AGENCY_MONTHLY[field]['type'] === 'percent' ? ' %' : ''}
                    onValueChange={values => this.handleInputChange(
                        field , values.floatValue, deptCommercial, 
                        type = 'ty_goal_total'
                    )}
                    // onBlur={(e) => this.handleInputBlur(field, deptPersonal)}
                    value={this.getCalculatedValue(field, deptCommercial,type = 'ty_goal_total')}
                    // displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                    disabled={true}
                    style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                />
                </td>
               
                      
            </tr>
            </React.Fragment>
        ));
        return tableBody;
    }

    render = () => {
        const currentYear = this.props.year;
        console.log(this.state.agency_rev_info, this.state.agencyMonthly)
       
        return (
            this.state.flag ? 
            <Redirect to='/' /> :
            <>
               
                <Table responsive striped style={{'color':'#676a6c'}}  className="mt-2" >
                
                    <thead style = {{'height':'40px', 'background-color': '#ececec'}}>
                       
                        <tr style={{"font":'13px', 
                            'height':'70px',
                                'padding': '5px 8px',
                                'border-bottom': '1px solid #b3c0c7',
                                'background-color': '#ececec'}}>
                            <th className="blueHeader">
                                Month
                            </th>
                           
                            
                            <th className="blueHeader pb-1 align-middle text-center" scope="col">Ly Actual</th>
                            <th className="blueHeader pb-1 align-middle text-center" scope="col">TY Actual</th>
                            <th className="blueHeader pb-1 align-middle text-center" scope="col">TY Goal - New</th>
                            <th className="blueHeader pb-1 align-middle text-center" scope="col"> TY Goal - Renewal </th>
                            <th className="blueHeader pb-1 align-middle text-center" scope="col">TY Goal - Total</th>
                           
                         </tr>
                        
                    </thead>
                    <tbody style= {{"border": "0"}}>
                        <tr></tr>
                        <tr></tr>
                        {this.renderpersTableBody()}
                    </tbody>
                    <tbody style= {{"border": "0"}}>
                        
                        {this.rendercommTableBody()}
                    </tbody>
                </Table>
                {this.state.isloading && <LoadingModal modal={this.state.isloading} />}
                 
            </>
        
        )
        
        
    }

    
};
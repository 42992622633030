import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import {
    Table, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';
import ogcAction from "../../actions/OGCAction";
import {GET_LOB, UPDATE_LOB} from '../../actions/ActionTypes';
import { DEPARTMENTS } from "./Constants";




const AddLob = (props) => {
    const {openModal, togglebModal} = props;
    const [activeLobList, setLobList] = useState([]);
    const [inactiveLobList, setInactiveLobList] = useState([]);
    const [selectedLob, setSelectedLob]  = useState();
    const [selectedLobId, setSelectedLobId] = useState();

    let LobOptions = inactiveLobList.map((lob) => 
    <option key={lob.id} value={lob.id}>{lob.name}</option>
    );

    useEffect(() => {
        if (props.isAuthenticated) {
            getLobList();
        }
    }, [props.year, props.selectedDepartment]);

    const getLobList = async () => {
        try {
            let result = await ogcAction({  type: GET_LOB,
                                            year: props.year,
                                            department:DEPARTMENTS[props.selectedDepartment]}
                                        );
            if (result) {
                console.log(result);
                let lobList = result.map(lob => ({...lob}));
                
                let activeLobList = lobList.filter(lob => {
                    return lob.show;
                });
                setLobList(activeLobList);
                console.log(activeLobList);
                
                let inactiveLobList = lobList.filter(lob => {
                    return !lob.show;
                });
                setInactiveLobList(inactiveLobList);
                console.log(inactiveLobList);
            }
        } catch(error) {
            toast.error(error);
        }
        return;
    }


    const getSelectedLob = (event) => {
        const value = parseInt(event.currentTarget.value, 10);
        if (value === 0) {
            return false;
        }
        inactiveLobList.map(lob =>{
            if(lob.id===value) setSelectedLobId(lob.id);
        });
        setSelectedLob(value);
    }

    const  updateLob = async (event) => {
        try {
            console.log(event.target.name);
            let data = []
            if (event.target.name === "add"){
                data.push({"id": selectedLobId,"show":true});
                console.log(data);
            }

            else if (event.target.name==="remove"){
                data.push({"id": event.target.id,"show":false});
            }
            
            let result = await ogcAction(
                            {   type: UPDATE_LOB,
                                year: props.year, 
                                department:DEPARTMENTS[props.selectedDepartment], 
                                data:data
                            });
            if (result) {
                let lobList = result.map(lob => ({...lob}));
                
                let activeLobList = lobList.filter(lob => {
                    return lob.show;
                });
                setLobList(activeLobList);
                
                let inactiveLobList = lobList.filter(lob => {
                    return !lob.show;
                });
                setInactiveLobList(inactiveLobList);
                props.setLobChangedTrue();
            }
        } catch(error) {
            toast.error(error);
        }
        return;
    }
    let tableBody = [];
    activeLobList.map(lob => {
        tableBody.push(
            <tr  key={lob.id}>
                  <td style={{'backgroundColor':'white'}} className>
                    {lob.name}
                </td>
                <td style={{'backgroundColor':'white'}}>
                    <Button style={
                            {   'background-color': '#1ab394',
                                'border-color': '#18a689',
                                'font-size': '12px',
                                'padding': '4px 10px',
                                'border-radius': '3px',
                                'margin-left': '5px',
                                'line-height': '1.5',
                                'color': '#fff',
                                'cursor': 'pointer',
                                'border': '1px solid transparent',
                                'user-select': 'none'}
                            } 
                        name="remove" onClick={updateLob} id={lob.id} className="btn-sm">Remove</Button>
                </td>
                
            </tr>
        )

    });

    return (
        <Modal isOpen={openModal} toggle={togglebModal} size="lg">
            <ModalHeader style={{'backgroundColor':'#ffffff'}}>
                Add / Edit Line of Business
            </ModalHeader>
            <ModalBody style={{'backgroundColor':'#f1f1f2'}}>
            <div className="d-flex">
            <select name="lobName"
                className="form-control"
                onChange={getSelectedLob}
                value={selectedLob}
                >
                 <option key="0" value="0">Select LOB</option>
                {LobOptions}
            </select> 
            <Button id="nowCertButton" className="btn-sm ml-2 p-2 p-2" 
                  name="add" onClick={updateLob}
               > 
                Add
            </Button>
            
            </div>
            <br></br>
            <Table responsive striped size="m" className="">
                {tableBody}
            </Table>
            </ModalBody>
            
            <ModalFooter className="mt-4" style={{'backgroundColor':'#ffffff'}}>
            <Button color="secondary" onClick={togglebModal} className="btn-sm">
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default AddLob;

import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import {Table} from 'reactstrap';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';

import ogcAction from "../../actions/OGCAction";
import {GET_KPI_INFO, UPDATE_KPI_INFO} from '../../actions/ActionTypes';
import {HABITS_KPI, DEPARTMENTS} from "./Constants";
import { Redirect } from "react-router";
import LoadingModal from "./LoadingModal";

const KPI = (props) => {
    const currentYear = props.year;
    const [ogcKPI, setOGCKPI] = useState({
        personal: {},
        commercial:{}
    });

    const ogcKPIREF =  React.createRef();
    ogcKPIREF.current = ogcKPI;

    const [showPopUp,setShowPopUp] = useState(false); 
    const [flag,setflag] = useState(false);
    const [isloading,setLoad]  = useState(false);

    useEffect(() => {
        if (props.isAuthenticated) {
            getKPIInfo(currentYear);
        }
        
    }, [props.isAuthenticated, props.year, props.selectedDepartment]);

    const getKPIInfo = async (year) => {
        setLoad(true);
        try {
            const resultPersonal = await ogcAction({
                type: GET_KPI_INFO, year: year, department:DEPARTMENTS['personal']
            });

            const resultCommercial = await ogcAction({
                type: GET_KPI_INFO, year: year, department:DEPARTMENTS['commercial']
            });


            if(resultPersonal && resultCommercial) {
                setOGCKPI({
                    ...ogcKPI,
                    ['personal']: {...resultPersonal},
                    ['commercial']: {...resultCommercial}
               
                });
                setLoad(false);
                
            }
            else{
                setflag(true)
            }
            
               
        } catch(error) {
            toast.error(error);
            setLoad(false);
        }
        
        return;
    }

    // for dialog box. 
    /*
    useEffect(() => {
            return () => {
                console.log("clean up called");
                console.log(ogcKPIREF.current);
            }
        }, []);
    */
    useEffect(() => {
        if (props.shouldSave) {
            saveInfoPersonalandCommercial(currentYear);
            props.setShouldSave(false);
        }
    }, [props.shouldSave]);

    const saveInfoPersonalandCommercial = async (year) => {
        try {
            const resultPersonal = await ogcAction({
                type: UPDATE_KPI_INFO, 
                year: year, department: DEPARTMENTS['personal'],
                data: ogcKPI['personal']
            });
            const resultCommercial = await ogcAction({
                type: UPDATE_KPI_INFO, 
                year: year, department: DEPARTMENTS['commercial'],
                data: ogcKPI['commercial']
            });
            if((resultPersonal && resultCommercial) && year === currentYear) {
                toast.success("Saved Successfully.")
            }
        } catch(error) {
            toast.error(error);
        }
        return;
    }
        
    const handleInputChange = (field, value, dept) => {
        let ogcKPIInfo = {...ogcKPI};
        ogcKPIInfo[dept][field] = value;
        setOGCKPI(ogcKPIInfo);
        
    }

    const handleInputBlur = (field, value, dept) => {
        let ogcKPIInfo = {...ogcKPI};
        
        if (isNaN(value)) {
            ogcKPIInfo[dept][field] = 0;
            setOGCKPI(ogcKPIInfo);
        } else if (HABITS_KPI[field]['type'] === 'percent' && value > 100) {
            ogcKPIInfo[dept][field] = 100;
            setOGCKPI(ogcKPIInfo);
        }
    }

    const deptPersonal = 'personal';
    const deptCommercial = 'commercial';

    let tableBody = Object.keys(HABITS_KPI).map((field, index) => (
        <tr style= {{"border": "0"}} key={index}>
            <td style= {{"border": "0"}} className={`align-middle ${
                    HABITS_KPI[field]['isCategory'] ? 'font-weight-bold bg-d9' : 'pl-3'
                }`} colSpan={HABITS_KPI[field]['isCategory'] ? 3 : 1}
            >
                {HABITS_KPI[field]['text']}
                {HABITS_KPI[field]['explanation'] && 
                    <i className="fa fa-info-circle pl-2" 
                        data-tip data-for={`info_${index}`}
                    >
                        <ReactTooltip id={`info_${index}`} 
                            className="react-tooltip-class"
                            delayShow={200}
                        >
                            {HABITS_KPI[field]['explanation']}
                        </ReactTooltip>
                    </i>
                }
            </td>
            {!HABITS_KPI[field]['isCategory'] &&
                <td style= {{"border": "0"}} className="align-middle py-0">
                    <NumberFormat className="form-control form-control-sm text-center"
                        thousandSeparator={true} allowNegative={false}
                        decimalScale={HABITS_KPI[field]['decimalScale']} 
                        prefix={HABITS_KPI[field]['type'] === 'amount' ? '$ ' : ''}
                        suffix={HABITS_KPI[field]['type'] === 'percent' ? ' %' : ''}
                        onValueChange={values => handleInputChange(
                            field , values.floatValue, deptPersonal
                        )}
                        onBlur={(e) => handleInputBlur(
                            field, ogcKPI[deptPersonal][field], deptPersonal
                        )}
                        value={ogcKPI[deptPersonal] && ogcKPI[deptPersonal][field]}
                        disabled={!HABITS_KPI[field]['editable']}
                        style = {{'border': '0px',backgroundColor:"inherit"}}
                    /> 
                </td>
            }
              {!HABITS_KPI[field]['isCategory'] &&
                <td style= {{"border": "0"}} className="align-middle py-0">
                    <NumberFormat className="form-control form-control-sm text-center"
                        thousandSeparator={true} allowNegative={false}
                        decimalScale={HABITS_KPI[field]['decimalScale']} 
                        prefix={HABITS_KPI[field]['type'] === 'amount' ? '$ ' : ''}
                        suffix={HABITS_KPI[field]['type'] === 'percent' ? ' %' : ''}
                        onValueChange={values => handleInputChange(
                            field , values.floatValue, deptCommercial
                        )}
                        onBlur={(e) => handleInputBlur(
                            field, ogcKPI[deptCommercial][field], deptCommercial
                        )}
                        value={ogcKPI[deptCommercial] && ogcKPI[deptCommercial][field]}
                        disabled={!HABITS_KPI[field]['editable']}
                        style = {{'border': '0px',backgroundColor:"inherit"}}
                    /> 
                </td>
            }
        </tr>
    ));
    if (flag){
        return(<Redirect to='/login' />);
    }
    else{

        return (
            <>
             
            <Table responsive striped style={{'color':'#676a6c'}} className="mt-2"  >
                <thead>
                    <tr  style={{"font":'13px', 
                                'height':'70px',
                                'padding': '5px 8px',
                                'border-bottom': '1px solid #b3c0c7',
                                'background-color': '#ececec'}}
                    >
                        <th className="blueHeader">
                            Habits/KPIs
                        </th>
                        <th className="blueHeader align-middle text-center">
                            Personal
                        </th>
                        <th className="blueHeader align-middle text-center">
                            Commercial
                        </th>
                    </tr>
                </thead>
                <tbody style= {{"border": "0"}}>
                    <tr></tr>
                    <tr></tr>
                    {tableBody}
                </tbody>
            </Table>
            {isloading && <LoadingModal modal={isloading} />}
            </>
        );
    }
}

export default KPI;

import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import {Button, Input} from 'reactstrap';
import moment from 'moment';
import AddLob from "./AddLob";
import SideNav from "./SideBar";


import {USER_PROFILE} from '../../actions/ActionTypes';
import ogcAction from "../../actions/OGCAction";
import GeneralInfo from "./GeneralInfo";
import KPI from "./KPIs";   
import LOBData from "./LOBData";
import TimeNeeded from "./TimeNeeded";
import Report from "./Report";
import LobReport from "./LobReport";
import HabitsReport from "./HabitsReport";
import OrganicDashboard from "./OrganicDashboard";
import ConfirmModal from "./ConfirmModal";
import TopMenu from "./TopMenu"
import Axios from 'axios';
import { Redirect } from "react-router";
import { Bubble } from "react-chartjs-2";
import {ValidationRules} from './ValidationRules';
import FormValidator from './FormValidator';
import LoadingModal from "./LoadingModal";

const Login = (props) => {
    console.log("props",props, )
    console.log(window)
    
    const [credentials, setcred] = useState({
        username: '',
        password: ''
    })
    const [error, setError] = useState({})
   
    const [token,setToken] = useState()
    const [isloading,setLoad]  = useState(false);

    const validator = new FormValidator(ValidationRules);
   

    const onChange = (event) => {
        const field = event.target.name;
        const value = event.target.value;
        let credential = Object.assign({}, credentials);
        credential[field] = value;
        return setcred(credential);
    };

    const validateAndSubmit = async () => {
       
        let validationStatus = validator.validateForm(credentials);

        if (!validationStatus.isValid) {
            setError(validationStatus.errors);
        } 
        else {
            setLoad(true);
            try {
                var reqdata = `grant_type=password&username=${credentials.username}&password=${credentials.password}&client_id=ngAuthApp`;
                var config = {
                method: 'post',
                url: 'https://api.nowcerts.com/api/token',
                headers: { 
                    'Content-Type': 'application/json', 
                },
                data : reqdata
                };
                Axios(config)
                .then(function(response) {
                    let res = response.data;
                    localStorage.setItem('access_token',res.access_token)
                    localStorage.setItem('userId',res.userId)
                    localStorage.setItem('agencyId',res.agencyId)
                    localStorage.setItem('.issued',res['.issued'])
                    localStorage.setItem('.expires',res['.expires'])
                    localStorage.setItem('refresh_token',res.refresh_token)
                    localStorage.setItem('userName',res.userName)
                    localStorage.setItem('agencyName',res.agencyName)
                    localStorage.setItem('userDisplayName',res.userDisplayName)
                    localStorage.setItem('contactId',res.contactId)

                    setToken(res);
                    setLoad(false);
                    toast.success("Login successfully",);
                })
                
                .catch(function (error) {
                    setLoad(false);
                    toast.error("Email or password doesn't match");
                });
                
            }
            catch (error) {
                 
                    toast.error("Email or password doesn't match");
                    setLoad(false);
            }
        }
        return true;
    };

    if (token){
        return(<Redirect to='/' />);
    }
    else {
        
        return (
            <body style={{ 'background' : '#e7edf3' , padding:'8%',}} className='d-flex justify-content-center'>
            
            <div style={{ 'background' : 'white', width:'45%' ,height:'100%'}} className='p-5'>
            <p className='text-center ' style={{ fontSize : '200%'}}>Login with API  User Credentials </p>
                    <div className="form-group mt-5">
                    <label>Username</label>
                    <input type="text" className="form-control h-20 form-control-sm mb-3 "
                            name="username" 
                            onChange={onChange}
                            required type="email"
                            placeholder='Username'
                            value={credentials.username}
                            // disabled={year < currentYear}

                        />
                        {error.username ? 
                            <span className="span-error" style={{'color':"red", fontSize:'110%'}}>
                                {error.username}
                            </span> 
                            : ''
                        }
                </div>

                <div className="form-group pt-2">
                <label>Password</label>
                    <input type="passwod" className="form-control form-control-sm mb-3 "
                    name="password" 
                    onChange={onChange} 
                    placeholder='Password'
                    required type="password" 
                    value={credentials.password}
                    // disabled={year < currentYear}
                    />
                    {error.password ? 
                        <span className="span-error " style={{'color':"red", fontSize:'110%'}}>
                            {error.password}
                        </span> 
                        : ''
                    }
                </div>
                

                <Button 
                    onClick={validateAndSubmit} 
                        //style ={{backgroundColor:"#ef8e2a"}}
                        className="btn btn-primary btn-block mt-5"
                        type="submit"
                    >Login
                </Button>
                {isloading && <LoadingModal modal={isloading} />}

               
            </div>
           
            </body>
        )
    }
}
export default Login;
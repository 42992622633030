import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import {
    Table, Button
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import ogcAction from "../../actions/OGCAction";
import {GET_HABIT_ORGANIC_GROWTH} from '../../actions/ActionTypes';
import { DEPARTMENTS } from "./Constants";
import { Redirect } from "react-router";
import LoadingModal from "./LoadingModal";

const HabitsReport = (props) => {

    const [habitOrganicGrowth, setHabitOrganicGrowth] = useState([]);
    const [flag,setflag] = useState(false);
    const [isloading,setLoad]  = useState(false);

    useEffect(() => {
        if (props.isAuthenticated) {
            getHabitOrganicGrowth();
        }
    }, [props.year, props.selectedDepartment]);

    const getTotalHoursPerWeek = (habit) => {
        const total = parseFloat( habit.dept_prepare_time)+
                        parseFloat(habit.dept_review_time);
        return total;
    }

    const getHabitOrganicGrowth = async () => {
        setLoad(true);
        try {
            let result = await ogcAction({  
                                    type: GET_HABIT_ORGANIC_GROWTH,
                                    year: props.year,
                                    department:DEPARTMENTS[props.selectedDepartment]}
                                        );
            if (result) {
                console.log(result);
                setHabitOrganicGrowth(result);
                setLoad(false);
            }
            else{
                setflag(true)
            }
        } catch(error) {
            toast.error(error);
            setLoad(false);
        }

        return;
    }

    const getTotalHours = (field) => {
        let total = 0 
        habitOrganicGrowth.map((habit,index) => {
           total = total + parseFloat(habit[field]);
        }); 
        return Math.round(total * 100)/100;
    }

    const getTotalEmployees = (role) => {
        if (habitOrganicGrowth.length>0) {
            return habitOrganicGrowth[0][role];
        }
        return 0;
    }
    
    const getTotalHoursForRole = (role, prepareField, reviewField) => {
        let prepareHours =   getTotalHours(prepareField);
        let reviewHours =    getTotalHours(reviewField);
        let totalEmployees = getTotalEmployees(role);
        return Math.round((prepareHours  + reviewHours)/totalEmployees * 100) / 100
    }
    
   
    let tableBody = [];
    habitOrganicGrowth.map((habit, habitIndex)=>{

        tableBody.push(
            <React.Fragment>
                <tr style= {{"border": "0"}} key={habitIndex}>
                    <td  style={{'border':'0px'}} className="font-weight-bold bg-d9">
                            {habit.name}
                    </td>
                    <td style={{'border':'0px'}} className="font-weight-bold bg-d9 align-middle py-0">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0} prefix={'$ '}
                            value={habit.organic_growth}
                            allowNegative={false}
                            disabled={true}
                            style = {{'border': '0px',backgroundColor:"inherit", 'font-weight':'bold'}}
                        /> 
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={getTotalHoursPerWeek(habit)}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px',backgroundColor:"inherit", fontWeight:'bold'}}
                            /> 
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0"></td>
                    <td style={{'border':'0px'}} className="align-middle py-0"></td>
                </tr>

                <tr style= {{"border": "0"}} key={habitIndex}>
                    <td style={{'border':'0px'}} className="pl-3">
                        Ask for {habit.name} quote
                    </td>                
                    <td style={{'border':'0px'}} className="pl-3"></td>
                    <td style={{'border':'0px'}} className="pl-3"></td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={habit.ask_items/52}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={habit.ask_items/52/5}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                </tr>
                <tr style= {{"border": "0"}} key={habitIndex}>
                    <td style={{'border':'0px'}} className="pl-3">
                        Prepare {habit.name} 
                    </td>                
                    <td style={{'border':'0px'}} className="pl-3"></td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={habit.dept_prepare_time}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        />
                    </td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={parseFloat(habit.prepare_items)/52.0}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={parseFloat(habit.prepare_items)/52.0/5.0}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                </tr>   
                <tr style= {{"border": "0"}} key={habitIndex}>
                    <td style={{'border':'0px'}} className="pl-3">
                        Review {habit.name} with client
                    </td>                
                    <td style={{'border':'0px'}} className="pl-3"></td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={habit.dept_review_time}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        />
                    </td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={parseFloat(habit.review_items)/52.0}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={parseFloat(habit.review_items)/52.0/5.0}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                </tr>
            </React.Fragment>
        );
    });

    if (flag){
        return(<Redirect to='/login' />);
    }
    else{
        return (
            <>
        <section className="container-fluid">
            <div className="row no-gutters">
                <div className={`col-sm`}>
                    <div className="card section-border mb-3" style={{minWidth: '30%'}}>
                        <div style={{color:'white' , backgroundColor:"#209487"}} className="card-header text-white text-center mb-0 p-2">
                            <label  style={{fontSize:"11px",fontWeight:"bolder",color:"white"}} className="mb-0">
                            Outside Producers - Total Hours
                            </label>
                            <label  style={{fontSize:"11px",color:"white"}} className="mb-0">
                            Total/Individual
                            </label>
                        </div>
                        <div className="card-body text-center font-weight-bold p-2 pt-4 pb-4">
                            <label style={{ fontWeight:"bold",'font-size':'120%'}}>
                                    {Math.round((getTotalHours('outside_sales_prepare') + getTotalHours('outside_sales_review'))*100)/100} hours / {getTotalHoursForRole('outside_producer','outside_sales_prepare','outside_sales_review')} hours
                            </label>
                        </div>
                    </div>
                </div>
                <div className={`col-sm ml-5`}>
                    <div className="card section-border mb-3" style={{minWidth: '30%'}}>
                        <div style={{color:'black' , backgroundColor:"#209487"}} className="card-header text-white text-center mb-0 p-2">
                            <label style={{fontSize:"11px",fontWeight:"bolder",color:"white"}} className="mb-0">
                                Inside Sales - Total Hours
                            </label>
                            <label  style={{fontSize:"11px",color:"white"}} className="mb-0">
                            Total/Individual
                            </label>
                        </div>
                        <div className="card-body text-center font-weight-bold p-2 pt-4 pb-4" style={{minWidth: '30%'}}>
                            <label style={{ fontWeight:"bold", 'font-size':'120%'}}>
                                    { Math.round((getTotalHours('inside_sales_prepare') + getTotalHours('inside_sales_review'))*100)/100 } hours / {getTotalHoursForRole('inside_sales','inside_sales_prepare','inside_sales_review')} hours
                            </label>
                        </div>
                    </div>
                </div>
                <div className={`col-sm ml-5`}>
                    <div className="card section-border mb-3" style={{minWidth: '20%'}}>
                        <div style={{color:'white' , backgroundColor:"#209487"}} className="card-header text-white text-center mb-0 p-2">
                            <label style={{fontSize:"11px",fontWeight:"bolder",color:"white"}} className="mb-0">
                                Account Manager - Total Hours
                            </label>
                            <label  style={{fontSize:"11px",color:"white"}} className="mb-0">
                            Total/Individual
                            </label>
                        </div>
                        <div className="card-body text-center font-weight-bold p-2 pt-4 pb-4">
                            <label style={{fontWeight:"bold",'font-size':'120%'}}>
                                {Math.round((getTotalHours('account_manager_prepare') + getTotalHours('account_manager_review'))*100)/100} hours / {getTotalHoursForRole('account_manager','account_manager_prepare','account_manager_review')} hours
                            </label>
                        </div>
                    </div>
                </div>
                <div className={`col-sm ml-5`}>
                    <div className="card section-border mb-3" style={{minWidth: '20%'}}>
                        <div style={{color:'white' , backgroundColor:"#209487"}} className="card-header text-white text-center mb-0 p-2">
                            <label style={{fontSize:"11px", fontWeight:"bolder",color:"white"}} className="mb-0">
                            CSR/VA -  Total Hours
                            </label>
                            <label  style={{fontSize:"11px",color:"white"}} className="mb-0">
                            Total/Individual
                            </label>
                        </div>
                        <div className="card-body text-center font-weight-bold p-2 pt-4 pb-4">
                            <label style={{fontWeight:"bold",'font-size':'16px'}}>
                            {Math.round((getTotalHours('csr_prepare') + getTotalHours('csr_review'))*100)/100} hours / {getTotalHoursForRole('support','csr_prepare','csr_review')} hours
                            </label>
                        </div>
                    </div>
                </div>
            </div>
           <Table responsive striped style={{'color':'#676a6c'}} >
                <thead >
                    <tr style={{"font":'13px', 
                        'height':'70px',
                        'padding': '5px 8px',
                        'border-bottom': '1px solid #b3c0c7',
                        'background-color': '#ececec'}}
                    >
                        <th style={{ 'font-size':'120%'}} className="blueHeader pb-1 align-middle text-center">
                            { props.selectedDepartment.charAt(0).toUpperCase() + props.selectedDepartment.slice(1) + " Lines" }
                        </th>
                        <th className="blueHeader pb-1 align-middle text-center">
                            Organic Revenue 
                        </th>
                        <th  className="blueHeader pb-1 align-middle text-center">
                            Hours per week per for department 
                        </th>
                        <th className="blueHeader pb-1 align-middle text-center">
                            Total items per week for department
                        </th>
                        <th className="blueHeader pb-1 align-middle text-center">
                            Total items per day for department
                        </th>
                    </tr>
                </thead>
                <tbody style= {{"border": "0"}}>
                    <tr></tr>
                    <tr></tr>
                    {tableBody}
                </tbody>
            </Table>
        </section>
        {isloading && <LoadingModal modal={isloading} />}
        </>

    );
    }
}

export default HabitsReport;
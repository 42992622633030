import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import {Table} from 'reactstrap';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';

import ogcAction from "../../actions/OGCAction";
import {GET_LOB, UPDATE_LOB, GET_ADD_OTHER_DEPT, UPDATE_ADD_OTHER_DEPT} from '../../actions/ActionTypes';
import {Button} from 'reactstrap';
import AddLob from "./AddLob";
import '../../static/css/index.css';
import { DEPARTMENTS } from "./Constants";
import { Redirect } from "react-router";
import LoadingModal from "./LoadingModal";

const LOBData = (props) => {
    const [lobList, setLobList] = useState([]);
    const [inactiveLobList, setInactiveLobList] = useState([]);
    const [isOpenAddLob, setisOpenAddLob] = useState(false);
    const [totalCustomers, setTotalCustomer] = useState(0);
    const [headerLabel, setHeaderLabel] = useState('');
    const [flag,setflag] = useState(false);
    const [isloading,setLoad]  = useState(false);

    useEffect(() => {
        if (props.isAuthenticated) {
        
            if (props.selectedDepartment === "personal"){
                setHeaderLabel("Personal Lines LOB")
            }
            else if (props.selectedDepartment === "commercial"){
                setHeaderLabel("Commercial Lines LOB")
            }
            getLobList();
            getAddOtherDept();
            
            // return function would be called when component unmount. 
            /*
            return () => {
                props.showConfirmModal({
                    msgTitle: 'Save / Discard Changes !',
                    msgBody: [
                        <p>Do you want to save / discard the changes ?</p>
                    ],
                    buttons: [
                        {
                            label : 'Save',
                            onClick : async() => {
                                await saveInfo();
                                //this.setAreFieldValueChanged(false);
                                props.setLobInfo(null);
                            },
                            className : 'btn-success'
                        },
                        {
                            label : 'Discard',
                            onClick : () => {
                                props.setLobInfo(null);
                                props.setAreLobFieldValueChanged(false);
                            },
                            className : 'btn-danger'
                        },
                        {
                            label : 'Cancel',
                            onClick : () => {
                                let lob_details = {lobList};
                                window.location.hash = '#0';
                                props.setLobInfo(lob_details);
                                props.setAreLobFieldValueChanged(true);
                            },
                            className : 'btn-primary'
                        }
                    ]
                });
    
                


              }
              */
        }
     
    }, [props.isAuthenticated, props.year, props.selectedDepartment, props.isLobChanged]);

    const getAddOtherDept = async () => {
        try{
            let result = await ogcAction({ type: GET_ADD_OTHER_DEPT,
                year: props.year,
                department:DEPARTMENTS[props.selectedDepartment]}
            );

            if(result){
                props.handleAddDeptRadio(result.add_other_dept);
                setTotalCustomer(result.total_custumers);
                console.log(totalCustomers);
                
            }
            

        }
        catch(error) {
            toast.error(error);
            setLoad(false);
        }
    }
    
    const getLobList = async () => {
        setLoad(true);
        try {
            let result = await ogcAction({  type: GET_LOB,
                                            year: props.year,
                                            department:DEPARTMENTS[props.selectedDepartment]}
                                        );
            if (result) {
                let lobList = result.map(lob => ({...lob}));
                
                let activeLobList = lobList.filter(lob => {
                    return lob.show;
                });
                setLobList(activeLobList);
                
                
                let inactiveLobList = lobList.filter(lob => {
                    return !lob.show;
                });
                setInactiveLobList(inactiveLobList);
                setLoad(false);
            }
            else{
                setflag(true)
                setLoad(false);
            }
            
        } catch(error) {
            toast.error(error);
            setLoad(false);
        }
        return;
    }

    const getCalculatedValue = (lobObj) => {
        if (totalCustomers > 0) {
            return Math.round((lobObj['number_of_policies'] / totalCustomers) * 100);
        }
        return 0;
    };
    const handleInputChange = (id, field, value) => {
        let updatedList = lobList.map(lob => {
            if(lob.id === id) {
                return {
                    ...lob, [field]: value
                }
            }
            return {...lob};
        });
        setLobList(updatedList);
    }

    const handleInputBlur = (id, field, fieldType, value) => {
        let updatedList = lobList.map(lob => {
            if(lob.id === id) {
                if (isNaN(value)) {
                    return {...lob, [field]: 0}
                }
                if (fieldType === 'percent' && value > 100) {
                    return {...lob, [field]: 100}
                }
                return {...lob};
            }
            return {...lob};
        });
        setLobList(updatedList);
        targetCustomersValidationAgainstCurrentCustomer(id);
    }

    const targetCustomersValidationAgainstCurrentCustomer = (lobId) =>{
        let isValidated = true;
        const lob = lobList.filter(lob => lob.id === lobId)[0];
        const percentageTotalCust =  getCalculatedValue(lob);
        if (lob.percent_target_customers < percentageTotalCust){
            isValidated = false;
        }
        return isValidated;
    }
    
    const toggleModal = () =>{
        setisOpenAddLob(!isOpenAddLob);
        getLobList();
    }

    useEffect(() => {
        if (props.shouldSave) {
            saveInfo();
            props.setShouldSave(false);
        }
    }, [props.shouldSave]);

    const saveInfo = async () => {
        console.log(lobList);
        console.log(props.year);
        console.log(props.selectedDepartment);
        for(var i=0;i<lobList.length;i++){
            let isValidate = targetCustomersValidationAgainstCurrentCustomer(lobList[i].id);
            if(!isValidate){
                toast.error("% Target Customer can't be less the % Current Customers for LOB.");
                return;
            }
        }
        try {
            const result = await ogcAction({
                type: UPDATE_LOB, year: props.year, 
                department:DEPARTMENTS[props.selectedDepartment],
                data: lobList
            });
            if(result) {
                toast.success("Saved Successfully.")
            }
        } catch(error) {
            toast.error(error);
        }
        return;
    }

    let lobObj = {};
    lobList.map(lob => {
        if (lob.department) {
            if (lobObj[lob.department]) {
                lobObj[lob.department].push({...lob});
            } else {
                lobObj[lob.department] = [{...lob}];
            }
        }
        return false;
    });
    let tableBody = [];
    Object.keys(lobObj).map((dept, index) => {
        lobObj[dept].map((lob, lobIndex) => {
            tableBody.push(
                <tr style= {{"border": "0"}} key={`lob-${index}-${lobIndex}`}>
                    <td style={{'border':'0px'}} className="align-middle pl-3">
                        {lob.name}
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0} prefix={'$ '}
                            onValueChange={values => handleInputChange(
                                lob.id, 'avg_premium', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'avg_premium', 'amount', lob.avg_premium
                            )}
                            value={lob.avg_premium}
                            allowNegative={false}
                            disabled={true}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={2} suffix={' %'}
                            onValueChange={values => handleInputChange(
                                lob.id, 'avg_commission', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'avg_commission', 'percent', lob.avg_commission
                            )}
                            value={lob.avg_commission}
                            allowNegative={false}
                            disabled={false}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        />
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={2} suffix={' %'}
                            onValueChange={values => handleInputChange(
                                lob.id, 'close_rate', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'close_rate', 'percent', lob.close_rate
                            )}
                            value={lob.close_rate}
                            allowNegative={false}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            onValueChange={values => handleInputChange(
                                lob.id, 'no_of_prospects', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'no_of_prospects', 'number', lob.no_of_prospects
                            )}
                            value={lob.no_of_prospects}
                            allowNegative={false}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        />
                    </td>
                    
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat className="form-control form-control-sm text-center"
                             thousandSeparator={true} decimalScale={0}
                            onValueChange={values => handleInputChange(
                                lob.id, 'number_of_policies', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'number_of_policies', 'number', lob.number_of_policies
                            )}
                            value={lob.number_of_policies}
                            allowNegative={false}
                            disabled={true}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        />
                    </td>
                    
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={2} suffix={' %'}
                            onValueChange={values => handleInputChange(
                                lob.id, 'percent_customers_with_policy', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'percent_customers_with_policy', 'percent', lob.percent_target_customers
                            )}
                            value={getCalculatedValue(lob)}
                            allowNegative={false}
                            disabled={true}
                           
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        />
                    </td>

                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={2} suffix={' %'}
                            onValueChange={values => handleInputChange(
                                lob.id, 'percent_target_customers', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'percent_target_customers', 'percent', lob.percent_target_customers
                            )}
                            value={lob.percent_target_customers}
                            allowNegative={false}

                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit", color:`${!targetCustomersValidationAgainstCurrentCustomer(lob.id)?'red':''}`}}
                        />
                    </td>
                </tr>
            );
            return false;
        });
        return false;
    });

    if (flag){
        return(<Redirect to='/login' />);
    }
    else{
        return (
            <>
          
            <div className="mt-2">
            <div className=" p-2" style={{color:"black",fontWeight:"bold", backgroundColor:"#2dabc1"}}>{headerLabel}</div>
            <Table responsive striped style={{'color':'#676a6c'}} >
                <thead >
                    <tr style={{"font":'13px', 
                                'height':'70px',
                                'padding': '5px 8px',
                                'border-bottom': '1px solid #b3c0c7',
                                'background-color': '#ececec'}}>
                        
                        <th  className="lobHeaderColor"></th>
                        <th className="lobHeaderColor pb-1 align-middle text-center" data-tip data-for={`average-premium`}>
                            Average Premium
                            <ReactTooltip id={`average-premium`} 
                                    className="react-tooltip-class"
                                    delayShow={200}
                            >
                                {"Average premium for each LOB for a 12-month period."}
                            </ReactTooltip>
                        </th>
                        <th className="lobHeaderColor pb-1 align-middle text-center" data-tip data-for={`average-commision`}>
                            Average Commission
                            <ReactTooltip id={`average-commision`} 
                                    className="react-tooltip-class"
                                    delayShow={200}
                            >
                                {"Percentage paid by the carrier for calculating commission revenue for LOB."}
                            </ReactTooltip>
                        </th>
                        <th className="lobHeaderColor pb-1 align-middle text-center" data-tip data-for={`close-rate`}>
                            Close Rate
                            <ReactTooltip id={`close-rate`} 
                                    className="react-tooltip-class"
                                    delayShow={200}
                            >
                                {"Percentage of proposals reviewed with a Prospect or Customer resulting in one or more new lines of business."}
                            </ReactTooltip>
                        </th>
                        <th className="lobHeaderColor pb-1 align-middle text-center" data-tip data-for={`num-of-prospects`}>
                            Number of Prospects
                            <ReactTooltip id={`num-of-prospects`} 
                                    className="react-tooltip-class"
                                    delayShow={200}
                            >
                                {"Number of new leads or contacts in order to have the request/opportunity to provide a quote on one or more lines of business."}
                            </ReactTooltip>
                        </th>
                        <th className="lobHeaderColor pb-1 align-middle text-center" data-tip data-for={`num-of-policy`}>
                            Number of Policies
                            <ReactTooltip id={`num-of-policy`} 
                                    className="react-tooltip-class"
                                    delayShow={200}
                            >
                                {`Number of policies at the end of ${props.year - 1}`}
                            </ReactTooltip>
                        </th>
                        <th className="lobHeaderColor pb-1 align-middle text-center" data-tip data-for={`percentage-cust-policy`}>
                            Percentage of customers with policy
                            <ReactTooltip id={`percentage-cust-policy`} 
                                    className="react-tooltip-class"
                                    delayShow={200}
                            >
                                {`Percentage of total agency customers/accounts in the department at the end of ${props.year - 1} with the LOB.`}
                            </ReactTooltip>
                        </th>
                        <th className="lobHeaderColor pb-1 align-middle text-center" data-tip data-for={`target-cust-with-policy`}>
                        % Target customers with policies
                        <ReactTooltip id={`target-cust-with-policy`} 
                                    className="react-tooltip-class"
                                    delayShow={200}
                            >
                                {`Percentage of customers by department planned to have a LOB policy in ${props.year} .`}
                            </ReactTooltip>
                        </th>
                    </tr>
                </thead>
                <tbody style= {{"border": "0"}}>
                    <tr></tr>
                    <tr></tr>
                    {tableBody}
                </tbody>
            </Table>
            {isOpenAddLob &&
                    <AddLob openModal={isOpenAddLob} 
                    togglebModal={toggleModal}
                    activeLobList = {lobList}
                    inactiveLobList = {inactiveLobList} 
                    year = {props.year}
                    selectedDepartment = {props.selectedDepartment}
                />
                    }
            </div>
            {isloading && <LoadingModal modal={isloading} />}
        </>
        )
    }
}

export default LOBData;

import {required, isEmail} from './Validatiors';

export const ValidationRules = [
    {
        field: 'username',
        method: required,
        message: 'Please provide username.'
    },
    {
        field: 'username',
        method: isEmail,
        message: 'Please provide correct email.'
    },
    {
        field: 'password',
        method: required,
        message: 'Please provide password.'
    },
    {
        field: 'new_password',
        method: required,
        message: 'Please provide password.'
    },
    {
        field: 'confirm_password',
        method: required,
        message: 'Please provide password.'
    }
];

import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import { Redirect } from "react-router";
import {
    Table, Button
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import ogcAction from "../../actions/OGCAction";
import {GET_LOB_ORGANIC_GROWTH} from '../../actions/ActionTypes';
import { DEPARTMENTS } from "./Constants";

import LoadingModal from "./LoadingModal";

const LobReport = (props) => {

    const [lobOrganicGrowth, setLobOrganicGrowth] = useState([]);
    const [flag,setflag] = useState(false);
    const [isloading,setLoad]  = useState(false);

    useEffect(() => {
        if (props.isAuthenticated) {
            getLobOrganicGrowth();

        }
    }, [props.year, props.selectedDepartment]);

    const getLobOrganicGrowth = async () => {
        setLoad(true);
        try {
            let result = await ogcAction({  
                                    type: GET_LOB_ORGANIC_GROWTH,
                                    year: props.year,
                                    department:DEPARTMENTS[props.selectedDepartment]}
                                        );
            if (result) {
                setLobOrganicGrowth(result);
                setLoad(false);
            }
            else{
                setflag(true)
            }
        } catch(error) {
            toast.error(error);
            setLoad(false);
        }
        return;
    }


    const getTotalHours = (field) => {
        let total = 0 
        lobOrganicGrowth.map((lob,index) => {
           total = total + parseFloat(lob[field]);
        }); 
        return Math.round(total * 100)/100;
    }

    const getTotalEmployees = (role) => {
        if (lobOrganicGrowth.length>0) {
            return lobOrganicGrowth[0][role];
        }
        return 0;
    }

    const getTotalHoursPerWeek = (lob) => {
        const total = parseFloat( lob.dept_completion_time_for_quotes)+
                        parseFloat(lob.dept_completion_time_for_policy);
        return total;
    }
    
    // const getTotalItemsPerWeek = (lob) => {
    //     const total = lob.ask_for_quotes/52 + 
    //                     parseFloat(lob.completed_quotes)/52.0 + 
    //                     parseFloat(lob.new_policies)/52;
    //     return total;
    // }

    // const getTotalItemsPerDay = (lob) => {
    //     const total = lob.ask_for_quotes/52/5 + 
    //                     parseFloat(lob.completed_quotes)/52.0/5.0 + 
    //                     parseFloat(lob.new_policies)/52/5;
    //     return total;
    // }

    let tableBody = [];
    
    lobOrganicGrowth.map((lob,lobIndex) => {
        tableBody.push(
            <React.Fragment>
                <tr style= {{"border": "0"}} key={lobIndex}>
                    <td  style={{'border':'0px'}} className="font-weight-bold bg-d9">
                        {lob.name}
                    </td>
                    <td style={{'border':'0px'}} className="font-weight-bold bg-d9 align-middle py-0">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0} prefix={'$ '}
                            value={lob.organic_growth}
                            allowNegative={false}
                            disabled={true}
                            style = {{'border': '0px',backgroundColor:"inherit", 'font-weight':'bold'}}
                        /> 
                    </td>
                    <td style={{'border':'0px' ,'text-align':'center' ,'width': '23%'}} className="font-weight-bold bg-d9 align-middle">
                        {lob.revenue_current}% of customers to {lob.revenue_targeted}%
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={getTotalHoursPerWeek(lob)}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px',backgroundColor:"inherit", fontWeight:'bold'}}
                            /> 
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        {/* <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={getTotalItemsPerWeek(lob)}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px',backgroundColor:"inherit", fontWeight:'bold'}}
                            />  */}
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        {/* <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={getTotalItemsPerDay(lob)}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px',backgroundColor:"inherit", fontWeight:'bold'}}
                            />  */}
                    </td>
                   
                    </tr>
                    <tr style= {{"border": "0"}} key={lobIndex}>
                    <td style={{'border':'0px'}} className="pl-3">
                        Ask for {lob.name} quote
                    </td>                
                    <td style={{ backgroundColor:'#f2f2f2', 'border':'0px'}} className="pl-3"></td>
                    <td style={{backgroundColor:'#f2f2f2' , 'border':'0px'}} className="pl-3"></td>
                    <td style={{backgroundColor:'#f2f2f2','border':'0px'}} className="pl-3"></td>
                    <td style={{backgroundColor:'#f2f2f2','border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={lob.ask_for_quotes/52}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={lob.ask_for_quotes/52/5}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                </tr>
                <tr style= {{"border": "0"}} key={lobIndex}>
                    <td style={{'border':'0px'}} className="pl-3">
                        Review {lob.name} quote with client
                    </td>                
                    <td style={{'border':'0px'}} className="pl-3"></td>
                    <td style={{'border':'0px'}} className="pl-3"></td>
                    <td style={{'border':'0px'}} className="pl-3">
                    <NumberFormat className="form-control form-control-sm text-center"
                        thousandSeparator={true} decimalScale={0}
                        value={lob.dept_completion_time_for_quotes}
                        allowNegative={false}
                        disabled={true}
                        decimalScale={2}
                        style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                    />
                    </td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={parseFloat(lob.completed_quotes)/52.0}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={parseFloat(lob.completed_quotes)/52.0/5.0}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                </tr>
                <tr style= {{"border": "0"}} key={lobIndex}>
                    <td style={{'border':'0px'}} className="pl-3">
                        New {lob.name} policies
                    </td>                
                    <td style={{'border':'0px'}} className="pl-3"></td>
                    <td style={{'border':'0px'}} className="pl-3"></td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={lob.dept_completion_time_for_policy}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        />
                    </td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={parseFloat(lob.new_policies)/52}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                    <td style={{'border':'0px'}} className="pl-3">
                        <NumberFormat className="form-control form-control-sm text-center"
                            thousandSeparator={true} decimalScale={0}
                            value={parseFloat(lob.new_policies)/52/5}
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                        /> 
                    </td>
                </tr>
            
            </React.Fragment>
        )
    });
    
    if (flag){
        return(<Redirect to='/login' />);
    }
    else{
    return (
        <>
       <section className="container-fluid">
           <div className="row no-gutters">
                <div className={`col-sm`}>
                    <div className="card section-border mb-3" style={{minWidth: '30%'}}>
                        <div style={{color:'white' , backgroundColor:"#209487"}} className="card-header text-white text-center mb-0 p-2">
                            <label  style={{fontSize:"11px",fontWeight:"bolder",color:"white"}} className="mb-0">
                            Outside Producers - Total Hours
                            </label>
                            <label  style={{fontSize:"11px",color:"white"}} className="mb-0">
                            Total/Individual
                            </label>
                        </div>
                        <div className="card-body text-center font-weight-bold p-2 pt-4 pb-4">
                        <label style={{ fontWeight:"bold",'font-size':'120%'}}>
                                {Math.round((getTotalHours('outside_sales_policy') + getTotalHours('outside_sales_quotes'))*100)/100} hours  / {Math.round((getTotalHours('outside_sales_policy') + getTotalHours('outside_sales_quotes'))/getTotalEmployees('outside_producer')*100)/100}
                        </label>
                        </div>
                    </div>
                </div>
                <div className={`col-sm ml-5`}>
                    <div className="card section-border mb-3" style={{minWidth: '30%'}}>
                        <div style={{color:'black' , backgroundColor:"#209487"}} className="card-header text-white text-center mb-0 p-2">
                            <label style={{fontSize:"11px",fontWeight:"bolder",color:"white"}} className="mb-0">
                                Inside Sales - Total Hours
                            </label>
                            <label  style={{fontSize:"11px",color:"white"}} className="mb-0">
                            Total/Individual
                            </label>
                        </div>
                        <div className="card-body text-center font-weight-bold p-2 pt-4 pb-4" style={{minWidth: '30%'}}>
                            <label style={{ fontWeight:"bold", 'font-size':'120%'}}>
                                    {Math.round((getTotalHours('inside_sales_policy') + getTotalHours('inside_sales_quotes'))*100)/100} hours / {Math.round((getTotalHours('inside_sales_policy') + getTotalHours('inside_sales_quotes'))/getTotalEmployees('inside_sales')*100)/100}
                            </label>
                        </div>
                    </div>
                </div>
                <div className={`col-sm ml-5`}>
                    <div className="card section-border mb-3" style={{minWidth: '20%'}}>
                        <div style={{color:'white' , backgroundColor:"#209487"}} className="card-header text-white text-center mb-0 p-2">
                            <label style={{fontSize:"11px", fontWeight:"bolder",color:"white"}} className="mb-0">
                                Account Manager - Total Hours
                            </label>
                            <label  style={{fontSize:"11px",color:"white"}} className="mb-0">
                            Total/Individual
                            </label>
                        </div>
                        <div className="card-body text-center font-weight-bold p-2 pt-4 pb-4">
                            <label style={{fontWeight:"bold",'font-size':'120%'}}>
                                {Math.round((getTotalHours('account_manager_policy') + getTotalHours('account_manager_quotes'))*100)/100} hours / {Math.round((getTotalHours('account_manager_policy') + getTotalHours('account_manager_quotes'))/getTotalEmployees('account_manager')*100)/100}
                            </label>


                        </div>
                    </div>
                </div>
                <div className={`col-sm ml-5`}>
                    <div className="card section-border mb-3" style={{minWidth: '20%'}}>
                        <div style={{color:'white' , backgroundColor:"#209487"}} className="card-header text-white text-center mb-0 p-2">
                            <label style={{fontSize:"11px",fontWeight:"bolder", color:"white"}} className="mb-0">
                            CSR/VA -  Total Hours
                            </label>
                            <label  style={{fontSize:"11px",color:"white"}} className="mb-0">
                            Total/Individual
                            </label>
                        </div>
                        <div className="card-body text-center font-weight-bold p-2 pt-4 pb-4">
                            <label style={{fontWeight:"bold",'font-size':'16px'}}>
                            {Math.round((getTotalHours('csr_policy') + getTotalHours('csr_quotes'))*100)/100} hours / { Math.round((getTotalHours('csr_policy') + getTotalHours('csr_quotes'))/getTotalEmployees('support')*100)/100}                              </label>
                        </div>
                    </div>
                </div>
            </div>
            <Table responsive striped style={{'color':'#676a6c'}} >
                <thead >
                    <tr style={{"font":'13px', 
                        'height':'70px',
                        'padding': '5px 8px',
                        'border-bottom': '1px solid #b3c0c7',
                        'background-color': '#ececec'}}
                    >
                        <th style={{ 'font-size':'120%'}} className="blueHeader pb-1 align-middle text-center">
                            { props.selectedDepartment.charAt(0).toUpperCase() + props.selectedDepartment.slice(1) + " Lines" }
                        </th>
                        <th className="blueHeader pb-1 align-middle text-center">
                            Organic Revenue 
                        </th>
                        <th  className="blueHeader pb-1 align-middle text-center">
                            Planned Growth
                        </th>
                        <th  className="blueHeader pb-1 align-middle text-center">
                            Hours per week per for department 
                        </th>
                        <th className="blueHeader pb-1 align-middle text-center">
                            Total items per week for department
                        </th>
                        <th className="blueHeader pb-1 align-middle text-center">
                            Total items per day for department
                        </th>
                    </tr>
                </thead>
                <tbody style= {{"border": "0"}}>
                    <tr></tr>
                    <tr></tr>
                    {tableBody}
                </tbody>
            </Table>
        </section>
        {isloading && <LoadingModal modal={isloading} />}
    </>
    );
    }
}
export default LobReport;
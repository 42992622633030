import React, {useState, useEffect, isValidElement} from "react";
import {toast} from "react-toastify";
import {Table} from 'reactstrap';
import NumberFormat from 'react-number-format';

import ogcAction from "../../actions/OGCAction";
import {GET_LOB, GET_KPI_INFO, UPDATE_LOB, UPDATE_KPI_INFO} from '../../actions/ActionTypes';
import { DEPARTMENTS } from "./Constants";
import { Redirect } from "react-router";
import LoadingModal from "./LoadingModal";

const TimeNeeded = (props) => {
    const [lobList, setLobList] = useState([]);
    const [ogcKPI, setOGCKPI] = useState({});
    const [flag,setflag] = useState(false);
    const [isloading,setLoad]  = useState(false);
    //const [setShowErrorMsg]

    useEffect(() => {
        if (props.isAuthenticated) {
            getLobList();
            getKPIInfo();
            
        }
    }, [props.isAuthenticated, props.year, props.selectedDepartment]);

    const getKPIInfo = async () => {
        setLoad(true);
        try {
            const result = await ogcAction({
                type: GET_KPI_INFO, 
                year: props.year, 
                department:DEPARTMENTS[props.selectedDepartment]
            });
            console.log(result);
            if(result) {
                console.log(result);
                let kpi = {...result}
                console.log(kpi);
                setOGCKPI({...kpi});
                console.log(ogcKPI);
                setLoad(false);
            }
            else{
                setflag(true)
            }
            

        } catch(error) {
            setLoad(false);
          
            toast.error(error);
        }
        return;
    }


    const getLobList = async () => {
        setLoad(true);
        try {
            let result = await ogcAction({
                            type: GET_LOB, 
                            year: props.year,
                            department:DEPARTMENTS[props.selectedDepartment]
                        });
            if (result) {
                let lobList = result.map(lob => ({...lob}));
                let activeLobList = lobList.filter(lob => {
                    return lob.show;
                });
                setLobList(activeLobList);
                setLoad(false);
            }
        } catch(error) {
            toast.error(error);
        }
        return;
    }

    const handleInputChange = (id, field, value) => {
        let updatedList = lobList.map(lob => {
            if(lob.id === id) {
                return {
                    ...lob, [field]: value
                }
            }
            return {...lob};
        });
        setLobList(updatedList);
    };
   
    const handleKPIInputChange = (name, values) => {
        let {floatValue} = values;

        setOGCKPI({...ogcKPI, [name]: floatValue });
    };

    const handleKPIBlur = (name,fieldType) => {
        let KPI = {...ogcKPI};
        let value = ogcKPI[name];
        if (fieldType === 'percentage' && value > 100) {
            KPI = {...KPI, [name]: 100};
            setOGCKPI(KPI);
            return;
            
        }
        if (isNaN(value)) {
            KPI = {...KPI, [name]: 0};
            setOGCKPI(KPI);
        } 
        
    };

    const validatePercentageTimeForAllRoles = () => {
        let isInValidLOBFound = false;
        let returnObj = {
            validation: true,
            name: '',
            KpiName: '',
            isLobOrKpi: 'lob',
        }
        lobList.forEach(lob => {
            //Quote = Create Quote policy = Review Policy
            let totalPercentageQuote = lob.quote_for_producer_sales + lob.quote_for_inside_sales  + lob.quote_for_account_manager + lob.quote_for_supports
            let totalPercentagePolicy = lob.policy_for_producer_sales + lob.policy_for_inside_sales + lob.policy_for_account_manager   + lob.policy_for_supports
            if (totalPercentageQuote!==100 || totalPercentagePolicy!=100 && !isInValidLOBFound){
                returnObj.name = lob.name;
                returnObj.validation = false;
                isInValidLOBFound = true;
            } 
        });
        //  validating all KPIs
        returnObj.isLobOrKpi = "kpi";
        if(!isInValidLOBFound){
            let staffLeadsGoal = ogcKPI.leads_prepare_psptc + ogcKPI.leads_prepare_isptc
                                + ogcKPI.leads_prepare_msptc + ogcKPI.leads_prepare_ssptc
            let staffLeadsPolicy = ogcKPI.leads_review_psptc + ogcKPI.leads_review_isptc
                                    + ogcKPI.leads_review_msptc + ogcKPI.leads_review_ssptc
            let accountReviewGoal = ogcKPI.account_review_prepare_psptc + ogcKPI.account_review_prepare_isptc
                                    + ogcKPI.account_review_prepare_msptc + ogcKPI.account_review_prepare_ssptc
            let accountReviewPolicy = ogcKPI.account_review_psptc + ogcKPI.account_review_isptc
                                        + ogcKPI.account_review_msptc + ogcKPI.account_review_ssptc
            let customerReferralsGoal = ogcKPI.referral_prepare_psptc + ogcKPI.referral_prepare_isptc
                                        + ogcKPI.referral_prepare_msptc + ogcKPI.referral_prepare_ssptc
            let customerReferralsPolicy = ogcKPI.referral_review_psptc + ogcKPI.referral_review_isptc
                                            + ogcKPI.referral_review_msptc + ogcKPI.referral_review_ssptc
            
            if((accountReviewGoal!=100 || accountReviewPolicy!=100)&& !isInValidLOBFound ){
                returnObj.name = 'Account Review';
                returnObj.validation = false;
                isInValidLOBFound = true;
            }
            if((customerReferralsGoal!=100 || customerReferralsPolicy!=100)&& !isInValidLOBFound ){
                returnObj.name = 'Customer Referrals';
                returnObj.validation = false;
                isInValidLOBFound = true;
            }
            if ((staffLeadsGoal!=100 || staffLeadsPolicy!=100)&& !isInValidLOBFound){
                returnObj.name = 'Staff Leads';
                returnObj.validation = false;
                isInValidLOBFound = true;
            }
        }
        return returnObj;
    };

    const validateTotalPercentageForSingleKPI = (kpiName,property) => {
        // property could be quote, policy or both.
        let staffLeadsGoal = 100;
        let staffLeadsPolicy = 100;
        let accountReviewGoal = 100;
        let accountReviewPolicy = 100;
        let customerReferralsGoal = 100;
        let customerReferralsPolicy = 100;


        staffLeadsGoal = ogcKPI.leads_prepare_psptc + ogcKPI.leads_prepare_isptc
                        + ogcKPI.leads_prepare_msptc + ogcKPI.leads_prepare_ssptc
        staffLeadsPolicy = ogcKPI.leads_review_psptc + ogcKPI.leads_review_isptc
                                + ogcKPI.leads_review_msptc + ogcKPI.leads_review_ssptc;
        accountReviewGoal = ogcKPI.account_review_prepare_psptc + ogcKPI.account_review_prepare_isptc
                                + ogcKPI.account_review_prepare_msptc + ogcKPI.account_review_prepare_ssptc
        accountReviewPolicy = ogcKPI.account_review_psptc + ogcKPI.account_review_isptc
                                    + ogcKPI.account_review_msptc + ogcKPI.account_review_ssptc
        customerReferralsGoal = ogcKPI.referral_prepare_psptc + ogcKPI.referral_prepare_isptc
                                    + ogcKPI.referral_prepare_msptc + ogcKPI.referral_prepare_ssptc
        customerReferralsPolicy = ogcKPI.referral_review_psptc + ogcKPI.referral_review_isptc
                                        + ogcKPI.referral_review_msptc + ogcKPI.referral_review_ssptc;
        
        if (kpiName === 'staffLeads' && property === 'quote' && staffLeadsGoal!=100 && staffLeadsGoal!=0)
            return false;         
            
        if (kpiName === 'staffLeads' && property === 'policy' && staffLeadsPolicy!=100 && staffLeadsPolicy!=0)
            return false;
        
        if (kpiName === 'accountReview' && property === 'quote' && accountReviewGoal!=100 && accountReviewGoal!=0)
            return false;
        
        if (kpiName === 'accountReview' && property === 'policy' && accountReviewPolicy!=100 && accountReviewPolicy!==0)
            return false;

        if (kpiName === 'customerRefferal' && property === 'quote' && customerReferralsGoal!=100 && customerReferralsGoal!==0)
            return false;

        if (kpiName === 'customerRefferal' && property === 'policy' && customerReferralsPolicy!=100 && customerReferralsPolicy!==0)
            return false;
        
        return true;

    }

    const validateTotalPercentageForSingleLob = (lobId,property) =>{
        // property could be quote, policy or both. 
        let isValidated = true;
        lobList.map(lob => {
            if (lobId === lob.id){
                let totalPercentageQuote = 100;
                let totalPercentagePolicy = 100;
                if (property==='quote'){
                    totalPercentageQuote = lob.quote_for_producer_sales + lob.quote_for_inside_sales  + lob.quote_for_account_manager + lob.quote_for_supports
                }
                else if (property==='policy'){
                    totalPercentagePolicy = lob.policy_for_producer_sales + lob.policy_for_inside_sales + lob.policy_for_account_manager   + lob.policy_for_supports
                }
                else if(property==='both'){
                    totalPercentageQuote = lob.quote_for_producer_sales + lob.quote_for_inside_sales  + lob.quote_for_account_manager + lob.quote_for_supports
                    totalPercentagePolicy = lob.policy_for_producer_sales + lob.policy_for_inside_sales + lob.policy_for_account_manager   + lob.policy_for_supports
                }
                if (totalPercentageQuote!==100 || totalPercentagePolicy!=100){
                    isValidated =  false;
                }

                if(totalPercentagePolicy===0 && property === 'policy'){
                    isValidated = true;
                }
                if (totalPercentageQuote === 0 && property === 'quote'){
                    isValidated = true;
                }
                if (property === 'both' && (totalPercentageQuote === 0 && totalPercentagePolicy === 100) ){
                    isValidated = true;
                }

            }
        });
        return isValidated;
    }
    
    const handleInputBlur = (id, field, fieldType, value) => {
        let updatedList = lobList.map(lob => {
            if(lob.id === id) {
                if (isNaN(value)) {
                    return {...lob, [field]: 0}
                }
                if (fieldType === 'percent' && value > 100) {
                    return {...lob, [field]: 100}
                }
                return {...lob};
            }
            return {...lob};
        });
        validateTotalPercentageForSingleLob(id,'both');
        setLobList(updatedList);
    }

    useEffect(() => {
        if (props.shouldSave) {
            saveInfo();
            props.setShouldSave(false);
        }
    }, [props.shouldSave]);

    const saveInfo = async () => {
        // const returnObj = validatePercentageTimeForAllRoles();
        //console.log(returnObj.validation);
        // if (!returnObj.validation){
        //     toast.error("The percentage for all roles combined " +
        //                  "should be 100% for both Quote/Goal and Policy. "
        //                 + `The condition is not satisfied for ${returnObj.name}`);
        // } 
        // else{
            try {
                const result = await ogcAction({
                    type: UPDATE_LOB, year: props.year,
                    department: DEPARTMENTS[props.selectedDepartment],
                    data: lobList
                });
    
                const result2 = await ogcAction({
                    type: UPDATE_KPI_INFO, 
                    year: props.year, 
                    department:  DEPARTMENTS[props.selectedDepartment],
                    data: ogcKPI
                });
                if(result && result2) {
                    toast.success("Saved Successfully.")
                }
            } catch(error) {
                toast.error(error);
            }
        // }
        return;
    }

    let lobObj = {Personal: [], Commercial: [], 'Life-Health': []};
    lobList.map(lob => {
        if (lob.department) {
            if (lobObj[lob.department]) {
                lobObj[lob.department].push({...lob});
            } else {
                lobObj[lob.department] = [{...lob}];
            }
        }
        return false;
    });

    let tableBodyKPI = [];
    tableBodyKPI.push(
        <React.Fragment>
            <tr>
            <td></td>
            <td></td>
            <td></td>
            
            <td style={{"color":"black","font-weight":"bold"}} className = "align-middle font-14" colSpan="3">
               Time Needed for Habits
            </td>

            </tr>
            
            <tr style= {{"border": "0"}}>
                            <td style= {{"border": "0"}} className="align-middle font-14" colSpan="6">
                                <b>Account Review</b>
                            </td>
                        </tr>
                        <tr style= {{"border": "0"}}>
                            <td style= {{"border": "0"}} className="align-middle font-14 pl-3">
                                Create Quote
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat className="form-control form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0}
                                    suffix={` minute${
                                         ogcKPI.account_review_prepare_time > 1 ? "s" : ""
                                    }`}
                                    onValueChange={values => handleKPIInputChange(
                                        "account_review_prepare_time" , values
                                    )}
                                    value={ ogcKPI.account_review_prepare_time}
                                    onBlur={(e) => handleKPIBlur(
                                        "account_review_prepare_time","float"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit"}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.account_review_prepare_psptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'account_review_prepare_psptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'account_review_prepare_psptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('accountReview', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.account_review_prepare_isptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'account_review_prepare_isptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'account_review_prepare_isptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('accountReview', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.account_review_prepare_msptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'account_review_prepare_msptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'account_review_prepare_msptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('accountReview', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.account_review_prepare_ssptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'account_review_prepare_ssptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'account_review_prepare_ssptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('accountReview', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            </tr>

                        <tr style= {{"border": "0"}}>
                            <td style= {{"border": "0"}} className="align-middle font-14 pl-3">
                                Review Policy
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat className="form-control form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0}
                                    suffix={` minute${
                                        ogcKPI.account_review_time > 1 ? "s" : ""
                                    }`}
                                    onValueChange={(values) => handleKPIInputChange(
                                        "account_review_time" , values
                                    )}
                                    value={ogcKPI.account_review_time}
                                    onBlur={(e) => handleKPIBlur(
                                        "account_review_time", "float"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit"}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.account_review_psptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'account_review_psptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'account_review_psptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('accountReview', 'policy') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.account_review_isptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'account_review_isptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'account_review_isptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('accountReview', 'policy') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.account_review_msptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'account_review_msptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'account_review_msptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('accountReview', 'policy') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.account_review_ssptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'account_review_ssptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'account_review_ssptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('accountReview', 'policy') ? "" : ""}`}}
                                    
                                />
                            </td>
                        </tr>

                        <tr style= {{"border": "0"}}>
                            <td style= {{"border": "0"}} className="align-middle font-14" colSpan="6">
                                <b>Customer Referrals</b>
                            </td>
                        </tr>
                        <tr style= {{"border": "0"}}>
                            <td style= {{"border": "0"}} className="align-middle font-14 pl-3">
                                Create Quote
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat className="form-control form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0}
                                    suffix={` minute${
                                         ogcKPI.referral_prepare_time > 1 ? "s" : ""
                                    }`}
                                    onValueChange={values => handleKPIInputChange(
                                        "referral_prepare_time" , values
                                    )}
                                    value={ ogcKPI.referral_prepare_time}
                                    onBlur={(e) => handleKPIBlur(
                                        "referral_prepare_time", "float"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit"}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.referral_prepare_psptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'referral_prepare_psptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'referral_prepare_psptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('customerRefferal', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.referral_prepare_isptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'referral_prepare_isptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'referral_prepare_isptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('customerRefferal', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.referral_prepare_msptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'referral_prepare_msptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'referral_prepare_msptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('customerRefferal', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.referral_prepare_ssptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'referral_prepare_ssptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'referral_prepare_ssptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('customerRefferal', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            </tr>

                        <tr style= {{"border": "0"}}>
                            <td style= {{"border": "0"}} className="align-middle font-14 pl-3">
                                Review Policy
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat className="form-control form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0}
                                    suffix={` minute${
                                        ogcKPI.referral_review_time > 1 ? "s" : ""
                                    }`}
                                    onValueChange={(values) => handleKPIInputChange(
                                        "referral_review_time" , values
                                    )}
                                    value={ogcKPI.referral_review_time}
                                    onBlur={(e) => handleKPIBlur(
                                        "referral_review_time", "float"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit"}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.referral_review_psptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'referral_review_psptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'referral_review_psptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('customerRefferal', 'policy') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.referral_review_isptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'referral_review_isptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'referral_review_isptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('customerRefferal', 'policy') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.referral_review_msptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'referral_review_msptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'referral_review_msptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('customerRefferal', 'policy') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.referral_review_ssptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'referral_review_ssptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'referral_review_ssptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('customerRefferal', 'policy') ? "" : ""}`}}
                                />
                            </td>
                        </tr>


                        <tr style= {{"border": "0"}}>
                            <td style= {{"border": "0"}} className="align-middle font-14" colSpan="6">
                                <b>Staff Leads</b>
                            </td>
                        </tr>
                        <tr style= {{"border": "0"}}>
                            <td style= {{"border": "0"}} className="align-middle font-14 pl-3">
                                Create Quote
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat className="form-control form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0}
                                    suffix={` minute${
                                         ogcKPI.leads_prepare_time > 1 ? "s" : ""
                                    }`}
                                    onValueChange={values => handleKPIInputChange(
                                        "leads_prepare_time" , values
                                    )}
                                    value={ ogcKPI.leads_prepare_time}
                                    onBlur={(e) => handleKPIBlur(
                                        "leads_prepare_time", "float"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit"}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.leads_prepare_psptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'leads_prepare_psptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'leads_prepare_psptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('staffLeads', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.leads_prepare_isptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'leads_prepare_isptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'leads_prepare_isptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('staffLeads', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.leads_prepare_msptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'leads_prepare_msptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'leads_prepare_msptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('staffLeads', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ ogcKPI.leads_prepare_ssptc}
                                    onValueChange={values => handleKPIInputChange(
                                        'leads_prepare_ssptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'leads_prepare_ssptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('staffLeads', 'quote') ? "" : ""}`}}
                                    
                                />
                            </td>
                            </tr>

                        <tr style= {{"border": "0"}}>
                            <td style= {{"border": "0"}} className="align-middle font-14 pl-3">
                                Review Policy
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat className="form-control form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0}
                                    suffix={` minute${
                                        ogcKPI.leads_review_time > 1 ? "s" : ""
                                    }`}
                                    onValueChange={(values) => handleKPIInputChange(
                                        "leads_review_time" , values
                                    )}
                                    value={ogcKPI.leads_review_time}
                                    onBlur={(e) => handleKPIBlur(
                                        "leads_review_time", "float"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit"}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.leads_review_psptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'leads_review_psptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'leads_review_psptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('staffLeads', 'policy') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.leads_review_isptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'leads_review_isptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'leads_review_isptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('staffLeads', 'policy') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.leads_review_msptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'leads_review_msptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'leads_review_msptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('staffLeads', 'policy') ? "" : ""}`}}
                                    
                                />
                            </td>
                            <td style={{'border':'0px'}} className="align-middle py-0">
                                <NumberFormat 
                                    decimalScale={0} suffix={' %'}
                                    className="form-control form-control-sm text-center" 
                                    thousandSeparator={true} 
                                    value={ogcKPI.leads_review_ssptc}
                                    onValueChange={(values) => handleKPIInputChange(
                                        'leads_review_ssptc', values
                                    )}
                                    onBlur={() => handleKPIBlur(
                                        'leads_review_ssptc', "percentage"
                                    )}
                                    style = {{'border': '0px',backgroundColor:"inherit", color: `${!validateTotalPercentageForSingleKPI('staffLeads', 'policy') ? "" : ""}`}}
                                />
                            </td>
                        </tr>
        </React.Fragment>

    )


    let tableBody = [];
    Object.keys(lobObj).map((dept, deptIndex) => {
        lobObj[dept].map((lob, index) => {
            tableBody.push(
                <React.Fragment key={`frag-${deptIndex}-${index}`}>
                    {index===0 &&
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{"color":"black","font-weight":"bold"}} className = "align-middle font-14" colSpan="3">
                            Time Needed for LOBs
                        </td>
                    </tr>
                    }
                   
                    <tr style= {{"border": "0"}} key={`lob-${deptIndex}-${index}`}>
                        <td style= {{"border": "0" ,'background-color': '#ececec'}} className="bg-f5 align-middle font-weight-bold bg-d9" colSpan={6}>
                            {lob.name}
                        </td>
                         
                    </tr>
                    <tr></tr>
                    <tr style={{'border':'0'}} key={`quote-${deptIndex}-${index}`}>
                        <td style={{'border':'0px'}} className="align-middle pl-3" style={{minWidth: '100px','border':'0px'}}>
                            Create Quote
                        </td>
                        <td style={{'border':'0px'}} className="align-middle py-0">
                            <NumberFormat 
                                decimalScale={0} suffix={` Minute${
                                    lob.time_to_complete_quote > 1 ? "s" : ""
                                }`}
                                className="form-control form-control-sm text-center" 
                                thousandSeparator={true} 
                                value={lob.time_to_complete_quote}
                                onValueChange={values => handleInputChange(
                                    lob.id, 'time_to_complete_quote', values.floatValue
                                )}
                                onBlur={() => handleInputBlur(
                                    lob.id, 'time_to_complete_quote', 'time', 
                                    lob.time_to_complete_quote
                                )}
                                style = {{'border': '0px',backgroundColor:"inherit"}}
                            />
                        </td>
                        <td style={{'border':'0px'}} className="align-middle py-0">
                            <NumberFormat 
                                decimalScale={0} suffix={' %'}
                                className="form-control form-control-sm text-center" 
                                thousandSeparator={true} 
                                value={lob.quote_for_producer_sales}
                                onValueChange={values => handleInputChange(
                                    lob.id, 'quote_for_producer_sales', values.floatValue
                                )}
                                onBlur={() => handleInputBlur(
                                    lob.id, 'quote_for_producer_sales', 'percent', 
                                    lob.quote_for_producer_sales
                                )}
                                //validateTotalPercentageForSingleLob(lob.id) 
                                
                                
                                style = {{'border': '0px', backgroundColor:'inherit',  color: `${!validateTotalPercentageForSingleLob(lob.id, 'quote') ? "" : ""}`}} 
                            />
                        </td>
                        <td style={{'border':'0px'}} className="align-middle py-0">
                            <NumberFormat 
                                decimalScale={0} suffix={' %'}
                                className="form-control form-control-sm text-center" 
                                thousandSeparator={true} 
                                value={lob.quote_for_inside_sales}
                                onValueChange={values => handleInputChange(
                                    lob.id, 'quote_for_inside_sales', values.floatValue
                                )}
                                onBlur={() => handleInputBlur(
                                    lob.id, 'quote_for_inside_sales', 'percent', 
                                    lob.quote_for_inside_sales
                                )}
                                style = {{'border': '0px', backgroundColor:'inherit',  color: `${!validateTotalPercentageForSingleLob(lob.id, 'quote') ? "" : ""}`}} 
                            />
                        </td>
                        <td style={{'border':'0px'}} className="align-middle py-0">
                            <NumberFormat 
                                decimalScale={0} suffix={' %'}
                                className="form-control form-control-sm text-center" 
                                thousandSeparator={true} 
                                value={lob.quote_for_account_manager}
                                onValueChange={values => handleInputChange(
                                    lob.id, 'quote_for_account_manager', values.floatValue
                                )}
                                onBlur={() => handleInputBlur(
                                    lob.id, 'quote_for_account_manager', 'percent', 
                                    lob.quote_for_account_manager
                                )}
                                style = {{'border': '0px', backgroundColor:'inherit',  color: `${!validateTotalPercentageForSingleLob(lob.id, 'quote') ? "" : ""}`}} 
                            />
                        </td>
                        <td style={{'border':'0px'}} className="align-middle py-0">
                            <NumberFormat 
                                decimalScale={0} suffix={' %'}
                                className="form-control form-control-sm text-center" 
                                thousandSeparator={true} 
                                value={lob.quote_for_supports}
                                onValueChange={values => handleInputChange(
                                    lob.id, 'quote_for_supports', values.floatValue
                                )}
                                onBlur={() => handleInputBlur(
                                    lob.id, 'quote_for_supports', 'percent', 
                                    lob.quote_for_supports
                                )}
                                style = {{'border': '0px', backgroundColor:'inherit',  color: `${!validateTotalPercentageForSingleLob(lob.id, 'quote') ? "" : ""}`}} 
                            />
                        </td>
                    </tr>
                    <tr style={{'border':'0px'}} key={`policy-${deptIndex}-${index}`}>
                    <td className="align-middle pl-3" style={{minWidth: '100px','border':'0px'}}>
                        Review Policy
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat 
                            decimalScale={0} suffix={` Minute${
                                lob.time_to_complete_policy > 1 ? "s" : ""
                            }`}
                            className="form-control form-control-sm text-center" 
                            thousandSeparator={true} 
                            value={lob.time_to_complete_policy}
                            onValueChange={values => handleInputChange(
                                lob.id, 'time_to_complete_policy', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'time_to_complete_policy', 'time', 
                                lob.time_to_complete_policy
                            )}
                            style = {{'border': '0px',backgroundColor:"inherit"}}
                        />
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat 
                            decimalScale={0} suffix={' %'}
                            className="form-control form-control-sm text-center" 
                            thousandSeparator={true} 
                            value={lob.policy_for_producer_sales}
                            onValueChange={values => handleInputChange(
                                lob.id, 'policy_for_producer_sales', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'policy_for_producer_sales', 'percent', 
                                lob.policy_for_producer_sales
                            )}
                            style = {{'border': '0px', backgroundColor:'inherit',  color: `${!validateTotalPercentageForSingleLob(lob.id, 'policy') ? "" : ""}`}} 
                        />
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat 
                            decimalScale={0} suffix={' %'}
                            className="form-control form-control-sm text-center" 
                            thousandSeparator={true} 
                            value={lob.policy_for_inside_sales}
                            onValueChange={values => handleInputChange(
                                lob.id, 'policy_for_inside_sales', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'policy_for_inside_sales', 'percent', 
                                lob.policy_for_inside_sales
                            )}
                            style = {{'border': '0px', backgroundColor:'inherit',  color: `${!validateTotalPercentageForSingleLob(lob.id, 'policy') ? "" : ""}`}} 
                        />
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat 
                            decimalScale={0} suffix={' %'}
                            className="form-control form-control-sm text-center" 
                            thousandSeparator={true} 
                            value={lob.policy_for_account_manager}
                            onValueChange={values => handleInputChange(
                                lob.id, 'policy_for_account_manager', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'policy_for_account_manager', 'percent', 
                                lob.policy_for_account_manager
                            )}
                            style = {{'border': '0px', backgroundColor:'inherit',  color: `${!validateTotalPercentageForSingleLob(lob.id, 'policy') ? "" : ""}`}} 
                        />
                    </td>
                    <td style={{'border':'0px'}} className="align-middle py-0">
                        <NumberFormat 
                            decimalScale={0} suffix={' %'}
                            className="form-control form-control-sm text-center" 
                            thousandSeparator={true} 
                            value={lob.policy_for_supports}
                            onValueChange={values => handleInputChange(
                                lob.id, 'policy_for_supports', values.floatValue
                            )}
                            onBlur={() => handleInputBlur(
                                lob.id, 'policy_for_supports', 'percent', 
                                lob.policy_for_supports
                            )}
                            style = {{'border': '0px', backgroundColor:'inherit',  color: `${!validateTotalPercentageForSingleLob(lob.id, 'policy') ? "" : ""}`}} 
                        />
                    </td>
                </tr>
                </React.Fragment>
            );
            return false;
        });
        return false;
    });

    if (flag){
        return(<Redirect to='/login' />);
    }
    else{
        return (
            <>
           
            <Table responsive striped style={{'color':'#676a6c'}} className="mt-2" >
                <thead className="">
                    <tr>
                        <th className="blueHeader"></th>
                        <th className="blueHeader align-middle text-center">
                            Total Minutes to Complete per item
                        </th>
                        <th className="blueHeader align-middle text-center">
                            % Time to complete for outside producers
                        </th>
                        <th className="blueHeader align-middle text-center">
                            % Time to complete for Inside Sales Team
                        </th>
                        <th className="blueHeader align-middle text-center">
                            % Time to complete for Account manager
                        </th>
                        <th className="blueHeader align-middle text-center">
                            % Time to complete for CSR/Processor/VA
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr></tr>
                    <tr></tr>
                    {tableBody}
                </tbody>
                <tbody>
                    {tableBodyKPI}               
                </tbody>
            </Table>
            {isloading && <LoadingModal modal={isloading} />}
            </>
        )
    }
}

export default TimeNeeded;

import "@babel/polyfill";
import React from 'react';
import {render} from 'react-dom';
import {AppRoute} from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './static/css/styles.css';
import './static/css/index.css';
import 'react-toastify/dist/ReactToastify.css';


let token = "asd";

render (
    <React.StrictMode>
      <AppRoute token={token} />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

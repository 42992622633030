import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ToastContainer, toast} from 'react-toastify';
import {Route, BrowserRouter, Switch, Redirect} from 'react-router-dom';
import OrganicGrowthCalculator from "./components/ogc/OGCContainer"
import Login from "./components/ogc/Login" 

class App extends Component {

    render() {
        let a = 1;
        // if(a){
        //     return (<Redirect to="/login" />);
        // }
        // else{
        console.log(this.props)
        return (
            <>
                {this.props.children}
                <ToastContainer width={400} 
                    position={toast.POSITION.TOP_CENTER} 
                    autoClose={2000}
                />
            </>
        );
        // }
    }
}

App.propTypes = {
    children: PropTypes.array.isRequired
};

export default App;

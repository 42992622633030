export default class FormValidator {
    constructor(validations) {
        this.validations = validations;
    }

    validateForm = (formData) => {
        // start out assuming valid
        const validation = {isValid: true, errors: {}};
        Object.entries(formData).map(
            ([key, value]) => {
                let validationStatus = this.validateInput(key, value);
                if (!validationStatus.isValid) {
                    validation.errors[key] = validationStatus.error;
                }
                return true;
            }
        );
        if (Object.keys(validation.errors).length > 0) {
            validation.isValid = false;
        }

        return validation;
    };

    validateInput = (field, value) => {
        // start out assuming valid
        let validation = {isValid: true, message: ''};
        let rules = this.validations;
        let fieldRules = rules.filter((rule) => (rule.field === field));
        fieldRules.some((rule) => {
            let status = false;
            if (rule.param) {
                status = rule.method(value, rule.param);
            } else {
                status = rule.method(value);
            }

            if (status) {
                validation.isValid = false;
                validation.error = rule.message;
                return true;
            }

            return false;
        });
        return validation;
    };
}

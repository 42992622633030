import React, {useState, useEffect,useRef} from "react";
import {toast} from "react-toastify";
import {Button} from 'reactstrap';
import moment from 'moment';
import AddLob from "./AddLob";
import SideNav from "./SideBar";


import {USER_PROFILE} from '../../actions/ActionTypes';
import ogcAction from "../../actions/OGCAction";
import GeneralInfo from "./GeneralInfo";
import KPI from "./KPIs";   
import LOBData from "./LOBData";
import TimeNeeded from "./TimeNeeded";
import Report from "./Report";
import LobReport from "./LobReport";
import HabitsReport from "./HabitsReport";
import OrganicDashboard from "./OrganicDashboard";
import TotalAgencyMonthly from "./TotalAgencyMonthly";
import ConfirmModal from "./ConfirmModal";
import TopMenu from "./TopMenu"
import { Redirect } from "react-router";
import { act } from "react-dom/test-utils";

const OrganicGrowthCalculator = () => {

   
    window.token = {
        "access_token":localStorage.getItem('access_token'),
        "refresh_token":localStorage.getItem('refresh_token'),
        "userId":localStorage.getItem('userId'),
        "agencyId":localStorage.getItem('agencyId'),
        ".issued":localStorage.getItem('.issued'),
        ".expires":localStorage.getItem('.expires'),
        'userName' : localStorage.getItem('userName'),
        'agencyName':localStorage.getItem('agencyName'),
        'userDisplayName':localStorage.getItem('userDisplayName'),
        'contactId':localStorage.getItem('contactId')
    }

    // window.token = {
    //     "access_token": "GrbrkqoCPcueBySHHG02NichvnYRlgq4aixETP4rC5L40THMDw_vRMxuyhhZvR98J4R49X9fahR0I6nHmP7wdncwu4T3pQWdCjjP7ql2-rBJ_nZ_sbUyiBwoFVIHCaVBJIHEwMLb_Yoc9G4YuBNkp4-1_isfsbYDu2IUKFOmLPgmJJ33XGwDi3Mb7Aq5F_jq1dzJVyO44HcPEETeVOFuN5gEN_471cGHXQrZcRKT-aI_trSxSZ94OEbgFhvhN3P3fh8ixBQDGMOqGYYxbyEf6qLEQHDPGZa8xiY91yomVa0dY_r8dLfAWn8Cyzgfc09inRodwlKMkxeNyjHI3VC--Ts8qy2ClYQYQSIf5FTVe0AVtFhiEodCysf7IlenHAJyje2npdoQvg3LWiNTnf1vbdDUgWWCaBX482hXkGdGfBBITZITslOPlSXVSsXfebImOW0znb_5qyWGgsTy5mFJ_swoKpx3Ose-AHi7jdb4HUNBq7R4jGUb8daodUO2nINRSExIzJmYdk4uauJBR_OTEOpOEbEbJyrDDaOg7bDzUg3VY8uhfjMsjO4P1TyGW27e24Y6QujETwtbekx4IiTN38xcg6bUo1YgOZpj8Sp7MUBj5ExcXZQM4zQTF-CZEhiT5ikO5Qx9wJmOBoMeidvuAlgqlowGJHWZSIcVUn7goanyziOKoEJP7nE6U89cStR8RboCacoibneXH9YxJ80YgvDxj7rFCGNJuNN99u2qXsNBO13ZzwLoMYS8kJ5FOdD5-Bme8z2bljs6SjdeiBtggWhkccCWTO1CHl2u_V389_cyvRqcaXeKiJnBHuM8SujkoPdndYIoRRqXDXfOAxFl4DusTa5BQfhw2egZ-LJnJms63uWyTPtUUXkmYPYAAY7208HYk_qzTxAUdY3INGtz4rzSVNOfwrwdoQyxyAckQxslSerndlp-yOZ_jl321BQrMV5aG14GBWEImxrvJrmeBmKhj9pAA_5RKSQnPTZZEBIk753wmZzUQymbBR7OYz8XdzdET6Wd-TmgxOzBikYTWE5Jcd9p9i-NJut-nyD50tc3wjFJJge9uQvQFQwiRK8Jf6NBHcURk6hfIJQaOpxsLJogEBgtJZmI0NWIDEPJ3KAGfK3g3VQCTdGviiuZx-fs7NF2zm58njPxBcXHSpe4E6R1Xj85nBhCIZmzRwN5eV-CbvPOhIx-kfO-ALFNC8vmJ_vN_xwtnDsj3Di9yJ3eXHTuD7eMhk7rCIYnVyf6B-KorRalWHn0TQ9yXhINRYdda1MQnlVQ_VS1Y4G9qHH9yB12a5LbIE-7FI3tJA51KIEABRw1DDhzqkq-Nit-5Uj3O2DY_z2ahT8g85lLX1gHmRsBnu0GwMzWqhwVKmJlL3M6Facg6__ea5yDjvAjnUbMGer8sfO2ZYwcA0mEzvEm4aJ3sK8i7FXxiNJcZbDwoeQ",
    // "token_type": "bearer",
    // "expires_in": 86399,
    // "refresh_token": "29032f53b1d04302865506bcecf1cb1b",
    // "as:client_id": "ngAuthApp",
    // "userName": "api@api.api",
    // "userId": "a17be6bc-115f-47cf-b6af-5269483ea665",
    // "userDisplayName": "API Integration Plovdiv",
    // "agencyId": "3e9e2d23-3111-43dd-9a82-75d00f0af521",
    // "agencyName": "Test Agency Plovdiv",
    // "contactId": "2b518a22-edb5-4336-a864-397c60be7ae2",
    // "userType": "Agent",
    // ".issued": "Fri, 10 Dec 2021 11:37:20 GMT",
    // ".expires": "Sat, 11 Dec 2021 11:37:20 GMT"
    // }

    const currentYear = moment().year();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [year, setYear] = useState(currentYear);
    const [isOpenReport, setIsOpenReport] = useState(false);
    const [shouldSave, setShouldSave] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState("personal");
    const [isOpenAddLob, setisOpenAddLob] = useState(false);
    const [isLobChanged, setIsLobChanged] = useState(false);
    const [add_other_dept, setAddOtherDept] = useState(false);
    const [activeForm, setActiveForm] = useState(0);
    const [headerLabel, setHeaderLabel] = useState('DashBoard: Total Agency');
    const [forms, setForms] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [model, setModel] = useState({});
    const [generalInfo, setGeneralInfo] = useState(null);
    const [areFieldValueChanged, setAreFieldValueChanged] = useState(false);
    const [lobInfo, setLobInfo] = useState(null);
    const [areLobFieldValueChanged, setAreLobFieldValueChanged] = useState(false);
    const [habitInfo, setHabitInfo] = useState(null);
    const [areHabitFieldValueChanged, setAreHabitFieldValueChanged] = useState(false);


    useEffect(() => {
    // for devlopment purposes only. Now cert would provide this data. 
    localStorage.setItem("tyjan","22000");
    localStorage.setItem("tyfeb","24000");
    localStorage.setItem("tymarch","30000");
    localStorage.setItem("tyapril","21000");
    localStorage.setItem("tymay","31000");
    localStorage.setItem("tyjune","50000");
    localStorage.setItem("tyjuly","40000");
    localStorage.setItem("tyaug","20000");
    localStorage.setItem("tysept","30000");
    localStorage.setItem("tyoct","39000");
    localStorage.setItem("tynov","38000");
    localStorage.setItem("tydec","41000");


    localStorage.setItem("lyjan","12000");
    localStorage.setItem("lyfeb","33000");
    localStorage.setItem("lymarch","15000");
    localStorage.setItem("lyapril","18000");
    localStorage.setItem("lymay","33000");
    localStorage.setItem("lyjune","60000");
    localStorage.setItem("lyjuly","100000");
    localStorage.setItem("lyaug","21000");
    localStorage.setItem("lysept","20000");
    localStorage.setItem("lyoct","36000");
    localStorage.setItem("lynov","37000");
    localStorage.setItem("lydec","39000");
    // localStorage.setItem('token', window.token);
    // setToken(JSON.stringify(localStorage.getItem('token')), () => {
    // window.token = token['access_token']
  

    getUserProfile();
   
       
    },[]);

    const setGeneralInfoAfterCancel = (generalInfo) =>{
        setGeneralInfo(generalInfo);
    }

    const handleAddDeptRadio = (value) => {
        console.log(value);
        setAddOtherDept(value);
    };

    const setDashboardForms = (selectedYear) => {
        let formsHome = [];
        
        formsHome.push(
            <OrganicDashboard
            year={selectedYear}
            isAuthenticated={true}
        />
        );
        formsHome.push(
            <LobReport 
                year = {selectedYear}
                selectedDepartment ="personal"
                isAuthenticated={true}
            />

        );
        formsHome.push(
            <LobReport 
            year = {selectedYear}
            selectedDepartment ="commercial"
            isAuthenticated={true}
            />
        );

        formsHome.push(
            <HabitsReport
            year = {selectedYear}
            selectedDepartment ="personal"
            isAuthenticated={true}
            />
        );
        formsHome.push(
            <HabitsReport
            year = {selectedYear}
            selectedDepartment ="commercial"
            isAuthenticated={true}
            />
        );

        setForms(formsHome);


    }; 

    const getUserProfile = async () => {
        console.log(window.token)
        let formsHome = []
        try {
            const result = await ogcAction({
                type: USER_PROFILE, token: window.token
            });
            if(result) {
                setIsAuthenticated(true);
                setDashboardForms(year);
               
            }
        } catch(error) {
            toast.error(error);
        }
        return;
    }

    const setLobChangedTrue = () => {
        setIsLobChanged(true);
    }

    const toggleModal = () =>{
        setisOpenAddLob(!isOpenAddLob);
        setIsLobChanged(false);
    }

    const setYearState = (year) => {
        console.log(year);
        setYear(year);
        setDashboardForms(year);

    }
    const hideConfirmModal = (event) => {
        const name = event.target.name;
        if (name === 'discard-model-btn') {
            setConfirmModal(false);
            setModel({});
        }
    };
    
    const showConfirmModal = (modelObject) =>{
        console.log("show confrim model");
        const newModel = {};
        newModel.msgTitle = modelObject.hasOwnProperty('msgTitle') && modelObject.msgTitle.length > 0 ? modelObject.msgTitle : '';
        newModel.msgBody = modelObject.hasOwnProperty('msgBody') && modelObject.msgBody.length > 0 ? modelObject.msgBody : '';
        newModel.buttons = modelObject.hasOwnProperty('buttons') && modelObject.buttons.length > 0 ? modelObject.buttons : [];
        setModel(newModel);
        setConfirmModal(true);
        
      };
  

    const getNextPTOForm = () => {
        console.log(selectedDepartment);
        let activeForm_state = activeForm;
        
        activeForm_state += 1;
        if (activeForm_state ===  2 ){
            setHeaderLabel('DashBoard: Commercial Lines LOB');
        }
        else if(activeForm_state ===  1) {
            setHeaderLabel('DashBoard: Personal Lines LOB');
            
        }
        else if(activeForm_state ===  3){
            setHeaderLabel('DashBoard: Personal Lines Habits');
        }
        else if(activeForm_state ===  4){
            setHeaderLabel('DashBoard: Commercial Lines Habits');
        }
        else{
            setHeaderLabel('DashBoard: Total Agency');
        }
        if (activeForm_state >= forms.length) {
            activeForm_state = 0;
        }
        setActiveForm(activeForm_state);
        console.log(activeForm);
    }

    
    const getPrevPTOForm = () => {
        var activeForm_state = activeForm;
        activeForm_state -= 1;
        
        if (activeForm_state < 0) {
            activeForm_state = forms.length - 1;
        }

        if (activeForm_state ===  2 ){
            setHeaderLabel('DashBoard: Commercial Lines LOB')
        }
        else if (activeForm_state ===  1 ){
            setHeaderLabel('DashBoard: Personal Lines LOB')
            
        }
        else if(activeForm_state ===  3){
            setHeaderLabel('DashBoard: Personal Lines Habits');
        }
        else if(activeForm_state ===  4){
            setHeaderLabel('DashBoard: Commercial Lines Habits');
        }
        
        else{
            setHeaderLabel('DashBoard: Total Agency');
        }
        setActiveForm(activeForm_state);
        console.log(activeForm)
    }


    const saveHabitInfoPersonalandCommercial = (ogcKPI,year) => {
        console.log(ogcKPI);
        console.log(year);
    }


    const renderYear = () => {
        let yearOptions = [];
        for(let y=2020; y <= currentYear + 2; y++) {
            yearOptions.push(<option value={y} key={y}>{y}</option>);
        }

        return yearOptions;
    }

    let hashUrl = window.location.hash.length > 1 ? window.location.hash.substring(1) : '10';
    let hashUrlArr = hashUrl.split('_');
    let activeTab = parseInt(hashUrlArr[0]);


    const setHeaderOnLinkClick = (headerName) => {
        if(headerName !== "DashBoard: Total Agency"){
            setHeaderLabel(headerName);
        }
        
        if(headerName === "DashBoard: Total Agency"){
            var activeForm_state = activeForm;
        
            if (activeForm_state < 0) {
                activeForm_state = forms.length - 1;
            }

            if (activeForm_state ===  2 ){
                setHeaderLabel('DashBoard: Commercial Lines LOB')
            }
            else if (activeForm_state ===  1 ){
                setHeaderLabel('DashBoard: Personal Lines LOB')
                
            }
            else if(activeForm_state ===  3){
                setHeaderLabel('DashBoard: Personal Lines Habits');
            }
            else if(activeForm_state ===  4){
                setHeaderLabel('DashBoard: Commercial Lines Habits');
            }
            
            else{
                setHeaderLabel('DashBoard: Total Agency');
            }
                
        }
        
    }
        
    if (window.token.access_token === null){
        return(<Redirect to='/login' />);
    }
    else{
    
    return (
        <section style={{'backgroundColor':'#f1f1f2'}}>
            <TopMenu
            
              userDisplayName = {window.token ? window.token["userDisplayName"] : null}
            
            >

            </TopMenu> 
            <SideNav 
                year={year}
                setYearState = {setYearState}
                renderYear = {renderYear}
                activeTab = {activeTab}
                setHeaderOnLinkClick = {setHeaderOnLinkClick}
             >

            </SideNav>

            <section style={{marginLeft:"200px", marginTop:"70px"}}>
            
                <div style={{color:'grey' , backgroundColor:"white"}} className="mb-0 ml-3 mr-3  p-2" >
                    <label style={{fontWeight:'bold'}} className="ml-1">
                        {headerLabel} 
                    </label>
                    {(activeTab === 2 || activeTab === 0 || activeTab === 4 || activeTab === 1 || activeTab === 3 || activeTab === 5 || activeTab === 6) &&
                        <Button  id = "showReport" className="float-right btn-sm"
                            onClick={() => setShouldSave(true)}
                        >
                            Save Changes 
                        </Button>
                        }

                        {(activeTab === 2 || activeTab === 4) &&
                            <div className="float-right ">
                            <Button id="nowCertButton" className="btn-sm"   
                                onClick={() => setisOpenAddLob(true)}
                            > 
                                Add / Remove Lob
                            </Button>
                            </div>
                        }
            
                </div>
             

           
          
            
            <div  style={{'backgroundColor':'#f1f1f2'}} className="card card-section border-0">
                
                
                <div className="card-body pt-0 mt-0 ml-0">
                    {activeTab === 0 ?
                        <GeneralInfo 
                            isAuthenticated={isAuthenticated} 
                            year={year}
                            selectedDepartment = {selectedDepartment}
                            shouldSave={shouldSave}
                            showConfirmModal={showConfirmModal}
                            generalInfo = {generalInfo}
                            areFieldValueChanged = {areFieldValueChanged}
                            setAreFieldValueChanged = {setAreFieldValueChanged}
                            setGeneralInfoAfterCancel = {setGeneralInfoAfterCancel}
                            setShouldSave={(value) => setShouldSave(value)}
                        />
                        :(activeTab === 10) ?
                                    <div className="card-body main-dashboard-col position-relative">
                                        <span className="fa fa-angle-left arrow-left"
                                             onClick={getPrevPTOForm}
                                            >
                                        </span>
                                         {
                                            forms[activeForm]
                                         }
                                    <span className="fa fa-angle-right arrow-right"
                                        onClick={getNextPTOForm}
                                    ></span>
                                    </div>
                               
                    : activeTab === 1 ?
                        <KPI 
                            isAuthenticated={isAuthenticated} 
                            year={year}
                            selectedDepartment = {selectedDepartment}
                            shouldSave={shouldSave}
                            setShouldSave={(value) => setShouldSave(value)}
                            habitInfo = {habitInfo}
                            setHabitInfo = {setHabitInfo}
                            areHabitFieldValueChanged = {areHabitFieldValueChanged}
                            setAreHabitFieldValueChanged = {setAreHabitFieldValueChanged}
                            showConfirmModal={showConfirmModal}
                            saveHabitInfoPersonalandCommercial = {saveHabitInfoPersonalandCommercial}
                        />
                    : (activeTab === 2) ?
                        <LOBData 
                            isAuthenticated={isAuthenticated} 
                            year={year}
                            shouldSave={shouldSave}
                            selectedDepartment='personal'
                            setShouldSave={(value) => setShouldSave(value)}
                            isLobChanged={isLobChanged}
                            add_other_dept = {add_other_dept}
                            handleAddDeptRadio = {handleAddDeptRadio}
                            lobInfo = {lobInfo}
                            setLobInfo = {setLobInfo}
                            areLobFieldValueChanged = {areLobFieldValueChanged}
                            setAreLobFieldValueChanged = {setAreLobFieldValueChanged}
                            showConfirmModal={showConfirmModal}
                        />
                    :  (activeTab === 3) ?
                        <TimeNeeded 
                            isAuthenticated={isAuthenticated} 
                            year={year}
                            selectedDepartment = 'personal'
                            shouldSave={shouldSave}
                            setShouldSave={(value) => setShouldSave(value)}
                        /> 
                    : (activeTab === 4) ?
                        <LOBData 
                            isAuthenticated={isAuthenticated} 
                            year={year}
                            shouldSave={shouldSave}
                            selectedDepartment='commercial'
                            setShouldSave={(value) => setShouldSave(value)}
                            isLobChanged={isLobChanged}
                            add_other_dept = {add_other_dept}
                            handleAddDeptRadio = {handleAddDeptRadio}
                            lobInfo = {lobInfo}
                            setLobInfo = {setLobInfo}
                            areLobFieldValueChanged = {areLobFieldValueChanged}
                            setAreLobFieldValueChanged = {setAreLobFieldValueChanged}
                            showConfirmModal={showConfirmModal}
                        
                            
                        />
                    :   (activeTab === 5) ?
                        <TimeNeeded 
                            isAuthenticated={isAuthenticated} 
                            year={year}
                            selectedDepartment = 'commercial'
                            shouldSave={shouldSave}
                            setShouldSave={(value) => setShouldSave(value)}
                        />
                    : (activeTab === 6) &&
                    <TotalAgencyMonthly 
                        isAuthenticated={isAuthenticated} 
                        year={year}
                        shouldSave={shouldSave}
                        setShouldSave={(value) => setShouldSave(value)}
                    />
                 

                    }
                </div>
                {isOpenReport && 
                    <Report openModal={isOpenReport} 
                        togglebModal={() => setIsOpenReport(false)} 
                    />
                }
             
            </div>
            {isOpenAddLob &&
                  <AddLob openModal={isOpenAddLob} 
                  togglebModal={toggleModal}
                  year = {year}
                  selectedDepartment = {(activeTab === 2)?"personal":"commercial"}
                  isAuthenticated={isAuthenticated}
                  setLobChangedTrue = {setLobChangedTrue}
              />
                }
            
         {confirmModal && 
            <ConfirmModal {...model} showConfirmModal={confirmModal} 
              hide={hideConfirmModal}/>
          }
        </section>
        </section>

       
     
    )
        }
}

export default OrganicGrowthCalculator;

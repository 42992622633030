import React from "react";
import {toast} from "react-toastify";
import {Table} from 'reactstrap';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { Redirect } from "react-router";

import ogcAction from "../../actions/OGCAction";
import {GET_GENERAL_INFO, UPDATE_GENERAL_INFO} from '../../actions/ActionTypes';
import {GENERAL_INFO, DEPARTMENTS} from "./Constants";
import LoadingModal from "./LoadingModal";



export default class GeneralInfo extends React.Component {
    state = {
        generalInfo: this.props.generalInfo === null ? {
            personal: {},
            commercial:{},
        }:this.props.generalInfo,

        areFieldValueChanged: this.props.areFieldValueChanged,
        isloading :false,
        flag:false
       
    }

    componentDidMount = async () => {
       // console.log(this.props.generalInfo_details);
        const currentYear = this.props.year;
        const prevYear = currentYear - 1;
        if (this.props.isAuthenticated && this.props.generalInfo===null) {
            // without await, we are facing problem 
            // because both function update same state
            
            // call for commercial. department id = 2
            await this.getGeneralInfo(currentYear,'personal'); 
           
           // call for personal. department id = 1
            await this.getGeneralInfo(currentYear,'commercial');
        }
        return false;
    }
    
    // componentWillUnmount = () => { 

    //     if (this.props.areFieldValueChanged && this.props.setShouldSave){
    //         this.props.showConfirmModal({
    //             msgTitle: 'Save / Discard Changes !',
    //             msgBody: [
    //                 <p>Do you want to save / discard the changes ?</p>
    //             ],
    //             buttons: [
    //                 {
    //                     label : 'Save',
    //                     onClick : async() => {
    //                         await this.saveInfo(this.props.year);
    //                         //this.setAreFieldValueChanged(false);
    //                         this.props.setGeneralInfoAfterCancel(null);
    //                     },
    //                     className : 'btn-success'
    //                 },
    //                 {
    //                     label : 'Discard',
    //                     onClick : () => {
    //                         this.props.setGeneralInfoAfterCancel(null);
    //                         this.props.setAreFieldValueChanged(false);
    //                     },
    //                     className : 'btn-danger'
    //                 },
    //                 {
    //                     label : 'Cancel',
    //                     onClick : () => {
    //                         let generalInfo_details = {...this.state.generalInfo};
    //                         window.location.hash = '#0';
    //                         this.props.setGeneralInfoAfterCancel(generalInfo_details);
    //                         this.props.setAreFieldValueChanged(true);
    //                     },
    //                     className : 'btn-primary'
    //                 }
    //             ]
    //         });

    //     }
        
    // }

    componentDidUpdate = async (prevProps) => {

        //console.log("update");
        const {isAuthenticated, year, shouldSave, generalInfo} = this.props;
        const currentYear = year;
        //console.log(DEPARTMENTS[this.props.selectedDepartment])
        if ((prevProps.isAuthenticated !== isAuthenticated || prevProps.year !== year)) {
            await this.getGeneralInfo(currentYear,'personal');
            await this.getGeneralInfo(currentYear,'commercial');
        }



        if (prevProps.shouldSave !== shouldSave) {
            if (shouldSave) {
                this.saveInfo(currentYear);
                this.props.setShouldSave(false);
            }
        }
        return false;
    };


    getGeneralInfo = async (year,department) => {

        const dept = DEPARTMENTS[department];
        const {generalInfo} = this.state;
        this.setState({isloading:true});
        try {
            const result = await ogcAction({
                type: GET_GENERAL_INFO, year: year, department:dept
            });
            console.log('resa', result)
            if(result) {
                
                let agencyInfo = {...generalInfo};
                agencyInfo[department] = {...result};
                this.setState({generalInfo: agencyInfo,isloading:false});
               
            }
            else{
                this.setState({isloading:false, flag:true});
            }
        } catch(error) {
            toast.error(error);
            this.setState({isloading:false});
        }
        return;
    }

    saveInfo = async (year) => {
        //console.log(year);
        const {generalInfo} = this.state;
        try {
            const resultPersonal = await ogcAction({
                type: UPDATE_GENERAL_INFO, year: year, 
                department:DEPARTMENTS['personal'],
                data: generalInfo['personal']

            });
            const resultCommercial = await ogcAction({
                type: UPDATE_GENERAL_INFO, year: year, 
                department:DEPARTMENTS['commercial'],
                data: generalInfo['commercial']
            });
            if((resultPersonal && resultCommercial) && year === this.props.year) {
                toast.success("Saved Successfully.")
            }

          
        } catch(error) {
            //console.log("inside cathc general infor");
           // let err = error.data.errors[0];
            //let key  = Object.keys(err)[0]; 
            //console.log(error.data.errors[0][key][0]);
            toast.error("Bad request");
        }
        return;
    }

    handleInputChange = (field, value, dept) => {
        const {generalInfo} = this.state;
        let agencyInfo =  {...generalInfo};
        //console.log(agencyInfo[dept][field]);
        agencyInfo[dept][field] = value;
        this.setState({generalInfo: agencyInfo});
    }

    handleInputBlur = (field, dept) => {
        this.props.setAreFieldValueChanged(true);
        const currentYear = this.props.year;
        const {generalInfo} = this.state;
        let agencyInfo =  {...generalInfo};
        
        let value = agencyInfo[dept][field];
        if (isNaN(value)) {
            agencyInfo[dept][field] = 0;
            this.setState({generalInfo: agencyInfo});
        } else if (GENERAL_INFO[field]['type'] === 'percent' && value > 100) {
            agencyInfo[dept][field] = 100;
            this.setState({generalInfo: agencyInfo});
        }
    }

    getCalculatedValue = (field, dept) => {
        const {generalInfo} = this.state;
        
        let value = 0;
        
        if (generalInfo[dept]) {
            if (field === 'fhe') {
                value = Number(
                    generalInfo[dept]['outside_producer_fhe'] + 
                    generalInfo[dept]['inside_sales_fhe'] + 
                    generalInfo[dept]['account_manager_fhe'] + 
                    generalInfo[dept]['supports_fhe']
                )
            } else if (field === 'admin_fte') {
                value = Number(
                    generalInfo[dept]['owner_fte'] + 
                    generalInfo[dept]['office_manager_fte'] + 
                    generalInfo[dept]['recep_fte'] + 
                    generalInfo[dept]['other_fte']
                )
            } else if (field === 'per_new_business_from_prospect') {
                value = 100 - Number(
                    generalInfo[dept]['per_new_business_from_customer']
                )
            } 
            // else if (field === 'avg_commission_rate') {
            //     value = Number(generalInfo[dept]['revenue']) /
            //         Number(generalInfo[dept]['total_premium']
              
            //         ) * 100 
            // } 
            else if (field === 'total_customers_this_period'){
               const new_customers = this.calculateNewCustomers(dept);
               const total_customers_ty =  this.calculateTotalCustomerTY(dept);
               value = new_customers + total_customers_ty;

            }
            
            else {
                value = generalInfo[dept][field] 
            }
        } 

        return value;
    };

    calculateNewCustomers = (dept) =>{
        const department_info = this.state.generalInfo[dept];
        console.log(department_info);
        let new_customers = 0;
        const planned_revenue = (1+department_info.revenue_increase/100.0)*department_info.revenue
        const renewal_business_revenue = department_info.revenue * department_info.retention / 100.0
        const new_business_revenue = planned_revenue - renewal_business_revenue  
        try{
            new_customers = Math.round(
                (((new_business_revenue * (
                        100 - department_info.per_new_business_from_customer
                ) / 100.0) * 100 / department_info.avg_commission_rate))
                / department_info.avg_premium_for_new_customers
            )
       }
        catch(except){
        }
        console.log(new_customers);
        return new_customers
    }

    calculateTotalCustomerTY = (dept) => {
        const department_info = this.state.generalInfo[dept];
        const total_customers_ty =   Math.round(
            department_info.total_customers
            * department_info.retention / 100.0
        );
        return total_customers_ty;

    }

    getExplanationText = (field, lastYear, currentYear) => {

        console.log(lastYear);
        console.log(currentYear);
        if (GENERAL_INFO[field]['text'] === 'Revenue'){
            const explanation = `Total net revenue for ${lastYear} (after chargebacks and cancellations) for the department, not including profit sharing or contingency income.`;
            return explanation;
        }
        else if (GENERAL_INFO[field]['text'] === 'Total Premium'){
            const explanation = `Total written premium for ${lastYear} not including rewrites but including renewals and new premium.`;
            return explanation;
        }
        else if (GENERAL_INFO[field]['text'] === 'Retention'){
            const explanation = `Planned or actual retention for ${lastYear} based on number of existing clients renewing`;
            return explanation;
        }
        else if (GENERAL_INFO[field]['text'] === 'Average commission rate'){
            const explanation = `Average commission rate based on total revenue and total premium at the end of ${lastYear}`;
            return explanation;
        }
        else if (GENERAL_INFO[field]['text'] === 'Total customers'){
            const explanation = `Total number of customers for the department at the end of ${lastYear}`;
            return explanation;
        }
        else if (GENERAL_INFO[field]['text'] === 'Planned total customers/accounts'){
            const explanation = `Total number of customers for the department at the end of ${currentYear}`;
            return explanation;
        }
        else if (GENERAL_INFO[field]['text'] === 'Percentage of customers communicated with'){
            const explanation = `Percentage of customers whom team members will have the opportunity for a conversation with during ${currentYear}.  Conversation is a two-way conversation that can be either in person, by phone, text or email.`;
            return explanation;
        }

        return GENERAL_INFO[field]['explanation'];

    }
    


    renderTableBody = () => {
        const currentYear = this.props.year;
        const prevYear = currentYear - 1;
        const deptPersonal = 'personal';
        const deptCommercial = 'commercial';
        let tableBody = Object.keys(GENERAL_INFO).map((field, index) => (
            <tr style= {{"border": "0"}} key={index}>
                <td style={{'border':'0px', 'cursor': 'alias'}} data-tip data-for={`info_${index}`} className={`align-middle ${
                        GENERAL_INFO[field]['isCategory'] ? 'font-weight-bold bg-d9' : 'pl-3'
                    }`}
                >
                    {GENERAL_INFO[field]['text']==='Revenue' ||GENERAL_INFO[field]['text']==='Total Premium' ? prevYear + ' ' + GENERAL_INFO[field]['text'] 
                    :GENERAL_INFO[field]['text']
                    }
                    {GENERAL_INFO[field]['explanation'] && 
                        <i 
                            data-tip data-for={`info_${index}`}
                        >
                            <ReactTooltip id={`info_${index}`} 
                                className="react-tooltip-class"
                                delayShow={200}
                            >
                                {this.getExplanationText(field, prevYear, currentYear)}
                            </ReactTooltip>
                        </i>
                    }
                </td>
                <td style={{'border':'0px'}} className={`align-middle py-0 text-center ${
                        GENERAL_INFO[field]['isCategory'] ? 'font-weight-bold bg-d9' : ''
                    }`}
                >
                    <NumberFormat 
                        className={`text-center ${GENERAL_INFO[field]['isCategory'] ? 
                            '' : 'form-control form-control-sm'
                        }`}
                        thousandSeparator={true} allowNegative={false}
                        decimalScale={GENERAL_INFO[field]['decimalScale']} 
                        prefix={GENERAL_INFO[field]['type'] === 'amount' ? '$ ' : ''}
                        suffix={GENERAL_INFO[field]['type'] === 'percent' ? ' %' : ''}
                        onValueChange={values => this.handleInputChange(
                            field , values.floatValue, deptPersonal
                        )}
                        onBlur={(e) => this.handleInputBlur(field, deptPersonal)}
                        value={this.getCalculatedValue(field, deptPersonal)}
                        displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                        disabled={!GENERAL_INFO[field]['editable']}
                        style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                    /> 
                </td>

                  <td style={{'border':'0px'}} className={`align-middle py-0 text-center ${
                        GENERAL_INFO[field]['isCategory'] ? 'font-weight-bold bg-d9' : ''
                    }`}
                >
                    <NumberFormat 
                        className={`text-center ${GENERAL_INFO[field]['isCategory'] ? 
                            '' : 'form-control form-control-sm'
                        }`}
                        thousandSeparator={true} allowNegative={false}
                        decimalScale={GENERAL_INFO[field]['decimalScale']} 
                        prefix={GENERAL_INFO[field]['type'] === 'amount' ? '$ ' : ''}
                        suffix={GENERAL_INFO[field]['type'] === 'percent' ? ' %' : ''}
                        onValueChange={values => this.handleInputChange(
                            field , values.floatValue, deptCommercial
                        )}
                        onBlur={(e) => this.handleInputBlur(field, deptCommercial)}
                        value={this.getCalculatedValue(field, deptCommercial)}
                        displayType={GENERAL_INFO[field]['isCategory'] ? "text" : "input"}
                        disabled={!GENERAL_INFO[field]['editable']}
                        style = {{'border': '0px solid #b3c0c7',backgroundColor:"inherit"}}
                    /> 
                </td>      
                      
            </tr>
            
        ));
        return tableBody;
    }

    render = () => {
        const currentYear = this.props.year;
       
        return (
            this.state.flag ? 
            <Redirect to='/' /> :
            <>
                 
                <Table responsive striped style={{'color':'#676a6c'}}  className="mt-2" >

                    <thead style = {{'height':'40px', 'background-color': '#ececec'}}>
                       
                        <tr style={{"font":'13px', 
                            'height':'70px',
                                'padding': '5px 8px',
                                'border-bottom': '1px solid #b3c0c7',
                                'background-color': '#ececec'}}>
                            <th className="blueHeader">
                                General Information
                            </th>
                            <th className="blueHeader pb-1 align-middle text-center">
                                Personal
                            </th>
                            <th className="blueHeader pb-1 align-middle text-center">
                                Commercial
                            </th>

                        </tr>
                    </thead>
                    <tbody style= {{"border": "0"}}>
                        <tr></tr>
                        <tr></tr>
                        {this.renderTableBody()}
                    </tbody>
                </Table>
                {this.state.isloading && <LoadingModal modal={this.state.isloading} />}


            </>
        
        
        )
    }

    
}
export const GENERAL_INFO = {
 //last year revenue
    'revenue': {
        text: 'Revenue', 
        editable: false, 
        type: 'amount',
        decimalScale: 0,
        explanation: 'Total net revenue for last year (after chargebacks and cancellations) for the department, not including profit sharing or contingency income.',
    },
    // last year  premium
    'total_premium': {
        text: 'Total Premium', 
        editable: false, 
        type: 'amount',
        decimalScale: 0,
        lyField: 'ly_premium',
        lyEditable: true,
        isCategory: false,
        explanation:'Total written premium for last year not including rewrites but including renewals and new premium.'
    },
    'retention': {
        text: 'Retention',
        editable: true,
        type: 'percent',
        decimalScale: 2,
        lyField: 'ly_retention',
        lyEditable: true,
        isCategory: false,
        explanation: 'Planned or actual retention for last year based on number of existing clients renewing'
    },
    'avg_commission_rate': {
        text: 'Average commission rate',
        editable: true,
        type: 'percent',
        decimalScale: 2,
        lyField: 'ly_avg_commission_rate',
        isCategory: false,
        explanation: true,
    },

    'total_customers': {
        text: 'Total customers',
        editable: false,
        type: 'number',
        decimalScale: 0,
        lyField: 'total_ly_customers',
        lyEditable: true,
        isCategory: false,
        explanation:true,
    },
    
    revenue_increase: {
        text: '% Planned increase',
        editable: true,
        type: 'percent',
        decimalScale: 2
    },
    
    'total_customers_this_period':{
        text: 'Planned total customers/accounts',
        editable: false,
        type:'number',
        explanation: true,
    },
    'avg_premium_for_new_customers': {
        text: 'Average premium for new households/accounts',
        editable: true,
        type: 'amount',
        decimalScale: 0,
        lyField: 'ly_avg_premium_for_new_customers',
        lyEditable: true,
        isCategory: false,
        explanation: 'Average amount of premium written for a new customer or account'
    },
    'avg_upsell_premium': {
        text: 'Average upsell premium for cross selling customers',
        editable: true,
        type: 'amount',
        decimalScale: 0,
        lyField: 'ly_avg_upsell_premium',
        lyEditable: true,
        isCategory: false,
        explanation: 'Average amount of premium for upselling or rounding an existing customer or account.'
    },
    'per_new_business_from_customer': {
        text: 'Percentage of new commission revenue from customers',
        editable: true,
        type: 'percent',
        decimalScale: 0,
        lyField: 'ly_per_new_business_from_customer',
        lyEditable: true,
        isCategory: false,
        explanation: 'Percentage of total new commission revenue received from upsell and cross-sell.'
    },
    'per_new_business_from_prospect': {
        text: 'Percentage of new commission revenue from prospects',
        editable: false,
        type: 'percent',
        decimalScale: 0,
        lyField: 'ly_per_new_business_from_prospect',
        lyEditable: false,
        isCategory: false,
        explanation: 'Percentage of total new commission revenue received from new customers'
    },
    
   
    'avg_close_rate': {
        text: 'Average close rate',
        editable: true,
        type: 'percent',
        decimalScale: 2,
        lyField: 'ly_avg_close_rate',
        lyEditable: true,
        isCategory: false,
        explanation: 'Average percentage of proposals reviewed with prospects or customers that leads to one or more new lines of business.',
    },

    'customer_talked':{
        text: 'Percentage of customers communicated with',
        editable: true,
        type: 'percent',
        decimalScale: 2,
        isCategory: false,
        explanation:true,
    },

    'full_time_emp_hours':{
        text:"Average number of hours for full-time team members",
        editable: true,
        type: 'number',
        decimalScale: 0,
        lyEditable: true,
        isCategory: false,
        explanation:'Average number of hours worked weekly for full-time staff.'
    },

    'pif': {
        text: 'Total policies in force (PIF)',
        editable: false,
        type: 'number',
        decimalScale: 0,
        lyEditable: true,
        isCategory: false,
        // explanation:'Average number of hours worked weekly for full-time staff.'
    },

    'admin_fte': {
        text: 'Total Admin FTE',
        editable: false,
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_fhe',
        lyEditable: false,
        explanation: 'FTE, or Full time equivalent, is the total number of hours in the department divided by the average number hours worked weekly by full time staff.',
        isCategory: true
    },
    'owner_fte': {
        text: 'Owner',
        editable: true,
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_outside_producer_fhe',
        lyEditable: true,
        isCategory: false,
        explanation: 'Team members who generate their own leads and are responsible for a dedicated sales goal, with no service responsibilities.',
    },
    'office_manager_fte': {
        text: 'Office Manager',
        editable: true,
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_inside_sales_fhe',
        lyEditable: true,
        isCategory: false,
        explanation: 'Team members who are responsible to write new business from inbound leads for prospects.',
    },
    'recep_fte': {
        text: 'Receptionist',
        editable: true,
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_account_manager_fhe',
        lyEditable: true,
        isCategory: false,
        explanation: 'Team members responsible to service customers as well as round accounts by upsell and cross-sell.',
    },
    'other_fte': {
        text: 'Other',
        editable: true,
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_service_fhe',
        lyEditable: true,
        explanation: 'Team members responsibly for service only including quoting and all other service needs.',
        isCategory: false
    },
    
    'fhe': {
        text: 'Total number of FTE',
        editable: false,
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_fhe',
        lyEditable: false,
        explanation: 'FTE, or Full time equivalent, is the total number of hours in the department divided by the average number hours worked weekly by full time staff.',
        isCategory: true
    },
    'outside_producer_fhe': {
        text: 'Dedicated outside Producer (Sales only)',
        editable: true,
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_outside_producer_fhe',
        lyEditable: true,
        isCategory: false,
        explanation: 'Team members who generate their own leads and are responsible for a dedicated sales goal, with no service responsibilities.',
    },
    'inside_sales_fhe': {
        text: 'Dedicated inside sales team (Sales only)',
        editable: true,
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_inside_sales_fhe',
        lyEditable: true,
        isCategory: false,
        explanation: 'Team members who are responsible to write new business from inbound leads for prospects.',
    },
    'account_manager_fhe': {
        text: 'Account manager (Sales and Support)',
        editable: true,
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_account_manager_fhe',
        lyEditable: true,
        isCategory: false,
        explanation: 'Team members responsible to service customers as well as round accounts by upsell and cross-sell.',
    },
    'supports_fhe': {
        text: 'CSR/Processor/VA (Support only)',
        editable: true,
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_service_fhe',
        lyEditable: true,
        explanation: 'Team members responsibly for service only including quoting and all other service needs.',
        isCategory: false
    },
    
};

export const HABITS_KPI = {
    // avg_close_rate_with_lob: {
    //     text: 'Average close rate with one or more new LOBs', 
    //     editable: true, 
    //     type: 'percent',
    //     decimalScale: 2,
    //     lyField: 'ly_avg_close_rate_with_lob',
    //     lyEditable: true,
    //     isCategory: false
    // },
    new_customer_referrals: {
        text: 'New customer referrals', 
        editable: false, 
        type: 'amount',
        decimalScale: 0,
        lyField: '',
        lyEditable: false,
        isCategory: true
    },
    per_customer_asked_for_referral: {
        text: 'Percentage customers asked for a referral', 
        editable: true, 
        type: 'percent',
        decimalScale: 2,
        lyField: 'ly_per_customer_asked_for_referral',
        lyEditable: true,
        isCategory: false
    },
    per_customer_give_referral: {
        text: 'Percentage customers asked who give a referral', 
        editable: true, 
        type: 'percent',
        decimalScale: 2,
        lyField: 'ly_per_customer_give_referral',
        lyEditable: true,
        isCategory: false
    },
    avg_referral_per_customer: {
        text: 'Average number of referrals per customer', 
        editable: true, 
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_avg_referral_per_customer',
        lyEditable: true,
        isCategory: false
    },
    avg_close_rate_per_referral: {
        text: 'Average close rate for each referral', 
        editable: true, 
        type: 'percent',
        decimalScale: 2,
        lyField: 'ly_avg_close_rate_per_referral',
        lyEditable: true,
        isCategory: false
    },
    avg_new_business_premium_referrals: {
        text: 'Average new business premium', 
        editable: true, 
        type: 'amount',
        decimalScale: 0,
        lyField: 'ly_avg_new_business_premium_referrals',
        lyEditable: true,
        isCategory: false
    },
    new_leads_from_staffs: {
        text: 'New leads from Staff ', 
        editable: true, 
        type: 'amount',
        decimalScale: 0,
        lyField: '',
        lyEditable: true,
        isCategory: true
    },
    avg_new_leads_added_to_pipeline: {
        text: 'Average number of new leads added to pipeline by Staff', 
        editable: true, 
        type: 'number',
        decimalScale: 2,
        lyField: 'ly_avg_new_leads_added_to_pipeline',
        lyEditable: true,
        isCategory: false
    },
    avg_new_leads_recieve_quotes :{
        text: 'Average percentage of new leads who recieve a quote ', 
        editable: true, 
        type: 'percent',
        decimalScale: 2,
        lyEditable: true,
        isCategory: false
    },
    avg_close_rate_for_new_leads: {
        text: 'Average close rate for new leads', 
        editable: true, 
        type: 'percent',
        decimalScale: 2,
        lyField: 'ly_avg_close_rate_for_new_leads',
        lyEditable: true,
        isCategory: false
    },


    
    /*
    follow_up_on_lost_souls: {
        text: 'Follow-up on Lost Souls', 
        editable: false, 
        type: 'amount',
        decimalScale: 0,
        lyField: '',
        lyEditable: false,
        isCategory: true
    },
    per_contacted_if_proposal_not_reviewed: {
        text: 'Percentage contacted if proposal not reviewed', 
        editable: true, 
        type: 'percent',
        decimalScale: 2,
        lyField: 'ly_per_contacted_if_proposal_not_reviewed',
        lyEditable: true,
        isCategory: false
    },
    per_contacted_completing_quote: {
        text: 'Percentage contacted completing a quote', 
        editable: true, 
        type: 'percent',
        decimalScale: 2,
        lyField: 'ly_per_contacted_completing_quote',
        lyEditable: true,
        isCategory: false
    },
    avg_close_rate_adding_lob: {
        text: 'Average close rate adding lines of business', 
        editable: true, 
        type: 'percent',
        decimalScale: 2,
        lyField: 'ly_avg_close_rate_adding_lob',
        lyEditable: true,
        isCategory: false
    },
    avg_new_business_premium_follow_up: {
        text: 'Average new business premium', 
        editable: true, 
        type: 'amount',
        decimalScale: 0,
        lyField: 'ly_avg_new_business_premium_follow_up',
        lyEditable: true,
        isCategory: false
    },
    */
    account_review:{
        text: "Account Reviews",
        lyEditable: false,
        isCategory: true,
        
         
        
    },
    asked_for_review: {
        text: '% Asked to schedule Account Review with Accounts/Households',
        editable: true,
        type: 'percent',
        decimalScale: 2,
      
    },
    scheduled_review: {
        text: '% Schedule account review with Accounts/Households',
        editable: true,
        type: 'percent',
        decimalScale: 2,
      
    },
    completed_review: {
        text: '% Completed account review with Accounts/Households',
        editable: true,
        type: 'percent',
        decimalScale: 2,
      
    },
    completed_review_close_rate: {
        text: 'Average Close Rate for Completed Account Reviews',
        editable: true,
        type: 'percent',
        decimalScale: 2,
      
    },
};


export const DEPARTMENTS = {
    'personal': 1,
    'commercial': 2,
    'life-Health':3,
}

export const AGENCY_MONTHLY = {
    January: {
        text :'January',
        editable: true,
        type: 'amount',
    },
    February : {
        text :'February',
        editable: true,
        type: 'amount',
    },
    March : {
        text :'March',
        editable: true,
        type: 'amount',
    },
    April : {
        text :'April',
        editable: true,
        type: 'amount',
    },

    May : {
        text :'May',
        editable: true,
        type: 'amount',
    },
    June : {
        text :'June',
        editable: true,
        type: 'amount',
    },
    July : {
        text :'July',
        editable: true,
        type: 'amount',
    },
    August: {
        text :'August',
        editable: true,
        type: 'amount',
    },
    September: {
        text :'September',
        editable: true,
        type: 'amount',
    },
    October : {
        text :'October',
        editable: true,
        type: 'amount',
    },
    November : {
        text :'November',
        editable: true,
        type: 'amount',
    },
    December : {
        text :'December',
        editable: true,
        type: 'amount',
    }
};
import React, {Component} from 'react';
import {Modal, ModalBody} from 'reactstrap';

import loadingGIF from "../../static/img/loading.gif";


export default class LoadingModal extends Component {
    
    render() {
        return (
            
            <Modal size={'sm'} style={{width:'8%', }} isOpen={this.props.modal} className="loading-modal ">
                <ModalBody className="text-center pb-3">
                    <img src={loadingGIF} alt="loading..." />
                </ModalBody>
            </Modal>
            
        );
    }
}
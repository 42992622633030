import validator from 'validator';

export const required = (value) => (!value || !value.toString().trim().length);

export const minValue = (value) => (!parseInt(value) > 0);

export const maxLength = (value, length) => (value.length > length);

export const dropdownValidation = (value, length) => (!value || parseInt(value, 10) === 0);

export const isEmpty = (value) => (!value || value.toString().trim() === '<p></p>');

export const isEmail = (value) => (!(validator.isEmail(value)));

export const minLength8 = (value) => (!value || !(value.toString().trim().length >= 8));

export const dateValidation = (date) => {
    let dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    // Match the date format through regular expression
    if (date.match(dateformat)) {
        //Test which separator is used '/'
        let splitDate = date.split('/');
        let lengthOfDate = splitDate.length;
        // Extract the string into month, date and year
        if (lengthOfDate > 1) {
            let newDate = date.split('/');
            let dd = parseInt(newDate[0]);
            let mm = parseInt(newDate[1]);
            let yy = parseInt(newDate[2]);
            // Create list of days of a month [assume there is no leap year by default]
            let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if (mm == 1 || mm > 2) {
                if (dd > ListofDays[mm - 1]) {
                    return false;
                }
            }
            if (mm == 2) {
                let lyear = false;
                if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
                    lyear = true;
                }
                if ((lyear == false) && (dd >= 29)) {
                    return false;
                }
                if ((lyear == true) && (dd > 29)) {
                    return false;
                }
            }

        } else {
            return false;
        }
    }
    else {
        return false;
    }
};

export const phoneValidation = (value) => {
    if(!value || !value.toString().trim().length) {
        return false;
    }
    // const phoneNumberPattern = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;
    const phoneNumberPattern = /^\(?([0-9]{10})$/;
    return !(phoneNumberPattern.test(value));
};

export const regValidation =(value , reg) => {
    if(!value || !(value.toString().trim().length)){
        return false
    }

    return !(reg && reg.test(value));
}


import React, {useState, useEffect} from "react";
import {Doughnut, HorizontalBar, Line} from 'react-chartjs-2';
import {lineChartOptions} from "./AgencyLineGraph";
import {GET_AGENCY_ORGANIC_GROWTH} from '../../actions/ActionTypes';
import {toast} from "react-toastify";
import ogcAction from "../../actions/OGCAction";
import NumberFormat from 'react-number-format';
import { Chart } from 'react-chartjs-2';
import { Redirect } from "react-router";
import LoadingModal from "./LoadingModal";
import {GENERAL_INFO, DEPARTMENTS,AGENCY_MONTHLY} from "./Constants";

const OrganicDashboard = (props) => {
    const [agencyOrganicGrowth, setAgencyOrganicGrowth] = useState({

        last_year_actual_revenue:0,
        total_planned_revenue:0,
        total_organic_growth:0,
        total_planned_premium:0,
        last_year_actual_premium:0,
        total_revenue_increase_percentage:0,
        commercial_organic_growth:0,
        personal_organic_growth:0,
        personal_planned_revenue:0,
        commercial_planned_revenue:0,
        personal_total_new_business_revenue:0,
        commercial_total_new_business_revenue:0,
        commercial_total_dept_time:0,
        personal_total_dept_time:0,
        commercial_total_hour_fhe:0,
        personal_total_hour_fhe:0,
        outside_producer_total_hours:0,
        inside_sales_total_hours:0,
        account_manager_total_hours:0,
        support_total_hours:0,
        commercial_rev:{},
        personal_rev:{}

   });
    const [plDepartmentPiChartDataSet, setPlDepartmentPiChartDataSet] = useState({
        planned:100,
        created:0,
    });
    const [clDepartmentPiChartDataSet, setClDepartmentPiChartDataSet] = useState({
        planned:100,
        created:0,
    });
    const [flag,setflag] = useState(false);
    const [isloading,setLoad]  = useState(false);

    useEffect(() => {
        
        if (props.isAuthenticated) {
           
            getAgencyOrganicGrowth();

            // Writing the % number at the center of the doughnut. 
            Chart.pluginService.register({
                beforeDraw: function(chart) {
                if (chart.config.type==='doughnut'){
                    var width = chart.chart.width,
                    height = chart.chart.height,
                    ctx = chart.chart.ctx;
                    var centerText = chart.config.options.elements.text;
                    ctx.restore();
                    var fontSize = ((height / 114)+2).toFixed(2);
                    ctx.font = fontSize + "em sans-serif";
                    ctx.fillStyle = '#1ab394';
                    ctx.textBaseline = "middle";
                    var text = centerText + '%',
                        textX = Math.round((width - ctx.measureText(text).width) / 2),
                        textY = height / 1.5;
                    ctx.fillText(text, textX, textY);
                    ctx.save();
                    }
                }
            });
        }
        else{
          
        }
    },[props.isAuthenticated, props.year]);

    const getAgencyOrganicGrowth = async () => {
        console.log(props)
        setLoad(true);
        try {
            let result = await ogcAction({  type: GET_AGENCY_ORGANIC_GROWTH,
                                            year: props.year,
                                        }
                                        );
            if (result) {
                // console.log(result);
                setAgencyOrganicGrowth(result);
                const plData = {
                    planned: Math.round(result.personal_total_new_business_revenue),
                    created:Math.round(result.personal_organic_growth)
                }
                // console.log("pldata" ,plData)

                const clData = {
                    planned:Math.round(result.commercial_total_new_business_revenue),
                    created:Math.round(result.commercial_organic_growth)
                }
                // console.log('cldata',clData)
                setClDepartmentPiChartDataSet(clData);
                setPlDepartmentPiChartDataSet(plData);
                localStorage.setItem('total_revenue_increase_percentage',result.total_revenue_increase_percentage);
                setLoad(false)
            }
            else{
                setflag(true)
            }
        } catch(error) {
            toast.error(error);
            setLoad(false)
        }

        return;
    }

    const plData ={ 
        created: plDepartmentPiChartDataSet.created,
        planned:plDepartmentPiChartDataSet.planned
    }
    const clData = {
        created:clDepartmentPiChartDataSet.created,
        planned:clDepartmentPiChartDataSet.planned
    }
    const getCompletedPercentage = (name) => {
        if (name==='pl'){
            //console.log(plData.created);
            //console.log(plData.planned);
            const created = Math.round( parseFloat(plData.created)/plData.planned * 100);
            if (isNaN(created)) return 0;
            if (created>100) return 100;
            return created;
        }

        else if(name==='cl'){
            const created = Math.round( parseFloat(clData.created)/clData.planned * 100);
            if (isNaN(created)) return 0;
            if (created>100) return 100; 
            return created;
        }
        else{
            return 0;
        }
    };

    const timeGrpahOptions = {
        elements: {
            line: {
                tension: 0
            }
        },
        legend: {
            display: false
        }
    }


    const TimeNeededRoleWiseOptions = {
        elements: {
            line: {
                tension: 0
            }
        },
        legend: {
            display: false,
            position: 'bottom',
            
        },
        scales: {
            xAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }

    const totalHourRoleWiseGraphData  = {
        labels:['Outside Producers','Inside Sales','Account Manager','CSR/VA'],
        
        datasets: [
            {
                labels: ['Outside Producers','Inside Sales','Account Manager','CSR/VA'],
                backgroundColor:['blue','grey','lightblue','purple'],
                data: [ agencyOrganicGrowth.outside_producer_total_hours,
                        agencyOrganicGrowth.inside_sales_total_hours,
                        agencyOrganicGrowth.account_manager_total_hours,
                        agencyOrganicGrowth.support_total_hours
                    ]
                
            },
           
        ]
    }

    const plTimeData = {
        datasets: [
            {
              label: 'Total',
              backgroundColor: '#ed7d31',
              borderWidth: 1,
              hoverBackgroundColor: '#ed7d31',
              data: [agencyOrganicGrowth.personal_total_dept_time]
            },
            {
                label: 'Planned',
                backgroundColor: '#5b9bd5',
                borderWidth: 1,
                hoverBackgroundColor: '#5b9bd5',
                data: [agencyOrganicGrowth.personal_total_hour_fhe]
            }
        ]
    }

    const clTimeData = {
        datasets: [
            {
              label: 'Total',
              backgroundColor: '#ed7d31',
              borderWidth: 1,
              hoverBackgroundColor: '#ed7d31',
              data: [agencyOrganicGrowth.commercial_total_dept_time]
            },
            {
                label: 'Planned',
                backgroundColor: '#5b9bd5',
                borderWidth: 1,
                hoverBackgroundColor: '#5b9bd5',
                data: [agencyOrganicGrowth.commercial_total_hour_fhe]
            }
        ]
      
    }

    const plOptions = {
        scaleFontColor: "#FFFFFF",
        maintainAspectRatio: true,
        responsive: true,
        legend: {
            display: false,
            position: "top",
            labels: {
                fontSize: 10,
            }
        },
        cutoutPercentage:70,
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        pieceLabel: {
            render: 'label',
            fontSize: 15,
            fontStyle: 'bold',
            fontColor: '#000',
            arc: true
        },
        plugins: {
            datalabels: {
                display: false
            }
        },
        elements: {
            text: getCompletedPercentage('pl')
          },
        stacked: true,
    }

    const clOptions = {
        maintainAspectRatio: true,
        responsive: true,
        legend: {
            display: false,
            position: "top",
            labels: {
                fontSize: 10,
            }
        },
        cutoutPercentage:70,
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        pieceLabel: {
            render: 'label',
            fontSize: 15,
            fontStyle: 'bold',
            fontColor: '#000',
            arc: true
        },
        plugins: {
            datalabels: {
                display: false
            }
        },
        elements: {
            text: getCompletedPercentage('cl')
          },
        
    }
    

const plDepartmentPiChart = {
    labels: [
        'Total Organic Growth by LOB',
        'Growth needed'
    ],
    datasets: [{
        data: [plDepartmentPiChartDataSet.created, (plDepartmentPiChartDataSet.planned-plDepartmentPiChartDataSet.created)>0?(plDepartmentPiChartDataSet.planned-plDepartmentPiChartDataSet.created):0],
        
        backgroundColor: [
            '#1ab394',
            '#D3D3D3'
        ],
        hoverBackgroundColor: [
            '#1ab394',
            '#D3D3D3'
        ]
    }]
};
// console.log(plDepartmentPiChart)
// console.log('cl')
const clDepartmentPiChart = {
    labels: [
        'Total Organic Growth by LOB',
        'Growth needed'
    ],
    datasets: [{
        data: [clDepartmentPiChartDataSet.created, (clDepartmentPiChartDataSet.planned - clDepartmentPiChartDataSet.created)>0?(clDepartmentPiChartDataSet.planned - clDepartmentPiChartDataSet.created):0],
        backgroundColor: [
            '#1ab394',
            '#D3D3D3'
        ],
        hoverBackgroundColor: [
            '#1ab394',
            '#D3D3D3'
        ]
    }]
   
};

// if (agencyOrganicGrowth['personal_rev']['January']){
    const agencyTrendData = {
        
        labels: ['Jan','Feb','March','Apil','May','June','July','Aug','Sept','Oct','Nov','Dec'],
        datasets: [
            {
                label: 'TY Goal',
                fill: false,
                backgroundColor: 'rgba(85, 150, 217, 1)',
                borderColor: 'rgba(85, 150, 217, 0.7)',
                
                data: [
                    (agencyOrganicGrowth['commercial_rev']['January']?agencyOrganicGrowth['commercial_rev']['January']['commercial_ty_goal_total']:0) +
                    (agencyOrganicGrowth['personal_rev']['January'] ? agencyOrganicGrowth['personal_rev']['January']['personal_ty_goal_total'] : 0),

                    (agencyOrganicGrowth['commercial_rev']['February']?agencyOrganicGrowth['commercial_rev']['February']['commercial_ty_goal_total']:0) +
                    (agencyOrganicGrowth['personal_rev']['February']  ? agencyOrganicGrowth['personal_rev']['February']['personal_ty_goal_total'] : 0 ),

                    (agencyOrganicGrowth['personal_rev']['March'] ? agencyOrganicGrowth['personal_rev']['March']['personal_ty_goal_total'] : 0) + 
                    (agencyOrganicGrowth['commercial_rev']['March']?agencyOrganicGrowth['commercial_rev']['March']['commercial_ty_goal_total']:0),

                    (agencyOrganicGrowth['commercial_rev']['April']?agencyOrganicGrowth['commercial_rev']['April']['commercial_ty_goal_total']:0 )+
                    (agencyOrganicGrowth['personal_rev']['April']?agencyOrganicGrowth['personal_rev']['April']['personal_ty_goal_total']:0),

                    (agencyOrganicGrowth['commercial_rev']['May']?agencyOrganicGrowth['commercial_rev']['May']['commercial_ty_goal_total']:0 )+
                    (agencyOrganicGrowth['personal_rev']['May']?agencyOrganicGrowth['personal_rev']['May']['personal_ty_goal_total']:0),

                    (agencyOrganicGrowth['commercial_rev']['June']?agencyOrganicGrowth['commercial_rev']['June']['commercial_ty_goal_total']:0)  +
                    (agencyOrganicGrowth['personal_rev']['June']?agencyOrganicGrowth['personal_rev']['June']['personal_ty_goal_total']:0),

                    (agencyOrganicGrowth['commercial_rev']['July']?agencyOrganicGrowth['commercial_rev']['July']['commercial_ty_goal_total']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['July']?agencyOrganicGrowth['personal_rev']['July']['personal_ty_goal_total']:0),

                    (agencyOrganicGrowth['commercial_rev']['August']?agencyOrganicGrowth['commercial_rev']['August']['commercial_ty_goal_total']:0 )+
                    (agencyOrganicGrowth['personal_rev']['August']?agencyOrganicGrowth['personal_rev']['August']['personal_ty_goal_total']:0),

                    (agencyOrganicGrowth['commercial_rev']['September']?agencyOrganicGrowth['commercial_rev']['September']['commercial_ty_goal_total']:0) +
                    (agencyOrganicGrowth['personal_rev']['September']?agencyOrganicGrowth['personal_rev']['September']['personal_ty_goal_total']:0),

                    (agencyOrganicGrowth['commercial_rev']['October']?agencyOrganicGrowth['commercial_rev']['October']['commercial_ty_goal_total']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['October']?agencyOrganicGrowth['personal_rev']['October']['personal_ty_goal_total']:0),

                    (agencyOrganicGrowth['commercial_rev']['November']?agencyOrganicGrowth['commercial_rev']['November']['commercial_ty_goal_total']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['November']? agencyOrganicGrowth['personal_rev']['November']['personal_ty_goal_total']:0),

                    (agencyOrganicGrowth['commercial_rev']['December']?agencyOrganicGrowth['commercial_rev']['December']['commercial_ty_goal_total']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['December']?agencyOrganicGrowth['personal_rev']['December']['personal_ty_goal_total']:0), 
                ]    
            },
            {
                label: 'TY Actual',
                fill: false,
                backgroundColor: 'rgba(132, 190, 68, 1)',
                borderColor: 'rgba(132, 190, 68, 0.7)',
                data: [
                    
                    
                    (agencyOrganicGrowth['commercial_rev']['January']?agencyOrganicGrowth['commercial_rev']['January']['commercial_ty_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['January'] ? agencyOrganicGrowth['personal_rev']['January']['personal_ty_actual'] : 0),

                    (agencyOrganicGrowth['commercial_rev']['February']?agencyOrganicGrowth['commercial_rev']['February']['commercial_ty_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['February']  ? agencyOrganicGrowth['personal_rev']['February']['personal_ty_actual'] : 0 ),

                    (agencyOrganicGrowth['personal_rev']['March'] ? agencyOrganicGrowth['personal_rev']['March']['personal_ty_actual'] : 0 )+ 
                    (agencyOrganicGrowth['commercial_rev']['March']?agencyOrganicGrowth['commercial_rev']['March']['commercial_ty_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['April']?agencyOrganicGrowth['commercial_rev']['April']['commercial_ty_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['April']?agencyOrganicGrowth['personal_rev']['April']['personal_ty_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['May']?agencyOrganicGrowth['commercial_rev']['May']['commercial_ty_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['May']?agencyOrganicGrowth['personal_rev']['May']['personal_ty_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['June']?agencyOrganicGrowth['commercial_rev']['June']['commercial_ty_actual']:0  )+
                    (agencyOrganicGrowth['personal_rev']['June']?agencyOrganicGrowth['personal_rev']['June']['personal_ty_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['July']?agencyOrganicGrowth['commercial_rev']['July']['commercial_ty_actual']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['July']?agencyOrganicGrowth['personal_rev']['July']['personal_ty_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['August']?agencyOrganicGrowth['commercial_rev']['August']['commercial_ty_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['August']?agencyOrganicGrowth['personal_rev']['August']['personal_ty_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['September']?agencyOrganicGrowth['commercial_rev']['September']['commercial_ty_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['September']?agencyOrganicGrowth['personal_rev']['September']['personal_ty_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['October']?agencyOrganicGrowth['commercial_rev']['October']['commercial_ty_actual']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['October']?agencyOrganicGrowth['personal_rev']['October']['personal_ty_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['November']?agencyOrganicGrowth['commercial_rev']['November']['commercial_ty_actual']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['November']? agencyOrganicGrowth['personal_rev']['November']['personal_ty_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['December']?agencyOrganicGrowth['commercial_rev']['December']['commercial_ty_actual']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['December']?agencyOrganicGrowth['personal_rev']['December']['personal_ty_actual']:0), 
                ]    
            },
            {
                label: 'LY Actual',
                fill: false,
                backgroundColor: 'rgba(200, 200, 200, 1)',
                borderColor: 'rgba(200, 200, 200, 0.7)',
                data: [
                    
                    
                   
                    (agencyOrganicGrowth['commercial_rev']['January']?agencyOrganicGrowth['commercial_rev']['January']['commercial_ly_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['January'] ? agencyOrganicGrowth['personal_rev']['January']['personal_ly_actual'] : 0),

                    (agencyOrganicGrowth['commercial_rev']['February']?agencyOrganicGrowth['commercial_rev']['February']['commercial_ly_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['February']  ? agencyOrganicGrowth['personal_rev']['February']['personal_ly_actual'] : 0 ),

                    (agencyOrganicGrowth['personal_rev']['March'] ? agencyOrganicGrowth['personal_rev']['March']['personal_ly_actual'] : 0 )+ 
                    (agencyOrganicGrowth['commercial_rev']['March']?agencyOrganicGrowth['commercial_rev']['March']['commercial_ly_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['April']?agencyOrganicGrowth['commercial_rev']['April']['commercial_ly_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['April']?agencyOrganicGrowth['personal_rev']['April']['personal_ly_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['May']?agencyOrganicGrowth['commercial_rev']['May']['commercial_ly_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['May']?agencyOrganicGrowth['personal_rev']['May']['personal_ly_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['June']?agencyOrganicGrowth['commercial_rev']['June']['commercial_ly_actual']:0  )+
                    (agencyOrganicGrowth['personal_rev']['June']?agencyOrganicGrowth['personal_rev']['June']['personal_ly_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['July']?agencyOrganicGrowth['commercial_rev']['July']['commercial_ly_actual']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['July']?agencyOrganicGrowth['personal_rev']['July']['personal_ly_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['August']?agencyOrganicGrowth['commercial_rev']['August']['commercial_ly_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['August']?agencyOrganicGrowth['personal_rev']['August']['personal_ly_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['September']?agencyOrganicGrowth['commercial_rev']['September']['commercial_ly_actual']:0 )+
                    (agencyOrganicGrowth['personal_rev']['September']?agencyOrganicGrowth['personal_rev']['September']['personal_ly_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['October']?agencyOrganicGrowth['commercial_rev']['October']['commercial_ly_actual']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['October']?agencyOrganicGrowth['personal_rev']['October']['personal_ly_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['November']?agencyOrganicGrowth['commercial_rev']['November']['commercial_ly_actual']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['November']? agencyOrganicGrowth['personal_rev']['November']['personal_ly_actual']:0),

                    (agencyOrganicGrowth['commercial_rev']['December']?agencyOrganicGrowth['commercial_rev']['December']['commercial_ly_actual']:0 )+ 
                    (agencyOrganicGrowth['personal_rev']['December']?agencyOrganicGrowth['personal_rev']['December']['personal_ly_actual']:0), 
                ]    
            }
        ]
    };

const agencyPersonalTrendData = {
    labels: ['Jan','Feb','March','Apil','May','June','July','Aug','Sept','Oct','Nov','Dec'],
    datasets: [
        {
            label: 'TY Goal',
            fill: false,
            backgroundColor: 'rgba(85, 150, 217, 1)',
            borderColor: 'rgba(85, 150, 217, 0.7)',
            
            data: [
                
                agencyOrganicGrowth['personal_rev']['January'] ? agencyOrganicGrowth['personal_rev']['January']['personal_ty_goal_total'] : 0,
                agencyOrganicGrowth['personal_rev']['February']  ? agencyOrganicGrowth['personal_rev']['February']['personal_ty_goal_total'] : 0,
                agencyOrganicGrowth['personal_rev']['March'] ? agencyOrganicGrowth['personal_rev']['March']['personal_ty_goal_total'] : 0,
                agencyOrganicGrowth['personal_rev']['April']?agencyOrganicGrowth['personal_rev']['April']['personal_ty_goal_total']:0,
                agencyOrganicGrowth['personal_rev']['May']?agencyOrganicGrowth['personal_rev']['May']['personal_ty_goal_total']:0,
                agencyOrganicGrowth['personal_rev']['June']?agencyOrganicGrowth['personal_rev']['June']['personal_ty_goal_total']:0,
                agencyOrganicGrowth['personal_rev']['July']?agencyOrganicGrowth['personal_rev']['July']['personal_ty_goal_total']:0,
                agencyOrganicGrowth['personal_rev']['August']?agencyOrganicGrowth['personal_rev']['August']['personal_ty_goal_total']:0,
                agencyOrganicGrowth['personal_rev']['September']?agencyOrganicGrowth['personal_rev']['September']['personal_ty_goal_total']:0,
                agencyOrganicGrowth['personal_rev']['October']?agencyOrganicGrowth['personal_rev']['October']['personal_ty_goal_total']:0,
                agencyOrganicGrowth['personal_rev']['November']? agencyOrganicGrowth['personal_rev']['November']['personal_ty_goal_total']:0,
                agencyOrganicGrowth['personal_rev']['December']?agencyOrganicGrowth['personal_rev']['December']['personal_ty_goal_total']:0, 
            ]    
        },
        {
            label: 'TY Acutual',
            fill: false,
            backgroundColor: 'rgba(132, 190, 68, 1)',
            borderColor: 'rgba(132, 190, 68, 0.7)',
            data: [
                agencyOrganicGrowth['personal_rev']['January']?agencyOrganicGrowth['personal_rev']['January']['personal_ty_actual']:0,
                agencyOrganicGrowth['personal_rev']['February']?agencyOrganicGrowth['personal_rev']['February']['personal_ty_actual']:0,
                agencyOrganicGrowth['personal_rev']['March']?agencyOrganicGrowth['personal_rev']['March']['personal_ty_actual']:0,
                agencyOrganicGrowth['personal_rev']['April']?agencyOrganicGrowth['personal_rev']['April']['personal_ty_actual']:0,
                agencyOrganicGrowth['personal_rev']['May']?agencyOrganicGrowth['personal_rev']['May']['personal_ty_actual']:0,
                agencyOrganicGrowth['personal_rev']['June']?agencyOrganicGrowth['personal_rev']['June']['personal_ty_actual']:0,
                agencyOrganicGrowth['personal_rev']['July']?agencyOrganicGrowth['personal_rev']['July']['personal_ty_actual']:0,
                agencyOrganicGrowth['personal_rev']['August']?agencyOrganicGrowth['personal_rev']['August']['personal_ty_actual']:0,
                agencyOrganicGrowth['personal_rev']['September']?agencyOrganicGrowth['personal_rev']['September']['personal_ty_actual']:0,
                agencyOrganicGrowth['personal_rev']['October']?agencyOrganicGrowth['personal_rev']['October']['personal_ty_actual']:0,
                agencyOrganicGrowth['personal_rev']['November']?agencyOrganicGrowth['personal_rev']['November']['personal_ty_actual']:0,
                agencyOrganicGrowth['personal_rev']['December']?agencyOrganicGrowth['personal_rev']['December']['personal_ty_actual']:0, 
            ]    
        },
        {
            label: 'LY Actual',
            fill: false,
            backgroundColor: 'rgba(200, 200, 200, 1)',
            borderColor: 'rgba(200, 200, 200, 0.7)',
            data: [
                
              
                    agencyOrganicGrowth['personal_rev']['January']?agencyOrganicGrowth['personal_rev']['January']['personal_ly_actual']:0,
                    agencyOrganicGrowth['personal_rev']['February']?agencyOrganicGrowth['personal_rev']['February']['personal_ly_actual']:0,
                    agencyOrganicGrowth['personal_rev']['March']?agencyOrganicGrowth['personal_rev']['March']['personal_ly_actual']:0,
                    agencyOrganicGrowth['personal_rev']['April']?agencyOrganicGrowth['personal_rev']['April']['personal_ly_actual']:0,
                    agencyOrganicGrowth['personal_rev']['May']?agencyOrganicGrowth['personal_rev']['May']['personal_ly_actual']:0,
                    agencyOrganicGrowth['personal_rev']['June']?agencyOrganicGrowth['personal_rev']['June']['personal_ly_actual']:0,
                    agencyOrganicGrowth['personal_rev']['July']?agencyOrganicGrowth['personal_rev']['July']['personal_ly_actual']:0,
                    agencyOrganicGrowth['personal_rev']['August']?agencyOrganicGrowth['personal_rev']['August']['personal_ly_actual']:0,
                    agencyOrganicGrowth['personal_rev']['September']?agencyOrganicGrowth['personal_rev']['September']['personal_ly_actual']:0,
                    agencyOrganicGrowth['personal_rev']['October']?agencyOrganicGrowth['personal_rev']['October']['personal_ly_actual']:0,
                    agencyOrganicGrowth['personal_rev']['November']?agencyOrganicGrowth['personal_rev']['November']['personal_ly_actual']:0,
                    agencyOrganicGrowth['personal_rev']['December']?agencyOrganicGrowth['personal_rev']['December']['personal_ly_actual']:0, 
                ]     
               
        }
    ]
};


// if (agencyOrganicGrowth['commercial_rev']['January']){
    const agencyCommercialTrendData = {
        
        labels: ['Jan','Feb','March','Apil','May','June','July','Aug','Sept','Oct','Nov','Dec'],
        datasets: [
            {
                label: 'TY Goal',
                fill: false,
                backgroundColor: 'rgba(85, 150, 217, 1)',
                borderColor: 'rgba(85, 150, 217, 0.7)',
                
                data: [
                    agencyOrganicGrowth['commercial_rev']['January']?agencyOrganicGrowth['commercial_rev']['January']['commercial_ty_goal_total']:0,
                    agencyOrganicGrowth['commercial_rev']['February']?agencyOrganicGrowth['commercial_rev']['February']['commercial_ty_goal_total']:0,
                    agencyOrganicGrowth['commercial_rev']['March']?agencyOrganicGrowth['commercial_rev']['March']['commercial_ty_goal_total']:0,
                    agencyOrganicGrowth['commercial_rev']['April']?agencyOrganicGrowth['commercial_rev']['April']['commercial_ty_goal_total']:0,
                    agencyOrganicGrowth['commercial_rev']['May']?agencyOrganicGrowth['commercial_rev']['May']['commercial_ty_goal_total']:0,
                    agencyOrganicGrowth['commercial_rev']['May']?agencyOrganicGrowth['commercial_rev']['May']['commercial_ty_goal_total']:0,
                    agencyOrganicGrowth['commercial_rev']['July']?agencyOrganicGrowth['commercial_rev']['July']['commercial_ty_goal_total']:0,
                    agencyOrganicGrowth['commercial_rev']['August']?agencyOrganicGrowth['commercial_rev']['August']['commercial_ty_goal_total']:0,
                    agencyOrganicGrowth['commercial_rev']['September']?agencyOrganicGrowth['commercial_rev']['September']['commercial_ty_goal_total']:0,
                    agencyOrganicGrowth['commercial_rev']['October']?agencyOrganicGrowth['commercial_rev']['October']['commercial_ty_goal_total']:0,
                    agencyOrganicGrowth['commercial_rev']['November']?agencyOrganicGrowth['commercial_rev']['November']['commercial_ty_goal_total']:0,
                    agencyOrganicGrowth['commercial_rev']['December']?agencyOrganicGrowth['commercial_rev']['December']['commercial_ty_goal_total']:0, 
                ]    
            },
            {
                label: 'TY Acutual',
                fill: false,
                backgroundColor: 'rgba(132, 190, 68, 1)',
                borderColor: 'rgba(132, 190, 68, 0.7)',
                data: [
                    
                    agencyOrganicGrowth['commercial_rev']['January']?agencyOrganicGrowth['commercial_rev']['January']['commercial_ty_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['February']?agencyOrganicGrowth['commercial_rev']['February']['commercial_ty_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['March']?agencyOrganicGrowth['commercial_rev']['March']['commercial_ty_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['April']?agencyOrganicGrowth['commercial_rev']['April']['commercial_ty_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['May']?agencyOrganicGrowth['commercial_rev']['May']['commercial_ty_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['May']?agencyOrganicGrowth['commercial_rev']['May']['commercial_ty_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['July']?agencyOrganicGrowth['commercial_rev']['July']['commercial_ty_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['August']?agencyOrganicGrowth['commercial_rev']['August']['commercial_ty_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['September']?agencyOrganicGrowth['commercial_rev']['September']['commercial_ty_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['October']?agencyOrganicGrowth['commercial_rev']['October']['commercial_ty_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['November']?agencyOrganicGrowth['commercial_rev']['November']['commercial_ty_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['December']?agencyOrganicGrowth['commercial_rev']['December']['commercial_ty_actual']:0, 
                ]    
            },
            {
                label: 'LY Actual',
                fill: false,
                backgroundColor: 'rgba(200, 200, 200, 1)',
                borderColor: 'rgba(200, 200, 200, 0.7)',
                data: [
                    
                    agencyOrganicGrowth['commercial_rev']['January']?agencyOrganicGrowth['commercial_rev']['January']['commercial_ly_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['February']?agencyOrganicGrowth['commercial_rev']['February']['commercial_ly_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['March']?agencyOrganicGrowth['commercial_rev']['March']['commercial_ly_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['April']?agencyOrganicGrowth['commercial_rev']['April']['commercial_ly_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['May']?agencyOrganicGrowth['commercial_rev']['May']['commercial_ly_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['May']?agencyOrganicGrowth['commercial_rev']['May']['commercial_ly_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['July']?agencyOrganicGrowth['commercial_rev']['July']['commercial_ly_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['August']?agencyOrganicGrowth['commercial_rev']['August']['commercial_ly_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['September']?agencyOrganicGrowth['commercial_rev']['September']['commercial_ly_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['October']?agencyOrganicGrowth['commercial_rev']['October']['commercial_ly_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['November']?agencyOrganicGrowth['commercial_rev']['November']['commercial_ly_actual']:0,
                    agencyOrganicGrowth['commercial_rev']['December']?agencyOrganicGrowth['commercial_rev']['December']['commercial_ly_actual']:0, 
                ]    
            }
        ]
    };

 
 if (flag || window.token.access_token === null){
     return(<Redirect to='/login' />);
 }
 else{
    return(
        <>
       

       <section className="container-fluid">
           
            <div className="row no-gutters">
                <div className="col-lg-3">
                    
                       
                            <div className="mt-2 mr-1 ml-1 pt-4 mb-3 row-lg-9 no-gutters text-center"  style={{backgroundColor:"#167F8B",height:'97%'}} >
                                <NumberFormat className="form-control text-center"
                                    thousandSeparator={true} decimalScale={0} suffix={'% Growth'}
                                    value={agencyOrganicGrowth.total_revenue_increase_percentage}
                                    allowNegative={false}
                                    disabled={true}
                                    displayType='text'
                                    style = {{'border': '0px', 'font-size': '200%' ,backgroundColor:"inherit", 'color':'#faefa0' ,'font-weight':'bold'}}
                                /> 
                                <label style={{"color":"white",'font-size':'90%' ,'font-weight':'bold' }} className='mt-2 mb-2'>
                                    Total New Revenue Needed/Planned
                                </label>
                                <NumberFormat className="form-control  text-center"
                                    thousandSeparator={true} decimalScale={0}  prefix="$" suffix={` / $${Math.round(agencyOrganicGrowth.personal_total_new_business_revenue) + Math.round(agencyOrganicGrowth.commercial_total_new_business_revenue)  } `}
                                    value={agencyOrganicGrowth.total_organic_growth}
                                    allowNegative={false}
                                    disabled={true}
                                    displayType='text'
                                    style = {{'border': '0px', 'background-color':"inherit", 'color':'white', 'font-weight':'bold','font-size':'190%'}}
                                /> 
                          
                      
                    </div>
                    
            </div>
            <div className="col-lg-9">
                <div style={{backgroundColor:'white'}} className="ml-2 mt-2 mr-2 p-2 row-lg-9 no-gutters">
                    <label style={{color:'black', fontWeight:"bold", fontSize:"15px"}} >
                        Total Agency Monthly-LY Actual/TY Goal/TY Actual 
                    </label>
                    <div className="ml-2 p-2 tab-pane mt-0 pl-1 active" id="agencytrend" role="tabpanel">
                        <Line 
                            data={agencyTrendData} 
                            height={50} 
                            options={lineChartOptions}
                        />
                    </div>
                </div>
            </div>
            <div className='col-12 mt-2'>
            <div className="row no-gutters">
                <div className='col-4'>
                <div className="card section-border mr-1 ml-1" >
                                <div style={{color:'white' , backgroundColor:"#209487",fontSize:'110%'}} className="card-header text-white text-center mb-0 p-1">
                                    <label className="mb-0 mr-5 pl-5">
                                    {props.year -1 } Actual Revenue 
                                    </label>
                                    <br/>
                                    <label className="mb-0">
                                        Personal/Commercial
                                    </label>
                                </div>
                            <div className="card-body text-center font-weight-bold p-4" style={{fontSize:'150%'}}>
                                <NumberFormat 
                                    className="form-control data-year form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0} prefix={'$ '} suffix={'  / '}
                                    value={agencyOrganicGrowth.personal_dept_revenue_ly}
                                    allowNegative={false}
                                    disabled={true}
                                    displayType='text'
                                />
                                <NumberFormat 
                                    className="data-year form-control form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0} prefix={'$ '}
                                    value={agencyOrganicGrowth.commercial_dept_revenue_ly}
                                    allowNegative={false}
                                    disabled={true}
                                    displayType='text'
                                    
                                />
                            </div>
                            </div>
                        </div>
                        <div className='col-4'>
                        <div className="card section-border mr-1"  >
                                <div style={{color:'white' , backgroundColor:"#209487",fontSize:'110%'}} className="card-header text-white text-center mb-0 p-1">
                                    <label className="mb-0 mr-5 pl-5">
                                    {props.year - 1} Actual Premium     
                                    </label>
                                    <br/>
                                    <label className="mb-0">
                                            Personal/Commercial
                                    </label>
                                </div>
                            <div className="card-body text-center font-weight-bold p-4" style={{fontSize:'150%'}}>
                                <NumberFormat className="data-year form-control form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0} prefix={'$ '} suffix={'  / '}
                                    value={agencyOrganicGrowth.personal_dept_actual_premium_ly}
                                    allowNegative={false}
                                    disabled={true}
                                    displayType='text'
                                />
                                <NumberFormat 
                                    className="data-year form-control form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0} prefix={'$ '}
                                    value={agencyOrganicGrowth.commercial_dept_actual_premium_ly}
                                    allowNegative={false}
                                    disabled={true}
                                    displayType='text'
                                />
                            </div>
                        </div>
                        </div>
                        <div className='col-4'>
                        <div className="card section-border" >
                                <div style={{color:'white' , backgroundColor:"#209487",fontSize:'110%'}} className="card-header text-white text-center mb-0 p-1">
                                    <label className="mb-0">
                                    {props.year -1 } Actual Revenue Per Person
                                    </label>
                                    <br/>
                                    <label className="mb-0">
                                        Personal/Commercial
                                    </label>
                                </div>
                            <div className="card-body text-center font-weight-bold p-4" style={{fontSize:'150%'}}>
                                <NumberFormat className="data-year form-control form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0} prefix={'$ '} suffix={'  / '}
                                    value={agencyOrganicGrowth.personal_dept_revenue_per_person_ly}
                                    allowNegative={false}
                                    disabled={true}
                                    displayType='text'
                                />
                                <NumberFormat 
                                    className="data-year form-control form-control-sm text-center"
                                    thousandSeparator={true} decimalScale={0} prefix={'$ '}
                                    value={agencyOrganicGrowth.commercial_dept_revenue_per_person_ly}
                                    allowNegative={false}
                                    disabled={true}
                                    displayType='text'
                                />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className='col-12 mt-2'>
                    <div className="row no-gutters">
                        <div className='col-4'>
                            <div className="card section-border mr-1 ml-1" >
                                <div style={{color:'white' , backgroundColor:"#209487",fontSize:'110%'}} className="card-header text-white text-center mb-0 p-2">
                                    <label className="mb-0 mr-5 pl-5">
                                    {props.year} Planned Revenue
                                    </label>
                                    <br/>
                                    <label className="mb-0">
                                        Personal/Commercial
                                    </label>
                                </div>
                                <div className="card-body text-center font-weight-bold p-4" style={{fontSize:'150%'}}>
                                    <NumberFormat 
                                        className="form-control data-year form-control-sm text-center"
                                        thousandSeparator={true} decimalScale={0} prefix={'$ '} suffix={'  / '}
                                        value={agencyOrganicGrowth.personal_dept_revenue_planned}
                                        allowNegative={false}
                                        disabled={true}
                                        displayType='text'
                                        
                                    />
                                    <NumberFormat 
                                        className="data-year form-control form-control-sm text-center"
                                        thousandSeparator={true} decimalScale={0} prefix={'$ '}
                                        value={agencyOrganicGrowth.commercial_dept_revenue_planned}
                                        allowNegative={false}
                                        disabled={true}
                                        displayType='text'
                                    />
                                </div>
                            </div>
                            </div>
                            
                            <div className='col-4'>
                            <div className="card section-border mr-1" >
                        
                                <div style={{color:'white' , backgroundColor:"#209487",fontSize:'110%'}} className="card-header text-white text-center mb-0 p-2">
                                    <label className="mb-0 mr-5 pl-5">
                                    {props.year} Planned Premium
                                    </label>
                                    <br/>
                                    <label className="mb-0">
                                        Personal/Commercial
                                    </label>
                                </div>
                                <div  className="card-body text-center font-weight-bold p-4" style={{fontSize:'150%'}}>
                                    <NumberFormat 
                                        className="data-year form-control form-control-sm text-center"
                                        thousandSeparator={true} decimalScale={0} prefix={'$ '} suffix={'  / '}
                                        value={agencyOrganicGrowth.personal_dept_planned_premium}
                                        allowNegative={false}
                                        disabled={true}
                                        displayType='text'
                                        style = {{'border': '0px',backgroundColor:"inherit", 'font-size':'165%', 'font-weight':'bold'}}
                                    />
                                    <NumberFormat 
                                        className="data-year form-control form-control-sm text-center"
                                        thousandSeparator={true} decimalScale={0} prefix={'$ '}
                                        value={agencyOrganicGrowth.commercial_dept_planned_premium}
                                        allowNegative={false}
                                        disabled={true}
                                        displayType='text'
                                    />
                                </div>
                          
                        </div>
                        </div>
                        <div className='col-4'>
                            <div className="card section-border" >
                                <div style={{color:'white' , backgroundColor:"#209487",fontSize:'110%'}} className="card-header text-white text-center mb-0 p-2">
                                    <label className="mb-0">
                                    {props.year} Planned Revenue Per Person
                                    </label>
                                    <br/>
                                    <label className="mb-0">
                                        Personal/Commercial
                                    </label>
                                </div>
                                <div  className="card-body text-center font-weight-bold p-4" style={{fontSize:'150%'}}>
                                    <NumberFormat 
                                        className="data-year form-control form-control-sm text-center"
                                        thousandSeparator={true} decimalScale={0} prefix={'$ '} suffix={'  / '}
                                        value={agencyOrganicGrowth.personal_dept_revenue_per_person_planned}
                                        allowNegative={false}
                                        disabled={true}
                                        displayType='text'
                                        style = {{'border': '0px',backgroundColor:"inherit", 'font-size':'165%', 'font-weight':'bold'}}
                                    />
                                    <NumberFormat 
                                        className="data-year form-control form-control-sm text-center"
                                        thousandSeparator={true} decimalScale={0} prefix={'$ '}
                                        value={agencyOrganicGrowth.commercial_dept_revenue_per_person_planned}
                                        allowNegative={false}
                                        disabled={true}
                                        displayType='text'
                                    />
                                </div>
                          
                        </div>
                    </div>

                    </div>
                    </div>


                <div className="row no-gutters">
                    <div className="col-lg-4">
                        <div style={{backgroundColor:"white"}} className="row m-2 p-2">
                            <Doughnut data={plDepartmentPiChart} width={100} height={80} options={plOptions}/>
                            <label   style={{padding:"4px" ,marginLeft:'20%', fontWeight:"bold", color:"white",backgroundColor:"#1ab394"}}>
                            PL Growth Needed/Planned
                            </label>
                        </div>
                        <div  style={{'text-align': 'center',backgroundColor:"white"}} className="row m-2 mt-2 p-2">
                            <Doughnut data={clDepartmentPiChart} width={100} height={80} options={clOptions}/>
                            <label  style={{padding:"4px",marginLeft:'20%', fontWeight:"bold", color:"white",backgroundColor:"#1ab394"}}>
                            CL Growth Needed/Planned
                            </label>
                        </div>     
                    </div>
                    <div className="col-lg-4">
                            <div style={{backgroundColor:"white", height:'48%'}} className="row-lg-4 mt-2 p-2   ">
                                <label style={{color:'black', padding:"2%" ,fontWeight:"bold", fontSize:"18px"}} className="pt-1 ">
                                        PL- Total/Planned Hours 
                                </label>
                                <div>
                                    <HorizontalBar data={plTimeData} 
                                        height={220}
                                        options = {timeGrpahOptions}
                                    />
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",height:'48%'}} className="row-lg-4 mt-2 p-2">
                                <label style={{color:'black', padding:"2%" ,fontWeight:"bold", fontSize:"18px"}}>
                                    CL- Total/Planned Hours 
                                </label>
                                <div>                              
                                <HorizontalBar data={clTimeData} 
                                    height={220}
                                    options = {timeGrpahOptions}
                                />
                                </div>
                            </div>
                                             
                    </div>
                    <div className="col-lg-4">
                        <div style={{backgroundColor:"",}} className="m-2 ">
                            <div style={{backgroundColor:"white", textAlign:"center",}}>
                                <label style={{color:'black',  fontWeight:"bold", fontSize:"18px"}} className='mt-5 mb-4'>
                                    Time Needed by Job Role 
                                </label>
                                <div className='p-3 '>
                                <HorizontalBar data={totalHourRoleWiseGraphData} 
                                    height={490}
                                    options = {TimeNeededRoleWiseOptions}
                                />
                                </div>
                            </div>
                        </div>
                     
                    </div>
            </div>  
           
            <div className="col-lg-12 row no-gutters">
                <div className="col-lg-6">
                    <div style={{backgroundColor:"white",}} className=" m-1 ml-2 p-2 ">
                        <label style={{color:'black', fontWeight:"bold", fontSize:"15px"}}>
                            PL-Monthly-LY Actual/TY Goal/TY Actual 
                        </label>
                        <div className=" tab-pane mt-0  active" id="agencytrend" role="tabpanel">
                            <Line 
                                data={agencyPersonalTrendData} 
                                height={130}
                                options={lineChartOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div style={{backgroundColor:"white",}} className="m-1 p-2 mr-2">
                        <label style={{color:'black', fontWeight:"bold", fontSize:"15px"}}>
                                CL-Monthly-LY Actual/TY Goal/TY Actual 
                        </label>
                        <div className=" tab-pane mt-0  active" id="agencytrend" role="tabpanel">
                            <Line 
                             data={agencyCommercialTrendData} 
                             height={130} 
                             options={lineChartOptions}
                            />
                        </div>
                    </div>
                </div>
              </div>
            </div>
        
    </section>
    {isloading && <LoadingModal modal={isloading} />}
    </>
);
    }


}


export default OrganicDashboard;
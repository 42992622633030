import { toast } from 'react-toastify';
import {axiosInstance, axiosInstanceWithToken} from '../utils/Client';
import {
    USER_PROFILE, GET_LOB, UPDATE_LOB, 
    GET_GENERAL_INFO, UPDATE_GENERAL_INFO,
    GET_KPI_INFO, UPDATE_KPI_INFO, GET_ADD_OTHER_DEPT,GET_AGENCY_MONTHLY_INFO,
    UPDATE_ADD_OTHER_DEPT, GET_LOB_ORGANIC_GROWTH, GET_AGENCY_ORGANIC_GROWTH, GET_HABIT_ORGANIC_GROWTH,UPDATE_AGENCY_MONTHLY_INFO
} from './ActionTypes';
import { Redirect } from "react-router";


const ogcAction = async (action) => {
    try {
        let result;
        switch (action.type) {
            case USER_PROFILE: {
                result = await axiosInstance.post(`/nowcerts/user/profile/`, action.token);
                break;
            }
            case GET_LOB: {
                result = await axiosInstanceWithToken.get(
                    `/nowcerts/lob/list/${action.year}/${action.department}/`
                );
                break;
            }
            case UPDATE_LOB: {
                result = await axiosInstanceWithToken.put(
                    `/nowcerts/lob/list/${action.year}/${action.department}/`, action.data
                );
                break;
            }
            case GET_AGENCY_MONTHLY_INFO : {
                result = await axiosInstanceWithToken.get(
                    `/nowcerts/agency_monthly/${action.year}/${action.department}/`
                );
                break;
            }
            case UPDATE_AGENCY_MONTHLY_INFO : {
                result = await axiosInstanceWithToken.put(
                    `/nowcerts/agency_monthly/${action.year}/${action.department}/`,action.data
                );
                break;
            }
            case GET_GENERAL_INFO: {
                result = await axiosInstanceWithToken.get(
                    `/nowcerts/general/info/${action.year}/${action.department}/`
                );
                break;
            }
            case UPDATE_GENERAL_INFO: {
                result = await axiosInstanceWithToken.put(
                    `/nowcerts/general/info/${action.year}/${action.department}/`, action.data
                );
                break;
            }
            case GET_KPI_INFO: {
                result = await axiosInstanceWithToken.get(
                    `/nowcerts/kpi/info/${action.year}/${action.department}/`
                );
                break;
            }
            case UPDATE_KPI_INFO: {
                result = await axiosInstanceWithToken.put(
                    `/nowcerts/kpi/info/${action.year}/${action.department}/`, action.data
                );
                break;
            }
            case GET_ADD_OTHER_DEPT: {
                result = await axiosInstanceWithToken.get(
                    `/nowcerts/general_info/total_customer/${action.year}/${action.department}/`
                );
                break;
            }
            case UPDATE_ADD_OTHER_DEPT: {
                result = await axiosInstanceWithToken.put(
                    `/nowcerts/general_info/total_customer/${action.year}/${action.department}/`, action.data
                );
                break;
            }
            case GET_LOB_ORGANIC_GROWTH: {
                result = await axiosInstanceWithToken.get(
                    `/nowcerts/organic_growth_lob/view/${action.year}/${action.department}/`
                );
                break;
            }
            case GET_HABIT_ORGANIC_GROWTH: {
                result = await axiosInstanceWithToken.get(
                    `/nowcerts/organic_growth_Kpi/view/${action.year}/${action.department}/`
                );
                break;
            }
            case GET_AGENCY_ORGANIC_GROWTH: {
                result = await axiosInstanceWithToken.get(
                    `/nowcerts/agency_organic_growth/view/${action.year}/`
                );
                break;
            }

            default:
                result = {};
        }
        let appVersion = localStorage.getItem('app-version');
        if (appVersion !== result.headers['app-version']) {
            localStorage.setItem('app-version', result.headers['app-version']);
            window.location.reload();
        }

        if (200 === result.status || 201 === result.status) {
           // console.log(result.data);
            //console.log(result);
            return result.data;
        }
        
    } catch (error) {
        console.log(error);
        console.log(error.response);
        console.log(error.data);
        if (error.response === undefined){
            toast.error("Something went wrong. Please Try again Later.");
        }
        //console.log("inside catch");
        if(401 === error.response.status) {
            toast.error("Unauthorized: Credentials are expired.");
            // return(<Redirect to='/login' />);
            return false;
        }
        throw error.response;
    }

    return false;
}

export default ogcAction;

import React, {Component} from 'react';


const SideNav = (props) => {
   
    return (
    <div style={{marginTop:"50px"}} className="sidenav">
      <label>Organic Growth Calculator</label>
      
      <select style={{ width:"80%", alignSelf:"center" ,backgroundColor:'#233646', color:"#34abc1", marginRight:'2px'}} value={props.year} 
            onChange={(e) => props.setYearState(Number(e.target.value)) }
            className="form-control form-control-sm"
        >
        {props.renderYear()}
      </select>

      <a href='/#'  onClick={() => props.setHeaderOnLinkClick("DashBoard: Total Agency")} className={ props.activeTab==10 ? "sidenav-active":""}>Dashboard</a>
      <a href="/#0" onClick={() => props.setHeaderOnLinkClick("General Information")} className={ props.activeTab==0 ? "sidenav-active":""}>General Information</a>
      <a href="/#6" onClick={() => props.setHeaderOnLinkClick("Annual Premium")} className={ props.activeTab==6 ? "sidenav-active":""}>Annual Premium</a>
      <a href="/#1" onClick={() => props.setHeaderOnLinkClick("Habits/KPIs")} className={ props.activeTab==1 ? "sidenav-active":""}>Habits/KPIs</a>
      <a href="/#2" onClick={() => props.setHeaderOnLinkClick("Personal Lines LOB")} className={ props.activeTab==2 ? "sidenav-active":""}>LOB data - Personal</a>
      <a href="/#4" onClick={() => props.setHeaderOnLinkClick("Commercial Lines LOB")} className={ props.activeTab==4 ? "sidenav-active":""}>LOB data - Commercial</a>
      <a href="/#3" onClick={() => props.setHeaderOnLinkClick("Personal Lines Time Needed")} className={ props.activeTab==3 ? "sidenav-active":""}>Time Needed - Personal</a>
      <a href="/#5" onClick={() => props.setHeaderOnLinkClick("Commercial Lines LOB")} className={ props.activeTab==5 ? "sidenav-active":""}>Time Needed - Commercial</a>
      
      
    </div>  
     );
    };

export default SideNav;
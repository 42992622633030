const lyActual = {
    jan :  (localStorage.getItem('lyjan')!==null)? localStorage.getItem('lyjan'):0,
    feb : (localStorage.getItem('lyfeb')!==null)? localStorage.getItem('lyfeb'):0,
    march : (localStorage.getItem('lymarch')!==null)? localStorage.getItem('lymarch'):0,
    april : (localStorage.getItem('lyapril')!==null)? localStorage.getItem('lyapril'):0,
    may  : (localStorage.getItem('lymay')!==null)? localStorage.getItem('lymay'):0,
    june : (localStorage.getItem('jan')!==null)? localStorage.getItem('lyjune'):0,
    july : (localStorage.getItem('lyjune')!==null)? localStorage.getItem('lyjuly'):0,
    aug : (localStorage.getItem('lyaug')!==null)? localStorage.getItem('lyaug'):0,
    sept : (localStorage.getItem('lysept')!==null)? localStorage.getItem('lysept'):0,
    oct : (localStorage.getItem('lyoct')!==null)? localStorage.getItem('lyoct'):0,
    nov : (localStorage.getItem('lynov')!==null)? localStorage.getItem('lynov'):0,
    dec : (localStorage.getItem('lydec'))!==null? localStorage.getItem('lydec'):0,
};

const tyActual = {
    jan :  (localStorage.getItem('tyjan')!==null)? localStorage.getItem('tyjan'):0,
    feb : (localStorage.getItem('tyfeb')!==null)? localStorage.getItem('tyfeb'):0,
    march : (localStorage.getItem('tymarch')!==null)? localStorage.getItem('tymarch'):0,
    april : (localStorage.getItem('tyapril')!==null)? localStorage.getItem('tyapril'):0,
    may  : (localStorage.getItem('tymay')!==null)? localStorage.getItem('tymay'):0,
    june : (localStorage.getItem('tyjune')!==null)? localStorage.getItem('tyjune'):0,
    july : (localStorage.getItem('tyjuly')!==null)? localStorage.getItem('tyjuly'):0,
    aug : (localStorage.getItem('tyaug')!==null)? localStorage.getItem('tyaug'):0,
    sept : (localStorage.getItem('tysept')!==null)? localStorage.getItem('tysept'):0,
    oct : (localStorage.getItem('tyoct')!==null)? localStorage.getItem('tyoct'):0,
    nov : (localStorage.getItem('tynov')!==null)? localStorage.getItem('tynov'):0,
    dec : (localStorage.getItem('tydec')!==null)? localStorage.getItem('tydec'):0,
};



let tyGoal = {
        jan:0,
        feb:0,
        march:0,
        april:0,
        may:0,
        june:0,
        july:0,
        aug:0,
        sept:0,
        oct:0,
        nov:0,
        dec:0
    }

if (localStorage.getItem('total_revenue_increase_percentage')!==0){
    tyGoal = {
        jan: (localStorage.getItem('lyjan')!==null) ? (parseFloat(localStorage.getItem('lyjan')) 
                            + (parseFloat(localStorage.getItem('lyjan'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
        
        feb: (localStorage.getItem('lyfeb')!==null) ? (parseFloat(localStorage.getItem('lyjan')) 
                            + (parseFloat(localStorage.getItem('lyfeb'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
    
        march: (localStorage.getItem('lymarch')!==null) ? (parseFloat(localStorage.getItem('lymarch') )
        + (parseFloat(localStorage.getItem('lymarch'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
        
        april: (localStorage.getItem('lyapril')!==null) ? (parseFloat(localStorage.getItem('lyapril')) 
        + (parseFloat(localStorage.getItem('lyapril'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
        
        may: (localStorage.getItem('lymay')!==null) ? (parseFloat(localStorage.getItem('lymay')) 
        + (parseFloat(localStorage.getItem('lymay'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
        
        june: (localStorage.getItem('lyjune')!==null) ? (parseFloat(localStorage.getItem('lyjune')) 
        + (parseFloat(localStorage.getItem('lyjune'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
        
        july: (localStorage.getItem('lyjuly')!==null) ? (parseFloat(localStorage.getItem('lyjuly')) 
        + (parseFloat(localStorage.getItem('lyjuly'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
        
        august: (localStorage.getItem('lyaugust')!==null) ? (parseFloat(localStorage.getItem('lyaugust')) 
        + (parseFloat(localStorage.getItem('lyaugust'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
        
        sept: (localStorage.getItem('lysept')!==null) ? (parseFloat(localStorage.getItem('lysept') )
        + (parseFloat(localStorage.getItem('lysept'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
        
        oct: (localStorage.getItem('lyoct')!==null) ? (parseFloat(localStorage.getItem('lyoct')) 
        + (parseFloat(localStorage.getItem('lyoct'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
        
        nov: (localStorage.getItem('lynov')!==null) ? (parseFloat(localStorage.getItem('lynov')) 
        + (parseFloat(localStorage.getItem('lynov'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
        
        dec: (localStorage.getItem('lydec')!==null) ? (parseFloat(localStorage.getItem('lydec')) 
        + (parseFloat(localStorage.getItem('lydec'))* parseFloat(localStorage.getItem('total_revenue_increase_percentage'))/100)):0,
    
    }

}

export const agencyTrendData = {
    labels: ['Jan','Feb','March','Apil','May','June','July','Aug','Sept','Oct','Nov','Dec'],
    datasets: [
        {
            label: 'TY Goal',
            fill: false,
            backgroundColor: 'rgba(85, 150, 217, 1)',
            borderColor: 'rgba(85, 150, 217, 0.7)',
            data: [tyGoal.jan, tyGoal.feb,tyGoal.march,
                tyGoal.april, tyGoal.may,tyGoal.june,
                tyGoal.july, tyGoal.aug,
                tyGoal.sept, tyGoal.oct,
                tyGoal.nov, tyGoal.dec]
        },
        {
            label: 'TY Acutual',
            fill: false,
            backgroundColor: 'rgba(132, 190, 68, 1)',
            borderColor: 'rgba(132, 190, 68, 0.7)',
            data: [tyActual.jan, tyActual.feb,tyActual.march,
                tyActual.april, tyActual.may,tyActual.june,
                tyActual.july, tyActual.aug,
                tyActual.sept, tyActual.oct,
                tyActual.nov, tyActual.dec]
        },
        {
            label: 'LY Actual',
            fill: false,
            backgroundColor: 'rgba(200, 200, 200, 1)',
            borderColor: 'rgba(200, 200, 200, 0.7)',
            data: [lyActual.jan,lyActual.feb,lyActual.march,
                    lyActual.april,lyActual.may,lyActual.june,
                    lyActual.july,
                    lyActual.aug,
                    lyActual.sept,lyActual.oct,lyActual.nov,
                    lyActual.dec]
        }
    ]
};

export const lineChartOptions = {
    scales: {
        yAxes: [{
         gridLines: {
                color: "rgba(0, 0, 0, 0)",
                    },   
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
                    if(parseInt(value) >= 1000){
                        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else {
                        return '$' + value;
                    }
                }
            }
        }
       
    ],
    xAxes: [{
        gridLines: {
            color: "rgba(0, 0, 0, 0)",
        }
    }],

    },
    tooltips: {
        callbacks: {
            label: function(tooltipItem, data) {
                try {
                    return " " + data.datasets[tooltipItem.datasetIndex].label + ": $ " + Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function(c, i, a) {
                        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
                    });
                } catch(error) {
                    return tooltipItem.yLabel;
                }
            }
        }
    },
    elements: {
        line: {
            tension: 0
        }
    },
    plugins: {
        datalabels: {
            display: false
        }
    },
    legend: {
        display: false
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
    }
   
}


import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';


const ConfirmModal = ({showConfirmModal, msgTitle, msgBody, buttons, hide, size='sm'}) => {
    const buttonsArray = buttons.map((btn , index) => (
        <Button className={`${btn['className']}`} 
            onClick={btn.onClick} key={index} 
            name='discard-model-btn'>{btn.label}
        </Button>
    )); 

    return (
        <Modal isOpen={showConfirmModal} size={size} 
            className='border' toggle={hide}
        >
            <ModalHeader>
                {msgTitle}
            </ModalHeader>
            <ModalBody className='bg-white'>
                {msgBody}
            </ModalBody>
            <ModalFooter onClick={hide}>
                {buttonsArray}
            </ModalFooter>
        </Modal>
    );
}

export default ConfirmModal;
import React from 'react';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import App from "./App";
import OrganicGrowthCalculator from "./components/ogc/OGCContainer"
import Login from "./components/ogc/Login"
import LoadNowCertUser from "./components/ogc/LoadNowCertUser"


export const AppRoute = (props) => {
    console.log(window)

    const urlForNowCert = "/nowcert/?access_token=:access_token"
    return <BrowserRouter>
        <Switch>
            <App>
               
                <Route exact path="/login" component={Login}/>
                <Route exact path="/" component={OrganicGrowthCalculator} />,
                <Route exact path="/nowcert/" component={LoadNowCertUser} />,
            </App>
        </Switch>
    </BrowserRouter>
};

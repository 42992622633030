import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import {
    Table, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';
import NumberFormat from 'react-number-format';


const Report = (props) => {
    const {openModal, togglebModal} = props;
    let tableBody = [];
    
    return (
        <Modal isOpen={openModal} toggle={togglebModal} size="xl">
            <ModalHeader toggle={togglebModal}>
                Add / Edit Line of Business
            </ModalHeader>
            <ModalBody>
                <Table responsive striped size="sm" className="ogc-table">
                    <thead className="bg-light border">
                        <tr>
                            <th className="border-top-0 w-30"></th>
                            <th className="border-top-0 pb-1 align-middle text-center">
                                Organic Revenue
                            </th>
                            <th className="border-top-0 pb-1 align-middle text-center">
                                Planned Growth
                            </th>
                            <th className="border-top-0 pb-1 align-middle text-center">
                                Hours per week per department
                            </th>
                            <th className="border-top-0 pb-1 align-middle text-center">
                                Total items per week for the department
                            </th>
                            <th className="border-top-0 pb-1 align-middle text-center">
                                Total items per day for the department
                            </th>
                        </tr>
                    </thead>
                    <tbody className="border-bottom">
                        <tr></tr>
                        {tableBody}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="info" className="btn-sm">
                    Print Report
                </Button>
                <Button color="secondary" onClick={togglebModal} className="btn-sm">
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default Report;
